import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Nav from './Nav';
import Header from '../Header';
import { Footer } from '../../Footer/Footer';
import './Lp.scss';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { motion } from 'framer-motion';
import { listItemVariants } from '../../../Animations';

const FlowWrapper = styled.section`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1920px;
    padding: 15.58vh 18.75vw 9.90vh;

    @media (max-width: 767px) {
        padding: 32px 32px 72px 32px;
    }

    > h2 {
        width: 264px;
        margin: 0 0 16px;
        font-family: chivo;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1d1e21;

        @media (max-width: 767px) {
            font-size: 24px;
            font-weight: bold;
            line-height: 22px;
            color: #1d1e21;
            margin: 0 0 4px 0;
        }
    }
    > h4 {
        margin: 0;
        font-family: chivo;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #767778;
        width: 232px;
        padding-bottom: 16px;
        border-bottom: solid 1px #707070;
    }
    > div {
        display: flex;
        flex-direction: column;
        margin-top: 118px;

        @media (max-width: 767px) {
            margin-top: 47px;
        }

        > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            & img {
                @media (max-width: 767px) {
                    max-height: 266px;
                }
            }

            &.transaction, &.approval {
                @media (max-width: 767px) {
                    &:not(:last-child) { 
                        margin-bottom: 48px;
                    }
                }

                & img {
                    @media (max-width: 767px) {
                        max-width: 148px;
                    }
                }
            }

            @media(max-width: 1365px) {
                flex-wrap: wrap;
            }

            &:not(:last-child) {
                margin-bottom: 125px;

                @media (max-width: 767px) {
                    margin-bottom: 72px;
                }
            }

            &:nth-child(even) {
                flex-direction: row-reverse;
                
                @media (max-width: 767px) {
                    flex-direction: row;
                }

                > div:nth-child(1) {
                    margin-left: 70px;
                    justify-content: flex-start;
                    justify-content: flex-end;

                    @media (max-width: 767px) {
                        margin-left: 0;
                    }
                }
            }
            h2 {
                margin: 8px 0 16px 0px;
                font-family: Chivo;
                font-size: 48px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #0033ad;
                position: relative;

                & .dot {
                    width: 8px;
                    height: 8px;
                    margin: 0 544px 0 0;
                    background-color: #ff7676;
                    position: absolute;
                    top: -16px;
                    border-radius: 100%;
                }

                @media (max-width: 767px) {
                    font-size: 24px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0.8;
                    letter-spacing: normal;
                    text-align: left;
                    color: #0033ad;
                }
            }

            h5 {
                margin: 16px 0px 0 0;
                font-family: Chivo;
                font-size: 24px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #1d1e21;

                @media (max-width: 767px) {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 24px;
                    letter-spacing: normal;
                    text-align: left;
                    color: #1d1e21;
                }
            }

            
            > div:nth-child(2) {
                max-width: 600px;
                max-height: 514px;
                width: 100%;
                display:flex;
                justify-content: center;
            }

            & img {
                max-width: 100%;
                margin-top: 8px;
                padding-left: 8px;

                @media (max-width: 767px) {
                    padding: 0 8px;
                }
            }
        }
    }
`;
class Lp extends Component {
    componentDidMount() {
        ReactGA.initialize(process.env.REACT_APP_GA_ID);
        ReactGA.pageview(window.location.pathname);
    }

    render() {  
        return (
            <React.Fragment>
                <Nav />
                <Header />
                <FlowWrapper>
                    <h2> ADA Pay flow </h2>
                    <h4>How does it work?</h4>
                    <div>
                        {
                            adapayFlowDetails.map((step, idx) => (
                                <motion.div className={step.key}
                                key={step.key}
                                variants={listItemVariants}
                                custom={idx}
                                initial="hidden"
                                animate="visible"
                                exit="hidden">
                                    <div>
                                        <h2><span className="dot"></span><span>{idx+1}.</span><span> {step.title}</span></h2>
                                        <h5>{step.description}</h5>
                                    </div>
                                    <div>
                                        <img src={require(`../../../images/lp/${step.image}`)} alt={step.image} />
                                    </div>
                                </motion.div>
                            ))
                        }

                    </div>
                </FlowWrapper>
                <Footer/>
            </React.Fragment>
        )
    }
}


export default withRouter(Lp);

const adapayFlowDetails = [{
    key: 'online',
    title: "Select Item",
    description: "Users enter a website that supports ADA Pay and select items",
    image: "online.png",
},{
    key: 'purchasing',
    title: "Proceed to Checkout",
    description: 'They add the items to their shopping cart and click "Pay with ADA"',
    image: "purchasing.png",
},{
    key: 'payment',
    title: "Send a Payment",
    description: "They scan the QR code from their wallet and send the transaction",
    image: "payment.png",
},{
    key: 'approval',
    title: "Transaction Approval",
    description: "Users receive a transaction confirmation",
    image: "approved.png",
},{
    key: 'transaction',
    title: "Transaction Updated",
    description: "The merchant's business app updates while the new payment is made",
    image: "transaction.png",
}];