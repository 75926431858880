import _ from 'lodash';
import { config } from '../config';
import bigdecimal from 'bigdecimal';

export const delete_cookie = name => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const _gte = (first, second) => {
    try {
        if (!first || !second) return;
        return new bigdecimal.BigDecimal(first).compareTo(new bigdecimal.BigDecimal(second)) > 0;
    } catch (error) {
        return false;
    }
}

export const toBNAmount = (amount = "0", magnitude = 6) => {
    try {
        if (!amount || amount === "") return "0";
        const amountBD = new bigdecimal.BigDecimal(amount);
        return amountBD.movePointRight(magnitude).toPlainString();
    } catch (error) {
        return "0";
    }
}

export const toDisplayAmount = (amount = "0", magnitude = 6, toFixed) => {
    try {
        if (!amount || amount === "") return "0";
        const amountBD = new bigdecimal.BigDecimal(amount.toString());
        if (toFixed) {
            return new bigdecimal.BigDecimal(customFixed(`${amountBD.movePointLeft(magnitude)}`, toFixed)).stripTrailingZeros().toPlainString();
        }
        return amountBD.movePointLeft(magnitude).stripTrailingZeros().toPlainString();
    } catch (error) {
        return "0";
    }
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export const removeZerosFromEndOfNumber = (number) => {
    if (number.includes('.')) {
        while (number.charAt(number.length - 1) === "0") {
            number = number.substring(0, number.length - 1);
        }

        if (number.charAt(number.length - 1) === ".")
            number = number.substring(0, number.length - 1);
    }
    return number;
}

export const checkUrl = suspect => isURL ? null : "Not a valid URL";

export const isURL = (str) => {
    var pattern = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
    return pattern.test(str);
}

export const customFixed = (num, fixed) => {
    if (!fixed) return parseInt(num);
    num = String(num);
    fixed = fixed + 1;
    if (num.length < 3) return num
    let fixed_num = "";
    let counter = 0;
    for (let i = 0; i < num.length; i++) {
        fixed_num = fixed_num + num[i];
        if (num[i] === "." || counter > 0) {
            counter++
            if (counter === fixed) {
                return fixed_num
            }
        }
    }
    return fixed_num
}


export const toFixed = (x) => {
    var data = String(x).split(/[eE]/);
    if (data.length === 1) return data[0];
    var z = '', sign = x < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;
    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';// eslint-disable-next-line
        return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
}

export const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

export const orderBy = (array, keys, sortBy) => {
    return _.orderBy(array, keys, sortBy);
}


export const onClickCopy = (e, val) => {
    var x = e.pageX,
        y = e.pageY;

    var textField = document.createElement('textarea')
    textField.innerText = val
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    var copied = document.createElement('span')
    copied.innerText = "Copied";
    copied.style = `
                position: absolute; 
                top: ${Number(y) - 50}px; 
                left: ${Number(x) - 40}px; 
                font-size: 14px;
                background-color: #00062a;
                border-radius: 8px 4px 8px 0px;
                color: #fff;           
                letter-spacing: 1px;
                font-family: roboto, sans-serif;
                padding: 5px 10px;
                z-index: 99999;
                `;
    document.body.appendChild(copied);
    setTimeout(() => { copied.remove() }, 2000)
}

export const sortByKey = (array, key) => {
    return array.sort((a, b) => {
        let x = b[key];
        let y = a[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    });
}


export const calcSize = (size, windowWidth) => (size / (windowWidth || 1920)) * 100;

export const commaFormatted = (amount) => {
    var parts = amount.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export const getDecimals = (currencies, symbol) => {
    if (currencies.length && symbol) {
        const currency = currencies.find(currency => currency.symbol === symbol);
        if (currency) {
            return currency.decimals;
        }
    }
    return config.defaultDecimals
}


export const getSettlementsOptions = (currencies, settlements) => {
    if (settlements === null || !settlements.length) return currencies;
    settlements = settlements.map(s => s.currencyId);
    return currencies.filter(c => !settlements.includes(c.id));
}

export const getDestination = ({ withdrawalType, externalAddress, wireInstructions }) => {
    switch (withdrawalType) {
        case "blockchain":
            return externalAddress
        case "wire":
            return `${wireInstructions.bankName} ${wireInstructions.iban}`
        default:
            return 'Destination error';
    }

}
