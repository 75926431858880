import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Pagination from '../../Common/Pagination';
import moment from 'moment';
import {EmptyTable} from '../../Common/EmptyTable';
import { config } from '../../../config';
import _ from 'lodash';
import { Status } from '../Payments/Table';
import { commaFormatted, customFixed, toDisplayAmount } from '../../../shared/utility';
import { motion } from 'framer-motion';
import { listItemVariants } from '../../../Animations';

const Table = styled.table`
    width: 100%;
    font-family: roboto, sans-serif;
    margin-top: 1.7708333333333333vw;
    table-layout: fixed;

    .external-link {
        margin-left: 8px;
        position: relative;

        &:hover {
            &::after {
                font-size: 14px;
                content: "View details";
                position: absolute;
                top: 0;
                left: 0;
                padding: 2px 6px;
                border-radius: 4px;
                background-color: black;
                color: white;
                white-space: nowrap;
                transform: translate(-4px, calc(-100% + -4px));
            }
        }
    }

    tbody{
        tr:hover{
            background-color: #f8fefd;
        }
    }
    tr {
        height: 72px;
    }
    th{
        text-align: left;
        font-size: 0.9vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #131313;
        text-transform: capitalize;
        padding: 0 0 1.3541666666666667vw 0;
        :last-child{
            text-align: right;
            padding-right: 0.8854166666666666vw;
        }
        :first-child{
            padding-left: 0.8854166666666666vw;
        }
    }
    td{
        font-size: 0.8333333333333334vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #131313;
        border-top: 1px solid #dde0e6;
        padding: 1.0416666666666665vw 0;
        text-transform: capitalize;
        vertical-align: middle;
        :last-child{
            text-align: right;
            padding-right: 0.8854166666666666vw;
        }
        img{
            max-width: 1.25vw;
            width: 100%;
            vertical-align: middle;
            margin-right: 16px;
        }
        img.link-icon { 
            height: 14px;
            width: 14px;
        }
        :first-child{
            padding-left: 0.8854166666666666vw;
        }
        :last-child > div{
            &:first-child{
                font-weight: bold;
            }
            justify-content: flex-end;
        }

        > div{
            display: flex;
            align-items: center;
        }
    }
    td#singleCell{
        text-align: center;
    }
    
    @media(max-width: 1200px){
        th{
            font-size: 10px;
            :first-child{
                padding-left: 1.328125vw;
            }
        }
        td{
            padding: 1.5624999999999998vw 0;
            img{
                max-width: 1.875vw;
                margin-right: 1.40625vw;
            }
            :first-child{
                padding-left: 1.328125vw;
            }
        }
    }
    @media(max-width: 768px){
        width: calc(100% + 48px);
        margin-left: -24px;
        padding-top: 32px;
        tr:first-child > td{
            border-top: unset;
        }
        td{
            padding: 13px 0;
            font-size: 16px;
            :first-child{
                padding-left: 24px;
            }
            :last-child{
                padding-right: 24px;
            }
            img{
                max-width: 24px;
            }
        }
        td.processing{
            color: #28acd7;
        }
        td.fully_processed, td.completed, td.accepted{
            color: #10bfa0;
        }
        td.pending{
            color: #eab632;
        }
        td.failed, td.cancelled{
            color: #c25a49; 
        }
    }
`

const TextElipsis = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 54px;
    text-transform: initial;
    position: relative;
}
`;

const TooltipWrapper = styled.div`
    position: relative;
    &:hover {
        &::after {
            font-size: 0.8vw;
            content: ${({address}) => `"${address}"` || "sdg"};
            position: absolute;
            top: 0;
            left: 0;
            padding: 2px 6px;
            border-radius: 4px;
            background-color: black;
            color: white;
            white-space: nowrap;
            transform: translate(-4px, calc(-100% + -6px));
            text-transform: initial;

            @media (max-width: 1200px) {
                font-size: 1vw;
            }

            @media (max-width: 767px) {
                font-size: 1.2vw;
            }
        }
    }
`;



class WithdrawalsTable extends Component{

    state = {
        withdrawalsToDisplay: [],
        from: 0,
        to: config.withdrawals.maxRows,
        filteredWithdrawals: [],
        filterOn: false
    }

    componentDidMount(){
        if(!!this.props.withdrawals.length){
            return this.createTable(0, config.withdrawals.maxRows.withdrawals, this.props.withdrawals)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!_.isEqual(this.props.withdrawals, prevProps.withdrawals) && !!this.props.withdrawals.length) {
            return this.state.filterOn ? this.mapFilterParams() : this.createTable(this.state.from, this.state.to, this.props.withdrawals);
        }
        if(!!this.props.filterParams){
            const {value, calendarType, from, to} = this.props.filterParams;
            if(value !== prevProps.filterParams.value || calendarType !== prevProps.filterParams.calendarType 
                || from !== prevProps.filterParams.from || to !== prevProps.filterParams.to){
                this.onSearch(value, calendarType, from, to, false, true);
            }
        }
    }

    mapFilterParams(){
        const {value, calendarType, from, to} = this.props.filterParams;
        this.onSearch(value, calendarType, from, to, true);
    }

    createTable(from, to, withdrawals, filterOn){
        filterOn = filterOn !== undefined ? filterOn : this.state.filterOn;
        const filteredWithdrawals = filterOn ? withdrawals : this.props.withdrawals;
        let withdrawalsToDisplay = [];
        if(withdrawals.length > 0) {
            withdrawalsToDisplay = withdrawals.filter((current, i) => i < to && i >= from);
        }
        this.setState({...this.state, withdrawalsToDisplay, from, to, filteredWithdrawals, filterOn});
    }

    checkWithdrawalIdOrEmail(withdraw, value){
        return withdraw.address.startsWith(value.toLowerCase());
    }

    onSearch(value, calendarType, from, to, withdrawalsUpdated, filterChanged){
        let filteredWithdrawals = [];
        if(calendarType === "all"){
            filteredWithdrawals = this.props.withdrawals.filter(withdraw => this.checkWithdrawalIdOrEmail(withdraw, value))
        }
        else{
            filteredWithdrawals = this.props.withdrawals.filter(withdraw => {
                const txDate = moment(withdraw.createTime).format("YYYY-MM-DD")
                return moment(txDate).isBetween(from, to, null, '[]');
            }).filter(withdraw => this.checkWithdrawalIdOrEmail(withdraw, value))
        }
        const filterOn = value !== "" || calendarType !== "all";
        const fromIndex = filterOn && (!filterChanged || withdrawalsUpdated) ? this.state.from : 0;
        const toIndex = filterOn && (!filterChanged || withdrawalsUpdated) ? this.state.to : config.withdrawals.maxRows.withdrawals;
        if(!_.isEqual(this.state.filteredWithdrawals, filteredWithdrawals)){
            this.createTable(fromIndex, toIndex, filteredWithdrawals, filterOn);
        }
    }

    transactionsLink(withdrawal) {
        if(!withdrawal?.transactions?.length > 0 && !withdrawal.transactionHash) return null;
        const activeTransaction = withdrawal?.transactions?.find(transaction => (transaction.status === "confirmed" || transaction.status === "pending"));
        const transactionHash = withdrawal?.transactionHash ?? activeTransaction.hash;
        return !(activeTransaction || transactionHash) ? null : <a href={`${process.env.REACT_APP_CARDANO_EXPLORER}/en/transaction?id=${transactionHash}`} className="external-link" target="_blank" rel="noopener noreferrer">
            <img className="link-icon" src={require('../../../images/link-icon.svg')} alt="link" />
        </a>
    }

    calculateFees(withdrawal) {
        let platformFees = Number(withdrawal.platformFees) || 0;
        let transactionsFees = withdrawal?.transactions?.reduce((prev, current) => {
            return prev + (Number(current.fee) || 0)
        }, 0) || 0;
        transactionsFees = withdrawal.transactionFee ? Number(withdrawal.transactionFee) : transactionsFees;
        
        return {
            transactionsFees,
            platformFees,
            totalFees: transactionsFees + platformFees
        };
    }

    render(){
        const statuses = config.withdrawalStatuses;
        const withdrawals = this.state.withdrawalsToDisplay;
        const desktop = this.props.isDesktop;

        return(
            <React.Fragment>
                <Table cellPadding="0" cellSpacing="0" border="0">
                    {
                        desktop &&
                        <thead>
                            <tr>
                                {config.withdrawals.headers.map((header, i) => <th key={i}>{header}</th>)}
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {
                            withdrawals.length > 0
                            ?   withdrawals.map((withdrawal, i) => {
                                const {transactionsFees, totalFees} = this.calculateFees(withdrawal);
                                return (
                                        <motion.tr key={i} variants={listItemVariants} custom={i} initial="hidden" animate="visible">
                                            <td>{moment(withdrawal.createTime).format("lll")}</td>
                                            {desktop && 
                                                <td>
                                                    <TooltipWrapper address={withdrawal.address}>
                                                        <TextElipsis>{withdrawal.address}</TextElipsis>
                                                    </TooltipWrapper>
                                                </td>
                                            }
                                            {desktop && <td className={withdrawal.status}>
                                                <div>
                                                    <img src={require(`../../../images/withdrawals/${statuses[withdrawal.status]?.path || 'failed.svg'}`)} alt="status"/>
                                                    <Status status={withdrawal.status} color={config.statusesColors[withdrawal.status] || 'black'}>
                                                        {statuses[withdrawal.status]?.name || withdrawal.status  || "Error"}
                                                    </Status>
                                                    {this.transactionsLink(withdrawal)}
                                                </div>
                                            </td>}

                                            <td>
                                                <div>{commaFormatted(customFixed(toDisplayAmount(totalFees, config.decimalsUSD['ADA']), 6))} ADA</div>
                                            </td>
                                            
                                            <td>
                                                <div>{commaFormatted(customFixed(toDisplayAmount(withdrawal.amount - transactionsFees, config.decimalsUSD['ADA']), 6))} ADA</div>
                                            </td>
                                        </motion.tr>
                                        
                                    )
                                })
                            : <EmptyTable message="No withdrawals detected" colSpan={6}/>
                        }
                    </tbody>
                </Table>
                <Pagination 
                    filterOn={this.state.filterOn}
                    maxRows={config.withdrawals.maxRows} 
                    array={this.state.filteredWithdrawals} 
                    clickedPage={(page) => this.clickedPage(page)}
                    setFilterdRows={(from, to) => this.createTable(from, to, this.state.filteredWithdrawals)}
                    totalDisplayed={this.state.filteredWithdrawals.length}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        view: app.view,
        isDesktop: app.isDesktop,
        withdrawals: app.withdrawals,
        account: app.account,
    };
};



export default withRouter(connect(mapStateToProps, null)(WithdrawalsTable));