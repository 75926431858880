import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {config} from '../../config';
import * as actions from '../../store/actions';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > div{
        cursor: pointer;
        ${'' /* margin-right: 10px; */}
    }
    font-family: roboto, sans-serif;
    font-size: 0.9375vw;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #131313;
    line-height: 1;
    position: ${({isOutsideApp}) => isOutsideApp && 'fixed'};
    top: ${({isOutsideApp}) => isOutsideApp && '2%'};
    right: ${({isOutsideApp}) => isOutsideApp && '2%'};
    @media(max-width: 1200px){
        font-size: 1.40625vw;
    }
    @media(max-width: 768px){
        justify-content: center;
        font-size: 16px;
    }
`


const Logout = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    > img{
        width: 2.083333333333333vw;
        margin: 0 0 0 0.9375vw;
    }
    line-height: 1;
    @media(max-width: 1200px){
        > img{
            width: 3.1249999999999996vw;
            margin: 0 0 0 1.40625vw;
        }
    }
`

const LogoutButton = ({logout, isDesktop, username, isOutsideApp}) => {
    
    const logoutPath = config.logout;
    return (
        <Wrapper isOutsideApp={isOutsideApp}>
            {
                isDesktop &&
                <React.Fragment>
                    {username}
                    <Logout onClick={()=>logout()}>
                        <img src={require(`../../images/${logoutPath}`)} alt="logout"/>
                    </Logout>
                </React.Fragment>
            }
        </Wrapper>
    )
}


const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    };
};

export default withRouter(connect(null, mapDispatchToProps)(LogoutButton));