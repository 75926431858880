import React, {Component} from 'react';
import styled from 'styled-components';
import { config } from '../../config';
import Calendars from '../Common/Calendars';

const CustomDropdown = styled.div`
    font-family: sofia-pro, sans-serif;
    height: 48px;
    padding-left: 2.4479166666666665vw;
    font-size: 0.8333333333333334vw;
    border-top: solid 1px #dde0e6;
    border-right: solid 1px #dde0e6;
    border-bottom: solid 1px #dde0e6;
    border: ${({error}) => error && '1px solid salmon'};
    background-color: #ffffff;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    :focus{
        outline: none;
    }
    > span{
        display: flex;
        justify-content: initial;
        align-items: center;
        user-select: none;
    }
    > img#arrow{
        position: absolute;
        right: 0.5208333333333333vw;
        top: 0;
        user-select: none;
    }
    > img#period{
        left: 0.8333333333333334vw;
        position: absolute;
    }
    > img.currency{
        margin-right: 10px;
        max-width: 18px;
    }
    > div.list{
        position: absolute;
        overflow: auto;
        border-right: solid 1px #dde0e6;
        border-bottom: solid 1px #dde0e6;
        border-left: solid 1px #dde0e6;
        z-index: 1000;
        width: 100%;
        max-height: 220px;
        left: -1px;
        top: 2.4479166666666665vw;
        background: #fff;
        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background: #eceae6;
        }

        ::-webkit-scrollbar-thumb{
            background: #081339; 
            border-radius: 10px;
        }
        > div{
            text-decoration: none;
            display: block;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 2.5vw;
            font-size: 0.8333333333333334vw;
            color: #131313;
            padding-left: 2.4479166666666665vw;
            box-sizing: border-box;
            > img.currency{
                margin-right: 10px;
                max-width: 18px;
            }
            &:hover{
                background-color: #232744;
                color: #ffffff;
            }
        }
    }
    &.isSearch{
        width: 16%;
    }
    &.isSearch, &.isInnerForm{
        height: 2.5vw;
    }
    &.isInnerForm, &.isForm{
        border-left: solid 1px #dde0e6;
        border: ${({error}) => error && '1px solid salmon'};
        border-radius: 4px;
        > div.list{
            border-top: solid 1px #dde0e6;
            border-radius: 4px;
            > div{
                padding-left: 0.8854166666666666vw;
            }
        }
    }
    &.isSales{
        padding-left: 64px;
    }
    &.isForm{
        font-size: 16px;
        margin-bottom: ${({mb}) => mb ? "58px" : "26px"};
        > div.list{
            top: 49px;
            > div{
                height: 25px;
                font-size: 14px;
                padding-left: 8px;
            }
        }
    }
    &.isIndustry, &.isTechnology{
        padding-left: 17px;
        margin-bottom: 30px;
    }
    &.isInnerForm{
        padding-left: 0.8854166666666666vw;
        margin-bottom: 26px;
    }
    @media(max-width: 1200px){
        &.isSearch, &.isInnerForm{
            height: 3.75vw;
            font-size: 1.25vw;
        }
        &.isForm{
            > div.list{
                top: 49px;
                > div{
                    height: 25px;
                    font-size: 14px;
                    padding-left: 8px;
                }
            }
        }
        &.isSearch{
            width: 30%;
            padding-left: 3.671875vw;
        }
        > div.list{
            top: 3.671875vw;
            > div{
                height: 3.75vw;
                font-size: 1.25vw;
                padding-left: 3.671875vw;
            }
        }
        &.isInnerForm {
            padding-left: 1.328125vw;
            > div.list{
                > div{
                    padding-left: 1.328125vw;
                }
            }
        }
    }
    @media(max-width: 768px){
        border: ${({error}) => error ? '1px solid salmon' : 'solid 1px #dde0e6'};
        > div.list{
            top: 47px;
        }
        &.isInnerForm{
            height: 48px;
            font-size: 16px;
            > div.list > div{
                height: 48px;
                font-size: 16px;
            }
        }
        &.isSearch{
            width: 100%;
            padding-left: 48px;
            height: 48px;
            font-size: 16px;
            > div.list > div{
                padding-left: 48px;
                font-size: 16px;
                height: 48px;
            }
        }
        &.isInnerForm, &.isIndustry{
            padding-left: 17px;
            > div.list > div{
                padding-left: 17px;
            }
        }
        &.isSales{
            padding-left: 64px;
        }
        &.isForm > div.list{
            top: 49px;
            > div{
                height: 40px;
                font-size: 14px;
            }
        }
        > img#arrow{
            right: 16px;
        }
        > img#period{
            left: 20px;
        }
    }
`

const Label = styled.label`
    font-size: ${({isInnerForm}) => isInnerForm ? "0.7291666666666666vw" : "14px"};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #131313;
    display: block;
    padding-left: ${({isInnerForm}) => isInnerForm ? "0.8854166666666666vw" : "17px"} ;
    padding-bottom: 7px;
    @media(max-width: 1200px){
        font-size: ${({isInnerForm}) => isInnerForm && "1.09375vw"};
        padding-left: ${({isInnerForm}) => isInnerForm && "1.328125vw"};
    }
    @media(max-width: 768px){
        padding-left: 0;
        font-size: 14px;
    }
`

const Error = styled.p`
    position: absolute;
    top: 49px;
    left: 0;
    margin: 0;
    font-size: 12px;
    color: salmon;
    font-family: sofia-pro, sans-serif;
    white-space: pre-wrap;
    padding-left: 17px;
    pointer-events: none;
    @media(max-width: 768px){
        padding-left: 0;
    }
`

const SubLabel = styled.p`
    position: absolute;
    top: 62px;
    left: 17px;
    margin: 0;
    font-size: 14px;
    color: ${({color}) => color ? color : 'salmon'};
    font-family: sofia-pro, sans-serif;
    white-space: pre-wrap;
    pointer-events: none;
    @media(max-width: 768px){
        white-space: unset;
        left: 0;
    }
`

const DropArrow = styled.div`
    position: absolute;
    width: ${({isInnerForm, isSearch}) => (isInnerForm || isSearch) ? "2.5vw" : "48px"};
    height: ${({isInnerForm, isSearch}) => (isInnerForm || isSearch) ? "2.3958333333333335vw" : "46px"};
    bottom: 0px;
    right: 0;
    background-color: #dde0e6;
    border-radius: ${({isInnerForm, isForm}) => (isInnerForm || isForm) && "0 4px 4px 0"};
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 1200px){
        width: ${({isInnerForm, isSearch}) => (isInnerForm || isSearch) && "3.75vw"};
        height: ${({isInnerForm, isSearch}) => (isInnerForm || isSearch) && "3.59375vw"};
    }
    @media(max-width: 768px){
        width: 48px;
        height: 46px;
    }
`

const Symbol = styled.div`
    position: absolute;
    width: 48px;
    height: 46px;
    bottom: 0px;
    left: 0;
    border-right: solid 1px #dde0e6;
    border-radius: 4px 0 0 4px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #0b1539;

`

class Dropdown extends Component{

    state = {
        showList: false,
        option: ""
    }

    componentDidMount(){
        if(this.props.isSearch){
            this.setState({...this.state, option: config.payments.filterOptions[0]})
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.isSearch && prevProps.type !== this.props.type && this.props.type === "all"){
            this.setState({...this.state, option: config.payments.filterOptions[0]})
        }
    }

    onSelectOption(event, option){
        event.stopPropagation();
        this.setState({...this.state, showList: false, option})
        this.props.onSelectOption(option.value)
    }

    toggleDropdown(flag){
        this.setState({...this.state, showList: flag})
    }

    getIcon(currency){
        const {isSearch, id} = this.props;
        if(isSearch){
            return <img src={require('../../images/payments/searchperiod.svg')} width="16vw" id="period" height="100%" alt="period"/>
        }
        else if(id === "currency" && currency){
            return <img src={require(`../../images/dashboard/${(currency).toLowerCase()}.svg`)} className="currency" alt={currency}/>
        }
        else if(id === "sales"){
            return <Symbol>$</Symbol>
        }
        return null;
    }

    getDropdownArrow(){
        const {isSearch, isInnerForm, isForm} = this.props;
        return (
            <DropArrow isInnerForm={isInnerForm} isForm={isForm} isSearch={isSearch}><img src={require("../../images/payments/dropdown.svg")} alt="" width="16px"/></DropArrow>
        )
    }

    getOptionsList(options, displayIcon){
        return (
            <div className="list">
                {
                    options.map((option, i) => 
                        <div key={i} onClick={(e) => this.onSelectOption(e, option)}>
                            {displayIcon && this.getIcon(option.label)}
                            {option.label}
                        </div>)
                }
            </div>
        )
    }


    render(){
        const { label, options, id, error, selected, subLabel, tabIndex, isInnerForm, className } = this.props;
        const showCalendars = id === "filter" && this.props.type === "custom";
        return (
            <React.Fragment>
                {label && <Label isInnerForm={isInnerForm}>{label}</Label>}
                <CustomDropdown mb={!!subLabel} className={className} error={error} onClick={() => this.toggleDropdown(!this.state.showList)} tabIndex={tabIndex} onBlur={() => this.toggleDropdown(false)}>
                    {this.getIcon(this.state.option.label || selected)}
                    <span>{this.state.option.label || selected}</span>
                    {this.getDropdownArrow()}
                    {(this.state.showList) && this.getOptionsList(options, id === "currency")}
                    {subLabel && <SubLabel subLabel color="#868686">{subLabel}</SubLabel>}
                    {error && <Error>{error}</Error>}
                </CustomDropdown>
                {showCalendars && <Calendars onSelectDate={(date, direction) => this.props.onSelectDate(date, direction)} from={this.props.from} to={this.props.to}/>}
            </React.Fragment>
        )
    }
}

export default Dropdown;