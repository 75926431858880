import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {config} from '../../../config';
import Pagination from '../../Common/Pagination';
import moment from 'moment';
import {commaFormatted, customFixed, toDisplayAmount} from '../../../shared/utility';
import {EmptyTable} from '../../Common/EmptyTable';
import _ from 'lodash';
import { motion } from 'framer-motion';
import {listItemVariants} from '../../../Animations';

const Table = styled.table`
    width: 100%;
    font-family: roboto, sans-serif;
    tbody{
        tr:hover{
            background-color: #f5f7fa;
            transition: background 0.3s ease-out 0s;
             cursor: pointer;
        }
    }
    tr {
        height: 72px;
        background: transparent;
        transition: background 0.3s ease-in 0s;
    }
    th{
        text-align: left;
        font-size: 0.9vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #131313;
        text-transform: capitalize;
        padding: 0 0 1.3541666666666667vw 0;
        :last-child{
            text-align: right;
        }
        :first-child{
            padding-left: 0.8854166666666666vw;
        }
        :last-child{
            padding-right: 0.8854166666666666vw;
        }
    }
    td{
        font-size: 0.8333333333333334vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #131313;
        border-top: 1px solid #dde0e6;
        vertical-align: middle;
        :last-child{
            > div{
                font-weight: bold;
                justify-content: flex-end;
            }
        }
        img{
            max-width: 1.25vw;
            width: 100%;
            vertical-align: middle;
            margin-right: 0.9375vw;
        }
        
        :first-child{
            padding-left: 0.8854166666666666vw;
            width: 12%;
            @media (max-width: 1024px) {
                width: 25%;
            }
        }
        :last-child{
            padding-right: 0.8854166666666666vw;
            @media (max-width: 1024px) {
                width: 25%;
            }
        }
        > div{
            display: flex;
        }
        :nth-child(2) {
            padding-right: 8px;
            width: 14%;
            @media (max-width: 1024px) {
                width: 50%;
            }
        }

        :nth-child(3){
            width: 30%;
            text-transform: capitalize;
            padding-right: 4px;
        }
        :nth-child(4){
            width: 20%;
        }
        :nth-child(5){
            width: 12%;
        }
        :nth-child(6){
            width: 12%;
        }
        &.td-status {
            padding-right: 8px;
            height: 72px;
            @media (max-width: 860px) {
                padding-right: 24px;
            }

            @media (max-width: 768px) {
                padding-right: 0px;
            }

            & > div {
                text-transform: capitalize;
                align-items: center;
            }
        }
    }
    td#singleCell{
        text-align: center;
    }
    @media(max-width: 1200px){
        th{
            font-size: 0.833vw;
            :first-child{
                padding-left: 1.328125vw;
            }
        }
        td{
            padding: 1.5624999999999998vw 0;
            font-size: 0.917vw;
            img{
                max-width: 1.875vw;
                margin-right: 1.40625vw;

                @media (max-width: 1080px) {
                    max-width: 1.2vw;
                    margin-right: 0.6vw;
                }
            }
            :first-child{
                padding-left: 1.328125vw;
            }
        }
    }

    @media(max-width: 860px){ 
        th{ 
            font-size: 1.163vw;
        }

        td{ 
            font-size: 1.163vw;
        }
    }

    @media(max-width: 768px){
        width: calc(100% + 48px);
        margin-left: -24px;
        padding-top: 32px;
        td{
            padding: 13px 0;
            font-size: 2.778vw;
            :first-child{
                padding-left: 24px;
            }
            :nth-child(2){
                text-transform: capitalize;
            }
            :last-child{
                padding-right: 24px;
            }
            img{
                max-width: 24px;
            }
        }
        td.confirmed{
            color:#28acd7;
        }
        td.completed, td.confirmed{
            color: #11deb3;
        }
        td.expired{
            color: #eab632;
        }
        td.failed{
            color: #c25a49; 
        }
    }
`

export const Status = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({color}) => color ? color : 'black'};
    text-transform: ${({status}) => status === "payment-sent" ? "none" : "capitalize"}
    & span {
        font-family: Roboto;
        font-size: 0.7333333333333334vw;
        color: black;

        @media(max-width: 1365px){
            font-size: 0.8333333333333334vw;
        }

        @media(max-width: 767px){
            font-size: 2.778vw;
            text-align: right;
        }
        
        & span {
            text-transform: none;
        }
    }
`

const SubStatusContainer = styled.span`
    & > span {
        &:first-child {
            text-transform: capitalize;
        }
    }
`

const TextElipsis = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 54px;
    text-transform: initial;
    position: relative;
}
`;

const TooltipWrapper = styled.div`
    position: relative;
    max-width: 350px;
    padding-right: 16px;

    &:hover {
        &::after {
            font-size: 0.8vw;
            content: ${({address}) => `"${address}"` || "sdg"};
            position: absolute;
            top: 0;
            left: 0;
            padding: 2px 6px;
            border-radius: 4px;
            background-color: black;
            color: white;
            white-space: nowrap;
            transform: translate(-4px, calc(-100% + -6px));
            text-transform: initial;

            @media (max-width: 1200px) {
                font-size: 1vw;
            }

            @media (max-width: 767px) {
                font-size: 1.2vw;
            }
        }
    }
`;


class PaymentsTable extends Component{

    state = {
        paymentsToDisplay: [],
        from: 0,
        to: config.payments.maxRows.payments,
        filteredPayments: [],
        filterOn: false
    }

    componentDidMount(){
        if(!!this.props.payments.length){
            return this.props.isOverview 
                    ? this.createTable(0, 3, this.props.payments) 
                    : this.createTable(0, config.payments.maxRows.payments, this.props.payments)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!_.isEqual(this.props.payments, prevProps.payments) && !!this.props.payments.length) {
            return this.props.isOverview 
                ? this.createTable(0, 3, this.props.payments) 
                : this.state.filterOn ? this.mapFilterParams() : this.createTable(this.state.from, this.state.to, this.props.payments)
        }
        if(!!this.props.filterParams){
            const {value, calendarType, from, to} = this.props.filterParams;
            if(value !== prevProps.filterParams.value || calendarType !== prevProps.filterParams.calendarType 
                || from !== prevProps.filterParams.from || to !== prevProps.filterParams.to){
                this.onSearch(value, calendarType, from, to, false, true);
            }
        }
    }

    mapFilterParams(){
        const {value, calendarType, from, to} = this.props.filterParams;
        this.onSearch(value, calendarType, from, to, true);
    }

    createTable(from, to, payments, filterOn){
        filterOn = filterOn !== undefined ? filterOn : this.state.filterOn;
        const filteredPayments = filterOn ? payments : this.props.payments;
        let paymentsToDisplay = [];
        if(payments.length > 0) {
            paymentsToDisplay = payments.filter((current, i) => i < to && i >= from);
        }
        this.setState({...this.state, paymentsToDisplay, from, to, filteredPayments, filterOn});
    }

    onClickRow(payment){
        this.props.history.push(`${config.root_path}${config.items.payments.path}/invoice?id=${payment.uuid}`)
    }

    checkPaymentIdOrEmail(payment, value){
        return payment.uuid.startsWith(value.toLowerCase()) 
        || (payment.email && payment.email.includes(value.toLowerCase()))
    }

    onSearch(value, calendarType, from, to, paymentsUpdated, filterChanged){
        let filteredPayments = [];
        if(calendarType === "all"){
            filteredPayments = this.props.payments.filter(payment => this.checkPaymentIdOrEmail(payment, value))
        }
        else{
            filteredPayments = this.props.payments.filter(payment => {
                const txDate = moment(payment.createTime).format("YYYY-MM-DD")
                return moment(txDate).isBetween(from, to, null, '[]');
            }).filter(payment => this.checkPaymentIdOrEmail(payment, value))
        }
        const filterOn = value !== "" || calendarType !== "all";
        const fromIndex = filterOn && (!filterChanged || paymentsUpdated) ? this.state.from : 0;
        const toIndex = filterOn && (!filterChanged || paymentsUpdated) ? this.state.to : config.payments.maxRows.payments;
        if(!_.isEqual(this.state.filteredPayments, filteredPayments)){
            this.createTable(fromIndex, toIndex, filteredPayments, filterOn)
        }
    }

    render(){
        const statuses = config.txStatuses;
        const payments = this.state.paymentsToDisplay;
        const desktop = this.props.isDesktop;
        return(
            <React.Fragment>
                
                <Table cellPadding="0" cellSpacing="0" border="0">
                    {desktop && 
                        <thead>
                            <tr>
                                {config.txHeaders.map((header, i) => <th key={i}>{header}</th>)}
                            </tr>
                        </thead>
                    }
                    <tbody>
                            {
                                payments.length > 0
                                ?   payments.map((payment, i) => {
                                        return (
                                            <motion.tr
                                            key={i}
                                            onClick={() => this.onClickRow(payment)}
                                            variants={listItemVariants}
                                            custom={i}
                                            initial="hidden"
                                            animate="visible">
                                                <td style={{textTransform: "capitalize"}}>{moment(payment.createTime).format("MMM DD")}</td>
                                                {desktop && <td>{payment.email ? payment.email :  ""}</td>}
                                                {desktop && <td>
                                                    <TooltipWrapper address={payment.uuid}>
                                                        <TextElipsis>{payment.uuid}</TextElipsis>
                                                    </TooltipWrapper>
                                                </td>}
                                               
                                                <td className={`${payment.status} td-status`}>
                                                    <div>
                                                        {desktop && <img src={require(`../../../images/payments/${statuses[payment.status] ||  "failed.svg"}`)} alt="status"/>}
                                                        <Status status={payment.status} color={config.statusesColors[payment.status] || 'black'}>
                                                            {config.statusesFormat[payment.status] || payment.status  || "Error"}
                                                            <SubStatus payment={payment} />
                                                        </Status>
                                                    </div>
                                                </td>
                                                {desktop && <td> 
                                                                <div>{commaFormatted(customFixed(toDisplayAmount(payment.amount, config.decimalsUSD['ADA']), 2))} ADA</div>
                                                            </td>
                                                }
                                                
                                                <td> 
                                                    <div>{commaFormatted(customFixed(toDisplayAmount(payment.confirmedAmount, config.decimalsUSD['ADA']), 2))} ADA</div>
                                                </td>
                                            </motion.tr>
                                            
                                        )
                                    })
                                : <EmptyTable message="No records found" colSpan={6}/>
                            }
                    </tbody>
                </Table>
                {
                    !this.props.isOverview &&
                    <Pagination 
                        filterOn={this.state.filterOn}
                        maxRows={config.payments.maxRows[this.props.view.toLowerCase()]} 
                        array={this.state.filteredPayments} 
                        clickedPage={(page) => this.clickedPage(page)}
                        setFilterdRows={(from, to) => this.createTable(from, to, this.props.isOverview ? this.props.payments : this.state.filteredPayments)}
                        totalDisplayed={this.state.paymentsToDisplay.length}
                    />
                }
            </React.Fragment>
        )
    }
}


export const SubStatus = ({payment}) => {
    if(!payment.subStatus || payment.subStatus === "") return null;
    return <SubStatusContainer>
            <span>{payment.subStatus}</span>
            <span>
                {config.subStatusMessages[payment.subStatus] || ` by ${commaFormatted(Math.abs(customFixed(toDisplayAmount(payment.confirmedAmount - payment.amount, config.decimalsUSD['ADA']), 2)))} ADA`}
            </span>
    </SubStatusContainer>
}

const mapStateToProps = ({app}) => {
    return {
        view: app.view,
        isDesktop: app.isDesktop,
        payments: app.payments,
        account: app.account,
    };
};

export default withRouter(connect(mapStateToProps)(PaymentsTable));