import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../../../config';
import Setting, { SETTING_TYPES } from '../../../Common/Setting/Setting';
import './PaymentSettings.scss';
import { updateMerchantInfo } from '../../../../store/actions/integrations';
import { motion } from 'framer-motion';
import { expandVariants } from '../../../../Animations';

const apiKeysFormatted = {
    0: "Your first API key",
    1: "Your second API key"
}

const apiUpdatedKeys = {
    [config.settings.webhook]: "callbackUrl",
    [config.settings.success]: "returnUrl",
}

const PaymentSettings = () => {
    const { apiKeys, webhookUrl, successUrl } = useSelector(({app}) => app);
    const dispatch = useDispatch();
    const [_webhookUrl, _setWebhookUrl] = useState("");
    const [_successUrl, _setSuccessUrl] = useState("");
    
    const onSubmit = (key, value) => {
        dispatch(updateMerchantInfo({
            [apiUpdatedKeys[key] || key]: value
        }));
    }

    useEffect(() => {
        if(!webhookUrl) return;
        _setWebhookUrl(webhookUrl);
    }, [webhookUrl]);

    useEffect(() => {
        if(!successUrl) return;
        _setSuccessUrl(successUrl);
    }, [successUrl]);

    return (

        <motion.div className="payment-settings-component" initial="hidden" animate="visible"  exit="hidden" variants={expandVariants}>
            <Setting
                id={config.settings.webhook}
                title="Webhook URL"
                description="Please provide the URL where you want to receive payment status notifications"
                type={SETTING_TYPES.INPUT}
                value={_webhookUrl}
                setValue={_setWebhookUrl}
                validations={["required"]}
                disabled={_webhookUrl === webhookUrl}
                onClick={onSubmit}
            />
          
            <Setting
                id={config.settings.success}
                title="Rediredt success"
                description="Once the payment is complete successfully , consumer will be displayed with a redirect URL to go back to your website"
                type={SETTING_TYPES.INPUT}
                value={_successUrl}
                setValue={_setSuccessUrl}
                validations={["required"]}
                disabled={_successUrl === successUrl}
                onClick={onSubmit}
            />

            {apiKeys.map(({decryptedApiKey}, index) => <Setting
                key={decryptedApiKey}
                title={`API key ${index + 1}`}
                description={apiKeysFormatted[index]}
                type={SETTING_TYPES.INPUT}
                value={decryptedApiKey}
                readOnly
            />)}
        </motion.div>
    )
}

export default PaymentSettings;