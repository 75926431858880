import React, {Component} from 'react';
import styled from 'styled-components';
import InnerTitle from '../../Common/InnerTitle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {config} from '../../../config';
import * as actions from '../../../store/actions';
import _ from 'lodash';
import PaymentHistory from '../../Common/PaymentHistory/PaymentHistory';
import xhrApi from '../../../xhr-api';
import moment from 'moment';
import { commaFormatted, toDisplayAmount } from '../../../shared/utility';
import { SubStatus } from './Table';
import { fadeInRightVariants } from '../../../Animations';
import { motion } from 'framer-motion';

const Wrapper = styled.div`
    margin-top: 1.0416666666666665vw;
    padding-top: 3.75vw;
    padding-left: ${({right}) => right ? '3.6458333333333335vw' : "1.7708333333333333vw"};
    padding-right: ${({right}) => right ? "3vw" : "3.6979166666666665vw"};
    padding-bottom: 2.65625vw;
    box-sizing: border-box;
    background-color: #ffffff;
    border-right: ${({left}) => left && "1px solid #dde0e6"};
    font-family: roboto, sans-serif;
    width: ${({width}) => width};
    max-width: 100%;

    & .payment-user-details-component {
        @media (max-width: 767px) {
            margin-top: 48px;
            padding-top: 48px;
            border-top: 1px solid #dde0e6;
        }

        &__header {
            display: flex;
            align-items: center;
            font-weight: bold;

            & span {
                color: black;

                font-size: 1.3333333333333334vw;

                @media (max-width: 1170px) {
                    font-size: 2.3333333333333334vw;
                }
    
                @media (max-width: 480px) {
                    font-size: 3.4333333333333334vw;
                }
            }

            & > img {
                margin-right: 22px;

                @media (max-width: 767px) {
                    width: 40px;  
                    height: 40px;
                    margin-right: 12px;
                }
            }
        }

    }

    & .info-row-component {
        padding-left: 62px;
        margin-left: 40px;
        padding-top: 32px;
        border-left: 1px solid #dde0e6;

        @media (max-width: 767px) {
            padding-left: 32px;
            margin-left: 20px;
        }

        &:first-child {
            padding-top: 0;
            margin-top: 25px;
        }

        & h2 {
            font-size: 16px; 
            font-weight: bold;
            margin: 0;    
            
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }

        & p {
            margin: 0;
            padding-top: 4px;
            font-size: 16px; 

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }

    & .payment-history-component {
        padding-left: 44px;
        @media (max-width: 767px) {
            padding-left: 0;
        }

        & .transaction-row-component {
            max-width: 767px;
        }
    }

    ul{
        list-style: none;
        margin: 0 0 0 2.5vw;
        padding: 1.9791666666666665vw 0 0 0 ;
        border-top: 1px solid #dde0e6;
        li {
            display: flex;
            justify-content: space-between;
            padding: 0 0.8333333333333334vw 3.3854166666666665vw;
            color: #131313;
            font-size: 0.9375vw;
            .failed{
                color: #dd5a46;
            }
            .status{
                text-transform: capitalize;
            }
        }
    }
    @media(max-width: 1200px){
        width: 100%;
        border-right: unset;
        margin-top: 1.5624999999999998vw;
        padding-top: 5.625vw;
        padding-left: ${({right}) => right ? '5.46875vw' : "2.75625vw"};
        padding-right: ${({right}) => right ? "0" : "5.546875vw"};
        padding-bottom: 3.984375vw;
        ul{
            margin: 0 0 0 3.75vw;
            padding: 2.96875vw 0 0 0 ;
            li{
                font-size: 1.40625vw;
            }
        }
    }
    @media(max-width: 768px){
        margin: 0;
        padding: 0;
        background-color: unset;
        ul{
            margin: 0;
            width: calc(100% + 48px);
            margin-left: -24px;
            padding: 0 24px;
            box-sizing: border-box;
            li{
                font-size: 0.78125vw;
                flex-direction: column;
                border-bottom: 1px solid #dde0e6;
                margin-left: -24px;
                padding: 16px 24px;
                width: 100%;
                > span:last-child{
                    font-weight: bold;
                }
            }
        }
    }
`

const Top = styled.div`
    display: flex;
    align-items: center;

    & .payment-request-header {
        display: flex;
        align-items: center;

        & > img {
            width: 80px;
            height: 80px;
            margin-right: 1.354167vw;

            @media (max-width: 767px) {
                width: 40px;  
                height: 40px;
                margin-right: 12px;
            }
        }

        & .details {
            & h2 {
                margin: 0;
                font-size: 1.3333333333333334vw;

                @media (max-width: 1170px) {
                    font-size: 1.9333333333333334vw;
                }

                @media (max-width: 480px) {
                    font-size: 3.4333333333333334vw;
                }
            }

            & p {
                margin: 0;
                font-size: 0.9333333333333334vw;
                @media (max-width: 1170px) {
                    font-size: 1.5333333333333334vw;
                }

                @media (max-width: 480px) {
                    font-size: 2.5333333333333334vw;
                }
            }
        }
    }

    > img{
        width: 22px;
        cursor: pointer;
        margin-right: 1.3541666666666667vw;
    }
`

const Container = styled.div`
    display: flex;
    @media(max-width: 1200px){
        flex-direction: column;
    }
`

const Quantitites = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 1.4583333333333333vw;
    padding-top: 1.6145833333333335vw;
    padding-left: 2.5vw;
    box-sizing: border-box;
    justify-content: flex-start;
    
    #warning{
        font-size: 1.25vw;
        font-weight: bold;
    }
    > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 2.45vw;

        &.confirmed {
            > span:first-child{
                color: #10bfa0;
            }
        }

        > span:first-child{
            font-size: 1.6666666666666667vw;
            font-weight: bold;
        }
        > span:last-child{
            font-size: 0.8333333333333334vw;
            font-weight: normal;
            line-height: 1.5;
        }
        .overpaid{
            color: #eab632;
        }
        .underpaid{
            color: #dd5a46;
        }
    }
    @media(max-width: 768px){
        padding: 0;
        margin: 16px 0 0 0;

        #warning{
            padding: 0 33px;
            font-size: 18px;
        }
        > div{
            box-sizing: border-box;
            text-align: center;
          
            > span:first-child{
                font-size: 5.5vw;
            }
            > span:last-child{
                font-size: 2.5vw;
            }
        }
    }
`

const Divider = styled.div`
    width: 1px;
    height: 3.3333333333333335vw;
    background-color: #dde0e6;
    @media(max-width: 768px){
        height: 6.3333333333333335vw;
    }
`

class Invoice extends Component {

    state =  {
        paymentInfo: null,
        transactions: [],
    }

    componentDidMount() {
        this.props.setTransactions([]);
        this.getPaymentRequest();
    }

    componentWillUnmount() {
        this.props.setTransactions([]);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const updatedPayment = this.state.paymentInfo && nextProps.payments.find(({uuid}) => uuid === this.state.paymentInfo.uuid);
        const paymentIsUpdated =  updatedPayment && (updatedPayment.maxDate !== this.state.paymentInfo.maxDate);
    
        if(updatedPayment && paymentIsUpdated) {
            this.setState({paymentInfo: updatedPayment});
        }
       
        return !_.isEqual(this.state.paymentInfo, nextState.paymentInfo) 
                || !_.isEqual(this.state.transactions, nextState.transactions)
                || paymentIsUpdated;
    }
    
    onClickBack(){
        this.props.history.goBack();
    }

    async getPaymentRequest() {
        try {
            const urlParams = new URLSearchParams(this.props.location.search);
            const invoiceId = urlParams.get("id");
            if(!invoiceId) return;
            const { data } = await xhrApi.GET_PAYMENT_REQUEST({uuid: invoiceId});
           
            if(data && data.transactions && data.transactions.length > 0) {
                this.props.setTransactions(data.transactions);
                this.setState({transactions: data.transactions});
            }

            if(data && data.uuid) {
                this.setState({paymentInfo: data});
            }
        } catch(error) {
            console.log(error);
            this.setState({paymentInfo: {}});
        }
    }

    render(){
        const { paymentInfo } = this.state;
 
        return (
            <motion.div variants={ fadeInRightVariants } initial="hidden" animate="visible" exit="hidden">
                <Container>
                    {paymentInfo === null ? <Wrapper width="100%" right>
                        <Top>
                            <img src={require('../../../images/payments/backdesktop.svg')} onClick={() => this.onClickBack()} alt="back"/>
                            <InnerTitle 
                                isLoading
                                path={"invoice.svg"} 
                                isSubtitle
                            />
                        </Top>
                    
                    </Wrapper> : <>
                        {Object.keys(paymentInfo).length === 0 ? <Wrapper width="100%">
                            <Top>
                                <img src={require('../../../images/payments/backdesktop.svg')} onClick={() => this.onClickBack()} alt="back"/>
                                <div className="payment-request-header">
                                    <img src={require('../../../images/inner-title/invoice.svg')} alt="invoice" />
                                    <div className="details">
                                        <h2>{this.state.paymentInfo.status.toLowerCase() !== "payment-sent" && "Payment"} <span>{config.statusesFormat[this.state.paymentInfo.status.toLowerCase()] || this.state.paymentInfo.status.toLowerCase()}</span></h2>
                                        <SubStatus payment={this.state.paymentInfo} />
                                    </div>
                                </div>
                            </Top>
                            <h3>{config.tooltipMessages.generalfailMsg}</h3>
                        </Wrapper> : <> 
                            <Wrapper width="60%" left style={{ zIndex:"10"}}>
                                <Top>
                                    <img src={require('../../../images/payments/backdesktop.svg')} onClick={() => this.onClickBack()} alt="back"/>
                                    <div className="payment-request-header">
                                        <img src={require('../../../images/inner-title/invoice.svg')} alt="invoice" />
                                        <div className="details">
                                            <h2>{this.state.paymentInfo.status.toLowerCase() !== "payment-sent" && "Payment"} <span>{config.statusesFormat[this.state.paymentInfo.status.toLowerCase()] || this.state.paymentInfo.status.toLowerCase()}</span></h2>
                                            <SubStatus payment={this.state.paymentInfo} />
                                        </div>
                                    </div>
                                </Top>
                                <Quantitites>
                                    {
                                        !!Object.keys(paymentInfo).length ? <> 
                                            <div>
                                                <span>{commaFormatted(toDisplayAmount(paymentInfo.amount, config.currencies.ADA.decimals, 2))} ADA</span>
                                                <span>Payment request amount</span>
                                            </div>
                                            <Divider/>
                                            <div className="confirmed">
                                                <span>{commaFormatted(toDisplayAmount(paymentInfo.confirmedAmount, config.currencies.ADA.decimals, 2))} ADA</span>
                                                <span>Payment received</span>
                                            </div>
                                        </>
                                        : <span id="warning" style={{color: paymentInfo.status === "expired" ? "#dd5a46" : "#eab632"}}>{config.noCurrencyMessage[paymentInfo.status]}</span>
                                    }
                                    {/* <PaymentStatus status={paymentInfo.status} cryptoPaidAmount={paymentInfo.confirmedAmount} currency={paymentInfo.currency} exceptionalStatus={paymentInfo.exceptionalStatus}/> */}
                                </Quantitites>
                                
                                <PaymentHistory />
                            </Wrapper>
                                <Wrapper width="40%" right style={{ zIndex:"9"}}>
                                        <div className="payment-user-details-component">
                                            <div className="payment-user-details-component__header">
                                                <img src={require('../../../images/payment-info-icon.svg')} alt="payment info" />
                                                <span>Payment details</span>
                                            </div>

                                            <div className="payment-user-details-component__body">
                                                <InfoRow title="Creation date" content={moment(this.state.paymentInfo.createTime).utc().format('LLL')} />
                                                <InfoRow title="Payment ID" content={this.state.paymentInfo.uuid} />
                                                <InfoRow title="Email" content={this.state.paymentInfo.email} />
                                                {this.state.paymentInfo.name && <InfoRow title="Name" content={this.state.paymentInfo.name} /> }
                                            </div>
                                        </div>
                                </Wrapper>
                        </>}
                    </>}
                </Container>
            </motion.div>
        )
      
    }
}  

const InfoRow = ({title, content}) => {
    return <div className="info-row-component">
        <h2>{title}</h2>
        <p>{content}</p>
    </div>
}

const mapStateToProps = ({app}) => {
    return {
        view: app.view,
        isDesktop: app.isDesktop,
        payments: app.payments
    };
};

const mapDispatchToProps = dispatch => ({ 
    setTransactions: (transactions) => dispatch(actions.onSubscribe(transactions, "transactions"))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoice));