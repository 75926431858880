import React from 'react';
import styled from 'styled-components';

const Td = styled.td`
    &#empty{
        text-align: center;
        background-color: unset;
        cursor: default;
    }
    padding: 1.0416666666666665vw 0.8854166666666666vw;
    #no-payments img{
        width: 100%;
        max-width: 12.395833333333334vw;
        margin: 0 auto;
    }
    p{
        color: #131313;
        font-size: 0.9375vw;
        margin: 0;
        font-weight: normal;
    }
    > #no-payments{
        float: unset;
        flex-direction: column;
    }
    @media(max-width: 1200px){
        > #no-payments img{
            max-width: 18.59375vw;
        }
        > #no-payments p{
            font-size: 1.40625vw;
        }
    }
    @media(max-width: 768px){
        > #no-payments img{
            max-width: 119px;
        }
        > #no-payments p{
            font-size: 15px;
        }
    }
`

export const EmptyTable = ({message, colSpan}) => {
    return (
        <tr>
            <Td colSpan={colSpan} id="empty">
                <div id="no-payments">
                    <img src={require('../../images/payments/nothing-found-grey.svg')} alt="not found"/>
                    <p>{message}</p>
                </div>
            </Td>
        </tr>
    )
}