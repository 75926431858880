import { isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Actions from './Elements/Actions';
import Content from './Elements/Content';
import './Setting.scss';

export const SETTING_TYPES = {
    "FILE": "FILE",
    "INPUT": "INPUT",
    "CODE": "CODE",
    "EDIT": "EDIT"
}

const Setting = ({id, title, description, initialValue, value, setValue, type, validations = [], disabled, onClick, readOnly, suffix}) => {
    const [isValid, setIsValid] = useState(true);
    const [isEdit, setIsEdit] = useState();

    const checkIsValid = useCallback((_value) => {
        const validationList = validations.map(_validationType => {
            const validationType = _validationType.includes(":") ? _validationType.split(':')[0] : _validationType; 

            switch(validationType) {
                case 'required':
                    return !!_value;
                
                case 'number': 
                    return /\s/g.test(_value) || !isNaN(Number(_value));
                
                case 'min':
                    const minIsValid = (Number(_value) >= Number(_validationType.split(':')[1]));
                    if(!minIsValid) {
                        setValue(Number(_validationType.split(':')[1]));
                    }
                    return minIsValid;

                case 'max':
                    const maxIsValid = (Number(_value) <= Number(_validationType.split(':')[1]));
                    if(!maxIsValid) {
                        setValue(Number(_validationType.split(':')[1]));
                    }
                    return maxIsValid;

                default: 
                    return !!_value;
            }
        });

        // check if the is some input value that is not valid.
        if(validationList.some(val => val === false)) return false;
        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validations]);

    useEffect(() => {
        if(validations.length === 0 || disabled) return;
        const _isValid = checkIsValid(value);
        setIsValid(_isValid);
    }, [checkIsValid, disabled, validations, value]);

    useEffect(() => {
        if(!initialValue) return;
        if(isEdit) return setIsEdit(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    return useMemo(() => {
        const isNumber = () => {
            return validations.some(validation => validation === "number");
        }
        
        const onSubmit = () => {
            if(!onClick || !isValid || disabled) return;
            onClick(id, isNumber() ? Number(value) : value);
        }

        const onEdit = () => {
            if(isEdit) {
                onSubmit();
            } else {
                setIsEdit(true);
            }
        }

        const onCancel = () => {
            setIsEdit(false);
            setValue(initialValue);
        }

        const onSetValue = (value) => {
            if(value === "") return setValue("");

            if(type === SETTING_TYPES.EDIT) {
                const _isValid = checkIsValid(value);
                if(!_isValid) return;
            }

            if(isNumber()) return setValue(Number(value));
            
            setValue(value);
        }

        return (
            <div className={`setting-component ${type}`}>
                <div className="setting-component__header">
                    <h3>{title}</h3>
                    <p>{isArray(description) ? description.map((content, index) => <span key={index}>{content}</span>) : description}</p>
                </div>
    
                <Content 
                    type={type} 
                    value={value} 
                    isEdit={isEdit}
                    isClickable={!!onClick}
                    suffix={suffix}
                />
    
                {( 
                    type === SETTING_TYPES.CODE || 
                    type === SETTING_TYPES.FILE || 
                    onClick || 
                    readOnly
                ) && <div className="setting-component__action">
                    <Actions 
                        type={type}
                        suffix={suffix}
                        setValue={!readOnly && onSetValue}
                        disabled={disabled}
                        value={value}
                        onClick={onClick}
                        isValid={isValid}
                        onSubmit={onSubmit}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        onEdit={onEdit}
                        onCancel={onCancel}
                    />
                </div>}
            </div>
        )
    }, [checkIsValid, description, disabled, id, initialValue, isEdit, isValid, onClick, readOnly, setValue, suffix, title, type, validations, value])
}

export default Setting;
