import React from 'react'

export default ({value, onClick, isValid, onSubmit, setValue, disabled, isEdit, onCancel}) => {
    return <div className="setting-component__action--group">
        <input value={value} onChange={({target: {value}}) => setValue(value)} disabled={!setValue} />
        {onClick && <button onClick={onSubmit} disabled={!isValid || disabled}>Save</button> }
        {isEdit && <> 
            &nbsp;<button className="setting-component__action--group-cancel" onClick={onCancel}>Cancel</button>
        </>}
    </div>
}
