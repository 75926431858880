import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import {config} from '../../config';
import Input from '../Common/Input';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import passwordValidator from 'password-validator';
import Recaptcha from 'react-recaptcha';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import {Tooltip} from '../Common/Tooltip';

export var passwordSchema = new passwordValidator();
passwordSchema
.is().min(8)                                    // Minimum length 8
.is().max(15)                                   // Maximum length 15
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits()                                 // Must have digits
.has().not().spaces()                           // Must no have spaces
.has().symbols(1)                               // Must have at least one special letter
 
const minAndMaxSchema = new passwordValidator();
minAndMaxSchema
    .is().min(1)
    .is().max(128);

const Form = styled.form`
    &.signup-form {
        width: 920px;
        max-width: 100%;
        & > div {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            width: 920px;
            max-width: 100%;

            @media (max-width: 1365px){ 
                margin-top: 108px;
            }

            @media (max-width: 767px){
                margin-top: 0;
            }
            
            & .custom-button {
                @media (max-width: 767px) { 
                width: 312px;
                max-width: 100%;
                }
            }

            & .input-component {
                max-width: 50%;
                width: 100%;
                
                @media (max-width: 767px) { 
                    max-width: 100%;
                }

                &:nth-child(even) {
                    padding-right: 32px;
                    @media (max-width: 767px) { 
                        padding-right: 0;
                    }
                }

                &.email {
                    max-width: 100%;
                    padding-right: 0;
                }

                &.password {
                    padding-right: 32px;

                    @media (max-width: 767px) { 
                        padding-right: 0;
                    }
                }

                &.confirm_password {
                    padding-right: 0;
                }
            }
        }
    }
`

const Holder = styled.div`
    display: flex;
    justify-content: ${({checkbox}) => checkbox ? 'flex-start' : 'space-between'};
    margin-top: ${({checkbox}) => checkbox && '16px'};
    margin-bottom: 0;
    width: 100%;
    flex-direction: ${({comment}) => comment && 'column'};
    outline: none;
    .inner-holder{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        > img{
            position: absolute;
            right: 10px;
            top: 50px;
        }
    }
    .country-label{
        padding: 0 !important;
    }
    .flag-select {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: ${({error}) => error ? "solid 1px salmon" : "solid 1px #dde0e6"};
        background-color: #ffffff;
        padding: 0 17px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        position: relative;
    }
    .arrow-down {
        display: none;
    }
    .flag-select .filterBox input{
        padding: 5px;
        box-sizing: border-box;
        font-family: roboto, sans-serif;

        width: 100%;
        margin: 0;
        transform: scale(0.98);
        border: 1px solid #dde0e6;
    }
    .flag-options {
        top: 43px;
        width: 100%;
        left: 0;
    }
    .selected--flag--option {
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #131313;
        padding: 0 !important;
    }
    .flag-select .flag-options::-webkit-scrollbar {
        width: 6px;
    }

    .flag-select .flag-options::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #eceae6;
    }

    .flag-select .flag-options::-webkit-scrollbar-thumb{
        background: #081339; 
        border-radius: 10px;
    }
    .flag-select img{
        margin-right: 5px;
    }
    .flag-select .flag-option:hover{
        background-color: #232744;
    }
    .flag-select .flag-option .country-label{
        color: #131313;
        font-size: 14px;
        font-family: roboto, sans-serif;
    }
    .flag-select .flag-option:hover .country-label{
        color: #ffffff;
    }
    @media(max-width: 768px){
        flex-direction: ${({checkbox}) => !checkbox && 'column'};
        margin-bottom: ${({marginBottom}) => !marginBottom && '0'};
    }
`

const Error = styled.p`
    position: absolute;
    color: salmon;
    font-size: 12px;
    margin: 0;
    bottom: -18px;
    padding-left: 17px;
    @media(max-width: 768px){
        padding-left: 0;
    }
`

const Label = styled.label`
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #131313;
    display: block;
    padding-left: 17px;
    padding-bottom: 7px;
    @media(max-width: 768px){
        padding-left: 0;
    }
`

const CaptchaContainer = styled.div`
    width: 100%;
`;

const CaptchaHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 307px;
    
    border: ${({error}) => error && "1px solid salmon"};
    margin: 0 auto 22px;
    @media(max-width: 768px){
        margin: 0 auto 17px;
    }
`

const CheckboxContainer = styled.div`
    width: 100%;
    margin: 24px auto 16px;
    font-family: Roboto,sans-serif;
    font-size: 14px;
    z-index: 0;
    display: flex;
    justify-content: center;

    & > label {
        display: flex;
        align-items: center;
    }
    & a {
        font-family: inherit;
        color: #10bfa0;
    }
    p{
        margin: 0;
    }
    & .rc-checkbox-inner {
        border-color: ${props => props.errBorder ? "#e35959b3": "#a7abbd" };
        margin-right: 5px;
    }
    .rc-checkbox-checked .rc-checkbox-inner{
        border-color: #081339;
        background-color: #081339;
    }
    .rc-checkbox:hover .rc-checkbox-inner, .rc-checkbox-input:focus + .rc-checkbox-inner{
        border-color: #081339;
    }
    @media(max-width: 768px){
        margin: 35px auto 7px;
        justify-content: flex-start;
    }
`;

const Header = styled.header`
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    img{
        width: 80px;
        margin-right: 24px;
    }
    h1{
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #081339;
        font-family: Roboto;
    }
    @media(max-width: 768px){
        padding-bottom: 0;
        border-bottom: unset;
        margin-bottom: 51px;

        @media (max-width: 767px) { 
            margin-bottom: 32px;
        }

        img{
            width: 40px;
            margin-right: 15px;
        }
        h1{
            font-size: 18px;
        }
    }
`

const DropArrow = styled.div`
    position: absolute;
    width: 48px;
    height: 48px;
    bottom: 1px;
    right: 1px;
    background-color: #dde0e6;
    border-radius: 0 4px 4px 0;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Title = styled.h2`
    margin: 0 48px 24px 16px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    color: black;
    width: 100%;

    @media (max-width: 767px) { 
        margin-left: 0;
    }

    &:not(.first-title) {
        margin-top: 24px;
    }
`

const SubTitle = styled.h3`
    margin: 0 0 24px 16px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    width: 100%;

    @media (max-width: 767px) { 
        margin-left: 0;
    }
`

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    & p {
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
    }
`


const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const namePattern = /^(?=.{1,128}$)[a-z]+(?:['_.\s][a-z]+)*$/i;

class Signup extends Component{

    state = {
        company_name: "",
        company_name_err: false,
        website: "",
        website_err: false,
        first_name: "",
        first_name_err: false,
        last_name: "",
        last_name_err: false,
        email: "",
        email_err: false,
        password: "",
        password_err: false,
        confirm_password: "",
        confirm_password_err: false,
        address_line_2: "",
        address_line_2_err: false,
        address_line: "",
        address_line_err: false,
        city: "",
        city_err: false,
        zip: "",
        zip_err: false,
        country_incorporation: "",
        country_incorporation_err: false,
        captchaVerified: false,
        captcha_err: false,
        terms: false,
        submitWasAttempted: false
    }

    componentWillUnmount() {
        this.props.setTooltipMessage(false, true, "");
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {}
        if(props.errorMessage && props.errorMessage.includes("Account") && !state.email_err) {
            newState.email_err = props.errorMessage;
        }
        return !!Object.keys(newState).length ? {...state, ...newState} : null;
    }

    verifyCallback(){
        this.setState({...this.state, captchaVerified: true, captcha_err: false})
    }

    callback(){
    }

    toggle = () => {
        this.setState({...this.state, terms: !this.state.terms});
    }

    shouldComponentUpdate(nextProps, nextState){
        return nextState.company_name_err !== this.state.company_name_err
                || nextState.website_err !== this.state.website_err
                || nextState.zip_err !== this.state.zip_err
                || nextState.city_err !== this.state.city_err
                || nextState.address_line !== this.state.address_line
                || nextState.address_line_2 !== this.state.address_line_2
                || nextState.email_err !== this.state.email_err
                || nextState.password_err !== this.state.password_err
                || nextState.confirm_password_err !== this.state.confirm_password_err
                || nextState.first_name_err !== this.state.first_name_err
                || nextState.last_name_err !== this.state.last_name_err
                || nextState.country_incorporation_err !== this.state.country_incorporation_err
                || nextState.captcha_err !== this.state.captcha_err
                || nextProps.isDesktop !== this.props.isDesktop
                || nextProps.errorMessage !== this.props.errorMessage
                || nextProps.tooltipMessage !== this.props.tooltipMessage
                || nextProps.paymentMethods !== this.props.paymentMethods
    }

    onSelectCountry = (name, val) => {
        const newState = {...this.state, [name]: val, [`${name}_err`]: false};
        newState[`${name}_err`] = false;
        this.setState(newState);
    }

    onSelectOption(option, name){
        this.setState({...this.state, [name]: option, [`${name}_err`]: false})
    }

    onChangeHandler(value, id){
        if(id === "password" || id === "confirm_password") {
            this.setState({
                ...this.state, [id]: value, 
                "password_err": false, 
                "confirm_password_err": false
            });
        } else {
            this.setState({...this.state, [id]: value, [`${id}_err`]: false});
        }
    }

    validate(){
        let isValid = true;
        const newState = {...this.state};
        const mandatoryFields = ["email", "country_incorporation", "company_name", "password", "confirm_password", "first_name", "website", "address_line", "last_name", "city", "zip"];
        mandatoryFields.forEach(field => {
            if(this.state[field] === ""){
                isValid = isValid && false;
                newState[`${field}_err`] = "Required field";
            }
        })
        
        if(this.state.email !== "" && !pattern.test(this.state.email)){
            isValid = false;
            newState['email_err'] = "Invalid email";
        }
        let failedPasswordRules = passwordSchema.validate(this.state.password, {list: true});
        const passwordErr = "Select an 8-15 character password with one number, lowercase and uppercase letter and at least one special letter.";
        if(this.state.password !== "" && failedPasswordRules.length > 0) {
            newState.password_err = passwordErr;
            isValid = false;
        }

        let failedConfirmPasswordRules = passwordSchema.validate(this.state.confirm_password, {list: true});
        if(this.state.confirm_password !== "" && failedConfirmPasswordRules.length > 0) {
            newState.confirm_password_err = passwordErr;
            isValid = false;
        }

        if(failedPasswordRules.length === 0 && failedConfirmPasswordRules.length === 0) {
            if(this.state.password !== this.state.confirm_password) {
                newState.password_err = "Your password and confirmation password do not match.";
                newState.confirm_password_err = "Your password and confirmation password do not match.";
                isValid = false;
            }
        }

        if(this.state.first_name !== "" && !namePattern.test(this.state.first_name)) {
            newState.first_name_err = "only letters, min 1, max 128 characters";
            isValid = false;
        }

        if(this.state.last_name !== "" && !namePattern.test(this.state.last_name)) {
            newState.last_name_err = "only letters, min 1, max 128 characters";
            isValid = false;
        }

        if(this.state.company_name !== "" && !minAndMaxSchema.validate(this.state.company_name)) {
            newState.company_name_err = "min 1, max 128 characters.";
            isValid = false;
        }

        if(this.state.address_line_2 !== "" && !minAndMaxSchema.validate(this.state.address_line_2)) {
            newState.address_line_2_err = "min 1, max 128 characters.";
            isValid = false;
        }

        if(this.state.address_line !== "" && !minAndMaxSchema.validate(this.state.address_line)) {
            newState.address_line_err = "min 1, max 128 characters.";
            isValid = false;
        }
        
        if(this.state.city !== "" && !minAndMaxSchema.validate(this.state.city)) {
            newState.city_err = "min 1, max 128 characters.";
            isValid = false;
        }

        if(this.state.zip !== "" && !minAndMaxSchema.validate(this.state.zip)) {
            newState.zip_err = "min 1, max 128 characters.";
            isValid = false;
        }

        if(!this.state.captchaVerified){
            isValid = false;
            newState['captcha_err'] = true
        }

        if(!this.state.terms) {
            newState.submitWasAttempted = true
            isValid = false;
        }
     
        this.setState(newState)
        return isValid;
    }

    onSubmit(e){
        e.preventDefault();
        if(this.validate()){
            this.props.onSignup({
                firstName: this.state.first_name,
                lastName:  this.state.last_name,
                email:  this.state.email,
                password:  this.state.password,
                logoUrl:  "default",
                companyName:  this.state.company_name,
                websiteUrl: this.state.website,
                country:  this.state.company_name,
                city:  this.state.city,
                addressLine1:  this.state.address_line,
                addressLine2:  this.state.address_line_2,
                zip:  this.state.zip,
            })
        }
    }

    render(){
        const {tooltipMessage} = this.props
        return(
            <Form className="signup-form" onSubmit={(e) => this.onSubmit(e)}>
                <Header>
                    <img src={require('../../images/signup/account.svg')} alt="create account"/>
                    <h1>Create Account</h1>
                </Header>

                {tooltipMessage.show && <Tooltip message={config.tooltipMessages[tooltipMessage.key] || config.tooltipMessages.generalfailMsg} fullWidth/>}

                <div>
                    <Title className="first-title">Account Details</Title>
                    <Input type="text" tabIndex="1" error={this.state.first_name_err} label={config.signup.first_name} id="first_name" onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    <Input type="text" tabIndex="2" error={this.state.last_name_err} label={config.signup.last_name} id="last_name" onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    <Input type="text" tabIndex="3" label={config.signup.email} id="email" error={this.state.email_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    <Input type="password" tabIndex="4" label={config.signup.password} id="password" error={this.state.password_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm isPassword className="isForm"/>
                    <Input type="password" tabIndex="5" label={config.signup.confirmPassword} id="confirm_password" error={this.state.confirm_password_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm isPassword className="isForm"/>

                    <Title>Company details</Title>
                    <Input type="text" tabIndex="6" label={config.signup.company} id="company_name" error={this.state.company_name_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    <Input type="text" tabIndex="7" label={config.signup.website} id="website" error={this.state.website_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    
                    <Title>Address</Title>
                    <SubTitle>Please provide the addresses where your company is legally registered</SubTitle>
                    <Input type="text" tabIndex="8" label={config.signup.addressLine} id="address_line" error={this.state.address_line_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    <Input type="text" tabIndex="9" label={config.signup.addressLine2} id="address_line_2" error={this.state.address_line_2_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    <Input type="text" tabIndex="10" label={config.signup.city} id="city" error={this.state.city_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    <Input type="text" tabIndex="11" label={config.signup.zip} id="zip" error={this.state.zip_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>

                    <Holder marginBottom error={this.state.country_incorporation_err}>
                        <div className="inner-holder">
                            <Label>{config.signup.country}</Label>
                            <ReactFlagsSelect ref="country_incorporation1" tabIndex="12" searchable={true} placeholder="" onSelect={c => this.onSelectCountry('country_incorporation', c)} value={this.state.country_incorporation}/>
                            <DropArrow>
                                <img src={require("../../images/payments/dropdown.svg")} alt="" width="16px"/>
                            </DropArrow>
                            {this.state.country_incorporation_err && <Error>{this.state.country_incorporation_err}</Error>}
                        </div>
                    </Holder>
            
                    <CheckboxContainer errBorder={!this.state.terms && this.state.submitWasAttempted}>
                        <label>
                            <Checkbox onChange={() => this.toggle()} />
                            <p>
                                I agree to the &nbsp;
                                <a href={`/files/terms.pdf`} target="_blank" rel="noopener noreferrer">Terms of Use</a>
                            </p>
                        </label>
                    </CheckboxContainer>

                    <CaptchaContainer>
                        <CaptchaHolder error={this.state.captcha_err}>
                            <Recaptcha
                                sitekey="6LdeZbYUAAAAAAfiA6Nz6VRv1kAm9FvTwqAks5Wf"
                                render="explicit"
                                verifyCallback={() => this.verifyCallback()}
                                onloadCallback={() => this.callback()}
                                badge
                            />
                        </CaptchaHolder>
                    </CaptchaContainer>

                    <BottomContainer>
                        <button className="custom-button" type="submit" onClick={(e) => this.onSubmit(e)}>create account</button>
                        <p style={{textAlign: "center"}}>Already have an account? <Link to={config.root_path}>Login</Link></p>
                    </BottomContainer>
                </div>
            </Form>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        isDesktop: app.isDesktop,
        tooltipMessage: app.tooltipMessage,
        paymentMethod: !!app.paymentMethods.length ? app.paymentMethods[0] : "BTC"
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignup: payload => dispatch(actions.onSignup(payload)),
        setView: (view) => dispatch(actions.setView(view)),
        setTooltipMessage: (show, isSuccess, key) => dispatch(actions.setTooltipMessage(show, isSuccess, key))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));