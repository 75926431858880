import React from 'react';
import InnerTitle from '../../Common/InnerTitle';
import styled from 'styled-components';
import { commaFormatted, toDisplayAmount } from '../../../shared/utility';
import { config } from '../../../config';
import { connect } from 'react-redux';

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #dde0e6;
    margin: 0 auto;
    @media(min-width: 769px){
        display: none;
    }
`

export const Balance = ({title, path, total}) => {
    return (
        <React.Fragment>
            <InnerTitle title={title} path={path} total={`${total || total === 0 ? `${commaFormatted(total)} ADA` : ""}`}/>
        </React.Fragment>
    )
}


const Total = ({desktop, balance = {}, baseCurrencySymbol, ...props}) => {
    
    return (
        desktop ? 
        <Balance 
            title={config.overview.totalBalances.title} 
            path={config.overview.totalBalances.path} 
            total={toDisplayAmount(balance.balance, config.currencies.ADA.decimals, 2)} 
            baseCurrencySymbol={baseCurrencySymbol}/> 
        : 
        <React.Fragment>
            <InnerTitle title={config.overview.totalBalances.title} path={config.overview.totalBalances.path} total={`${toDisplayAmount(balance.balance, config.currencies.ADA.decimals, 2)} ADA`} mobile/>
          
            {!desktop && <Divider/>}
        </React.Fragment>
    )
} 

const mapStateToProps = ({app}) => {
    return {
        currencies: app.currencies,
        account: app.account
    };
};


export default connect(mapStateToProps)(Total);