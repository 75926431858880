import React, { Component } from 'react';
import ViewsRouter from './Router';
import Menu from '../Menu/Menu';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import {config} from '../../config';
import LogoutButton from '../Common/LogoutButton';

const AppContainer = styled.div`
    display: flex;
    max-width: 1920px;
    width: 100%;
`

const View = styled.div`
    width: calc(100% - 16.6vw);
    background-color: #f5f7fa;
    padding: 1.0416666666666665vw 2.604166666666667vw 48px;
    min-height: 100vh;
    box-sizing: border-box;
    margin-left: 16.666666666666664vw;
    @media(max-width: 1200px){
        padding: 1.5624999999999998vw 2.604166666666667vw 48px;
        margin-left: 25vw;
    }
    @media(max-width: 768px){
        margin-left: 0;
        padding: 114px 24px 25px;
        width: 100%;
    }
`

let currentView = '';

class Views extends Component {

    componentDidMount(){
        const path = this.props.history.location.pathname.split("/")[2];
        const item = config.items[path]
        if(item){
            const view = item.label;
            this.props.setView(view)
            currentView = view;
        }
    }
    componentDidUpdate(prevProps, prevState){
        const path = this.props.history.location.pathname.split("/")[2]
        const item = config.items[path]
        if(item && currentView !== item.label) {
            this.props.setView(item.label)
            currentView = item.label;
        }
    }

    render(){
        return ( 
            <AppContainer>
                <Menu />
                <View>
                    <LogoutButton title={this.props.view} isDesktop={this.props.isDesktop} username={this.props.username}/>
                    <ViewsRouter />
                </View>
            </AppContainer>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        view: app.view,
        windowWidth: app.windowWidth,
        isDesktop: app.isDesktop,
        username: `${app.account.firstName} ${app.account.lastName}`
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setView: (view) => dispatch(actions.setView(view)),
        setWindowWidth: width => dispatch(actions.setWindowWidth(width))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));