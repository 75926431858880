export const config = {
    host: process.env.REACT_APP_API_HOST,
    apiVersion: '/api/v1',
    root_path: '/dashboard',
    register: '/registration',
    cookie_name: 'cg_id',
    logo: "adapayBusinessCoti.svg",
    logo_dark: "adapayBusinessCoti-dark.svg",
    items: {
        overview: {label: "Overview", path: "/overview"},
        payments: {label: "Payments", path: "/payments"},
        withdrawals: {label: "Withdrawals", path: "/withdrawals" },
        tools: {label: "Integration tools", path: "/tools"},
        settings: {label: "Settings", path: "/settings"}
    },
    appViews: {
        status: {path: "/status"},
        verify_email: {path: "/verify_email"},
        resetPassword: {path: "/forgot_password"},
    },
    overview: {
        totalBalances: {
            title: "Total balance",
            path: "total.svg"
        },
        revenue: {
            title: "Revenue",
            path: "revenue.svg"
        },
        payments: {
            title: "recent payments",
            path: "recent.svg"
        }
    },
    signup: {
        company: "Company name",
        website: "Website",
        country: "Country of origin",
        first_name: "First name",
        last_name: "Last name",
        email: "Email",
        password: "Password",
        confirmPassword: "Confirm password",
        addressLine: "Address line 1",
        addressLine2: "Address line 2 (optional)",
        city: "City",
        zip: "Zip",
        phone: "Phone number",
        street: "Street",
        technology: "Technology",
        profile: "Profile",
        address: "Address",
        contact: "Contact",
        description: "Please provide the address where your company is legaly registered.",
        blockedCountry: "We currently cannot accept clients from your country.",
    },
    payments: {
        searchInput: "Search by Payment ID or Email",
        searchIcon: "search.svg",
        filterOptions: [
            {label: "All", value: "all"},
            {label: "Last 30 days", value: "last30"},
            {label: "Last 3 months", value: "last3"},
            {label: "Year to date", value: "yearToDate"},
            {label: "Custom", value: "custom"},
        ],
        maxRows: {
            overview: 3, 
            payments: 7
        },
        defaultFromDate: "2019-10-01"
    },
    withdrawals: {
        maxRows: 7,
        headers: ["Date", "Address", "Status", "Fees", "Amount"],
        searchInput: "Search by address",
        fiat: "USD",
        crypto: "BTC"
    },
    logout: "logout.svg",
    homepage: process.env.REACT_APP_HOMEPAGE,
    buttonSize: [
        {label: "S", width: "120", height: "24", name: "Small"}, 
        {label: "M", width: "160", height: "32", name: "Medium"}, 
        {label: "L", width: "240", height: "48", name: "Large"}
    ],
    txStatuses: {
        new: "new.svg",
        refund: "refund.svg",
        partialRefund: "refund.svg",
        paid: "paid.svg",
        completed: "completed.svg",
        pending: "pending.svg",
        failed: "failed.svg",
        expired: "expired.svg",
        confirmed: "completed.svg",
        default: "failed.svg",
        "payment-sent": "payment-sent.svg"
    },
    withdrawalStatuses: {
        pending: {path: "pending.svg", name: "Pending"},
        processing: {path: "tl-processing.svg", name: "Processing"},
        confirmed: {path: "confirmed.svg", name: "Completed"},
        failed: {path: "rejected.svg", name: "Failed"},
    },
    txHeaders: ["date", "email", "payment ID", "status", "requested", "received"],
    resetPassword: {
        newPass: "New password",
        confirmPass: "Re-enter new password",
        oldPassword: "Old password"
    },
    baseCurrenciesSymbols: {
        USD: "$",
        EUR: "€",
        GBP: "£",
        CAD: "$",
        AUD: "$"
    },
    noCurrencyMessage: {
        expired: "No Currency Selected",
        pending: "Awaiting Currency Selection.."
    },
    tools: {
        https: "https://",
        http: "http://",
    },
    responseIcons: {
        successIcon: "completed.svg",
        failIcon: "fail.svg",
        warning: "warning.svg",
        alert: "notification.svg"
    },
    currencyTypes: {
        fiat: ["usd", "eur"],
        crypto: ["btc", "ada", "eth"]
    },
    defaultDecimals: 2,
    timeline: {
        pending: {label: "Created", path: "new-2.svg"},
        created: {label: "Created", path: "new-2.svg"},
        pendingWithdrawal: {label: "Pending", path: "timeline-awaiting-approval.svg"},
        paid: {label: "Paid", path: "paid-dark-blue-2.svg"},
        confirmed: {label: "Completed", path: "timeline-approved.svg"},
        completed: {label: "Completed", path: "completed-dark-blue-2.svg"},
        failed: {label: "Failed", path: "failed-2.svg"},
        expired: {label: "Expired", path: "expired-2.svg"},
        cancelled: {label: "Cancelled", path: "timeline-cancelled.svg"},
        processing: {label: "Processing", path: "timeline-processing.svg"},
        approved: {label: "Approved", path: "timeline-approved.svg"},
        rejected: {label: "Rejected", path: "failed-2.svg"},
        fully_processed: {label: "Fully processed", path: "completed-dark-blue-2.svg"},
        explorerUrl: {
            BTC: "https://btc.com/",
            ADA: "https://cardanoexplorer.com/"
        }
    },
    withdrawalsTimeline:{
        "createTime": "created", 
        "pendingAt": "pendingWithdrawal", 
        "rejectedAt": "rejected", 
        "acceptedAt": "approved", 
        "processingAt": "processing" , 
        "fullyProcessedAt": "fully_processed"
    },
    apiKeyMessage: "Generating a new API key will cancel the previous one and requires an update for continued payment services.\n\n Are you sure you’d like to continue?",
    tooltipMessages: {
        verifiedFailed: "Email verification failed",
        verifiedSuccess: "Email verification successful",
        resetPasswordFailed: "Password update failed. Please try again",
        resetPasswordSuccess: "Password was updated successfully",
        resetPasswordExpired: "Reset password link expired. Please try again.",
        generalfailMsg: "Oops... something went wrong. Please try again",
        updatePasswordSuccess: "Password was updated successfully",
        updatePasswordFailed: "Password update failed. Please try again",
        apiKeySuccess: "New API key was generated successfully",
        apiKeyFailed: "New API key generation failed. Please try again",
        updatedSettings: "Updated successfully",
        updatSettingsFailed: "Update failed.",
        withdrawalRequestSuccess: "Withdraw request submitted successfully",
        withdrawalRequestFailed: "There was an error submitting your withdrawal request",
        wrongOldPassword: "Your old password is incorrect",
        requestResetPasswordSuccess: "We’ve sent you an email containing a password recovery link. Please follow the instructions in the email to complete the password reset process.",
        requestResetPasswordFailed: "There was an error sending your password recovery email.",
        invalidEmailOrPassword: "Invalid email or password.",
        emailNotVerified: "Email is not verified",
        accountNotApproved: "Account not approved",
        emailUnique: "Account already exist.",
        companyNameUnique: "Company name already exist",
        failedToEstimateFees: "Fees estimation failed.",
        invalidAddress: "Please enter a valid cardano address"
    },
    tooltipsErrorsIds: {
        "settings": "settings"
    },
    lpItems: ["Instant settlement to 35 currencies", "No chargeback fraud", "Global coverage", "No rolling reserve", "Low fees"],
    decimalsUSD: {
        ADA: 6,
        BTC: 2
    },
    merchants: {
        'save-the-children': {
            image: "save-the-children.svg"
        }
    },
    currencies: {
        ADA: {
            symbol: "ADA",
            decimals: "6"
        }
    },
    transaction: {
        maxMonitoredDepth: 15
    },
    statuses: {
        new: "new",
        confirmed: "confirmed",
        expired: "expired",
        pending: "pending",
        "payment-sent": "payment-sent",
    },
    statusMessages: {
        "thankYou": "Thank you for signing up!",
        "confirmEmail": "Confirm Your email",
    },
    statusMessagesFormat: {
        "thankYou": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. ",
    },
    statusesFormat: {
        confirmed: "Completed",
        expired: "Expired",
        failed: "Failed",
        paid: "Paid",
        new: "New",
        pending: "Pending",
        "payment-sent": "Payment Sent"
    },
    statusesColors: {
        confirmed: "#11deb3",
        expired: "#eab632",
        failed: "#dd5a46",
        paid: "#28acd7",
        new: "black",
        pending: "#eab632",
        "payment-sent": "#28acd7"
    },
    subStatusMessages: {
        partial: "refund."
    },
    settings: {
        webhook: "webhook",
        success: "success",
        paymentRequestApprovalPercentage: "paymentRequestApprovalPercentage",
        expireTime: "expireTime",
        receipt: "receipt"
    },
}

