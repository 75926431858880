import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter  } from 'react-router';
import { connect } from 'react-redux';
import arrowLeftFirst from '../../images/payments/left-arrow-first.svg';
import arrowLeftFirstHover from '../../images/payments/left-arrow-first-hover.svg';
import arrowLeft from '../../images/payments/left-arrow.svg';
import arrowLeftHover from '../../images/payments/left-arrow-hover.svg';
import arrowRight from '../../images/payments/right-arrow.svg';
import arrowRightHover from '../../images/payments/right-arrow-hover.svg';
import arrowRightLast from '../../images/payments/right-arrow-last.svg';
import arrowRightLastHover from '../../images/payments/right-arrow-last-hover.svg';

const PaginationWrapper = styled.div`
    justify-content: center;
    display: flex;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.83;
    letter-spacing: normal;
    text-align: left;
    color: #131313;
    font-family: roboto, sans-serif;
    align-items: center;
    margin-top: 30px;
    @media(max-width: 320px){
        font-size: 13px;
    }
`

const Wrapper = styled.div`
    cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
    opacity: ${({disabled}) => disabled ? '0.1' : '1'};
    display: flex;
    align-items: center;
    & > div{
        cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
        width: 24px;
        height: 24px;
        background-size: contain;
        transition: all .3s ease-in;
    }
`

const Numbers = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
    min-width: 130px;
`

const LeftArrowFirst = styled.div`
    background-image: url(${arrowLeftFirst});
    margin: 0 8px;
    :hover{
        background-image: ${({disabled}) => !disabled && `url(${arrowLeftFirstHover})`};
    }
`

const LeftArrow = styled.div`
    background-image: url(${arrowLeft});
    :hover{
        background-image: ${({disabled}) => !disabled && `url(${arrowLeftHover})`};
    }
`

const RightArrow = styled.div`
    background-image: url(${arrowRight});
    :hover{
        background-image: ${({disabled}) => !disabled && `url(${arrowRightHover})`};
    }
`

const RightArrowLast = styled.div`
    background-image: url(${arrowRightLast});
    margin: 0 8px;
    :hover{
        background-image: ${({disabled}) => !disabled && `url(${arrowRightLastHover})`};
    }
`

class Pagination extends Component {

    state = {
        active: 1,
        to: 0,
        from: 0
    }

    async componentDidMount(){
        if(!!this.props.array.length){
            await this.setState({...this.state, active: Math.ceil(this.props.array.length/this.props.maxRows)});
            const page = 1;
            this.setPage(page, this.props.array.length)
        }
    }


    async componentDidUpdate(prevProps){
        if(this.props.array.length !== prevProps.array.length){
            const page = (prevProps.filterOn !== this.props.filterOn || this.props.filterOn) ? 1 : this.state.active;
            this.setPage(page, this.props.array.length)
        }
    }

    setPage(page, propsArray){
        if(page > Math.ceil(this.props.array.length/this.props.maxRows) || page < 1){
            return
        }
        let to = page*this.props.maxRows;
        let from = to - this.props.maxRows;
        this.setState({...this.state, active: page, to, from});
        return this.props.setFilterdRows(from, to); 
    }

    render(){
        const isLastPage = this.state.active === Math.ceil(this.props.array.length/this.props.maxRows);
        return(
            this.props.array.length > this.props.maxRows ?
            <PaginationWrapper>
                <Wrapper prev disabled={this.state.active === 1}>
                    <LeftArrowFirst disabled={this.state.active === 1} onClick={() => this.setPage(1)}/>
                    <LeftArrow disabled={this.state.active === 1} onClick={() => this.setPage(this.state.active - 1)}/>
                </Wrapper>
                <Numbers>
                    {this.state.from + 1} - {this.props.maxRows === this.props.totalDisplayed ? this.state.to :  this.props.array.length} of {this.props.array.length}
                </Numbers>
                <Wrapper next disabled={isLastPage}>
                    <RightArrow disabled={isLastPage} onClick={() => this.setPage(this.state.active + 1)}/>
                    <RightArrowLast disabled={isLastPage} onClick={() => this.setPage(Math.ceil(this.props.array.length/this.props.maxRows))}/>
                </Wrapper>
            </PaginationWrapper>
            :
            null
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        windowWidth: app.windowWidth,
    };
};
export default withRouter(connect( mapStateToProps, null )( Pagination ));