import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner/Spinner';
import  { motion } from 'framer-motion';
import { scaleVariants } from '../../Animations';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    font-family: roboto, sans-serif;
    font-size: 1.25vw;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #081339;
    margin-bottom: ${({isTable, isSubtitle}) => isTable ? "1.6666666666666667vw" : isSubtitle ? "0" : "1.6666666666666667vw"};
    padding-top: ${({isSubtitle, isTimeline}) => (!isSubtitle && !isTimeline) && '1.25vw'};
    > img{
        margin-right: 1.1979166666666667vw;
        width: 4.166666666666666vw;
    }
    @media(max-width: 1200px){
        font-size: 1.875vw;
        > img{
            margin-right: 1.796875vw;
            width: 6.249999999999999vw;
        }
    }
    @media(max-width: 768px){
        > img{
            width: 40px;
        }
        margin-bottom: 0;
        padding-top: 0;
    }
`

const Header = styled.h3`
    margin: 0;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    img{
        width: 0.8333333333333334vw;
        margin-left: 0.26041666666666663vw;
        vertical-align: middle;
    }
    > span{
        font-size: ${({isSubtitle}) => isSubtitle ? "0.9375vw" : "1.6666666666666667vw"};
        font-weight: ${({isSubtitle}) => isSubtitle && '500'};
    }
    #currency{
        font-weight: normal;
        display: flex;
        align-items: center;
        margin-top: 5px;
        > img{
            width: 1.25vw;
            margin: 0 8px 0 0;
        }
    }
    > div > #invoiceId{
        font-size: 0.8854166666666666vw;
        text-transform: initial;
        font-weight: normal;
        display: block;
    }
    @media(max-width: 1200px){
        > span{
            font-size: ${({isSubtitle}) => isSubtitle ? "1.40625vw" : "2.5vw"};
        }
        > div > #invoiceId{
            font-size: 1.328125vw;
        }
        #currency > img{
            width: 1.875vw;
        }
    }
    @media(max-width: 768px){
        font-size: 18px;
        > span{
            display: ${({isSubtitle}) => !isSubtitle && 'none'};
            font-size: 12px;
        }
        img{
            width: 16px;
            margin-left: 13px;
        }
        #currency > img{
            width: 16px;
        }
        > div > #invoiceId{
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 54vw;
        }
    }
    @media(max-width: 320px){
       font-size: 12px;
       > div > #invoiceId{
           font-size: 12px;
       }
       > span{
            font-size: 11px;
       }
    }
`

const Total = styled.p`
    margin: 16px 0 60px;
    font-size: 32px;
    font-weight: bold;
    color: #131313;
    font-family: roboto, sans-serif;
`

const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const getInnerTitleWithSpan = (title) => {
    const titleLeftSide = title.substring(0, 10);
    const paymentId = title.substring(11);
    return (
        <div className="invoice-wrapper">
            {titleLeftSide}<br/>
            <span id="invoiceId"> {paymentId}</span>
        </div>
    )
}

const InnerTitle = ({title, isLoading, path, total, mobile, isTable, exceptionalStatus, isSubtitle, isTimeline, currency}) => {
    return (
        <React.Fragment>
            <Wrapper isTable={isTable} isSubtitle={isSubtitle} isTimeline={isTimeline}>
                <motion.img src={require(`../../images/inner-title/${path}`)} alt={title} initial="hidden" animate="visible"  exit="hidden" variants={scaleVariants}/>
                <Header isSubtitle={exceptionalStatus || isSubtitle}>
                    {title?.includes("Payment ID") ? getInnerTitleWithSpan(title) : title}
                    {isLoading && <Loading>Loading <Spinner type="dot-collision" /> </Loading>}
                    {total && 
                        <span>
                            {total}
                            {exceptionalStatus && <img src={require(`../../images/payments/${exceptionalStatus.toLowerCase()}.svg`)} alt={exceptionalStatus}/>}
                        </span>
                    }
                    {currency &&
                        <span id="currency">
                            <img src={require(`../../images/inner-title/${currency.toLowerCase()}.svg`)} alt={currency}/>
                            {currency.toUpperCase()}
                        </span>
                    }
                </Header>
            </Wrapper>
            {total && mobile && <Total>{total}</Total>}
        </React.Fragment>
    )
}

export default InnerTitle;