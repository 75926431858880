import * as actionTypes from "./actionTypes";

export const onAuth = account => {
    return {
        type: actionTypes.ON_AUTH,
        account
    }
}

export const onLogin = payload => {
    return {
        type: actionTypes.ON_LOGIN,
        payload
    }
}

export const onSignup = payload => {
    return {
        type: actionTypes.ON_SIGNUP,
        payload
    }
}

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    }
}
