import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {calcSize} from '../../../shared/utility';
import Total from './Balances';
import Payments from '../Payments/Table';
import InnerTitle from '../../Common/InnerTitle';
import  { motion } from 'framer-motion';
import { pageVariants } from '../../../Animations';

const Grid = styled.div`
    > .item1 { 
        grid-area: total; 
        padding-right: 4.53125vw;
        box-sizing: border-box;
    }
    > .item2 { 
        grid-area: revenue; 
        border-left: 1px solid #0813391a;
        padding-left: 3.6458333333333335vw;
    }
    > .item4{
        grid-area: payments; 
        margin-top: 3.75vw;
    }
    padding-left: 4.166666666666666vw;
    padding-right: 6.25vw;
    margin-top: 1.0416666666666665vw;
    padding-top: 2.5vw;
    background-color: #ffffff;
    padding-bottom: 4.166666666666666vw;
    @media(max-width: 1200px){
        grid-template-areas:
        'total'
        'revenue'
        'payments';
        .item2{
            padding-left: 0;
            border-left: none;
            margin-top: ${()=> calcSize(72) + 'vw'};
        }
        > .item1 { 
            padding-right: 0;
        }
    }
    @media(max-width: 768px){
        grid-template-areas:
        'total'
        'revenue'
        'payments';
        padding: 0;
        background-color: unset;
        .item2, .item4{
            margin-top: 40px;
        }
    }
`

const Overview = (props) => {
    return (
        <motion.div initial="hidden" animate="visible"  exit="hidden" variants={pageVariants}>
            <Grid>
                <div className="item1">
                    <Total desktop={props.isDesktop} balance={props.balance} baseCurrencySymbol={props.account.baseCurrencySymbol}/>
                </div>
                <div className="item4">
                    <InnerTitle title="Recent Payments" path="recent.svg"/>
                    <Payments isOverview/>
                </div>
            </Grid>
        </motion.div>
    )
}

const mapStateToProps = ({app}) => ({
    isDesktop: app.isDesktop,
    balance: app.balance,
    account: app.account
});

export default withRouter(connect(mapStateToProps)(Overview));
