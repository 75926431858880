import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    //eslint-disable-next-line
    [ref, handler]
  );
}

export const useApiValue = (apiValue, id) => {
  const [value,  setValue] = useState("");
  const { tooltipMessage } = useSelector(({app}) => app);

  useEffect(() => {
      if(!apiValue) return;
      setValue(apiValue);
  }, [apiValue]);
  
  useEffect(() => {
      if(!tooltipMessage.id) return;
      if(tooltipMessage.id === id) return setValue(apiValue);
  }, [apiValue, id, tooltipMessage]);

  return [value, setValue];
}