import React from 'react'
import ApplyAsMerchantButton from '../Common/ApplyAsMerchantButton';
import './Welcome.scss';

const Welcome = () => {
    return (
        <div className="welcome-component">
            <img className="adapay" src={require('../../images/adapay.svg')} alt="adapay" />

            <h1>Welcome to ADA Pay</h1>

            <h3>
                Accept ADA payments.<br/>
                Direct. Secure. From Anywhere. For Everyone
            </h3>

            <ApplyAsMerchantButton position="Top" />
        </div>
    )
}

export default Welcome;