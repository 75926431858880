import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import {config} from '../../config';
import Input from './Input';
import { Tooltip } from './Tooltip';
import xhrApi from '../../xhr-api';
import { passwordSchema } from '../Signup/Signup';

const Header = styled.header`
    display: flex;
    align-items: center;
    padding-bottom: ${({isSettings}) => isSettings ? "1.6666666666666667vw" : "32px"};
    margin-bottom: ${({isSettings}) => isSettings ? "1.7708333333333333vw" : "34px"};
    padding-top: ${({isSettings}) => isSettings && '1.25vw'};
    position: relative;
    img#arrow{
        width: 22px;
        cursor: pointer;
        left: -2.083333333333333vw;
        position: absolute;
    }
    img#icon{
        width: ${({isSettings}) => isSettings ? "4.166666666666666vw" : "80px"};
        margin-right: ${({isSettings}) => isSettings ? "1.25vw" : "24px"};
    }
    h1{
        margin: 0;
        font-size: ${({isSettings}) => isSettings ? "1.25vw" : "24px"};
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #081339;
    }
    @media(max-width: 1200px){
        padding-bottom: ${({isSettings}) => isSettings && "2.5vw"};
        margin-bottom: ${({isSettings}) => isSettings && "2.65625vw"};
        h1{
            font-size: ${({isSettings}) => isSettings && "1.875vw"};
        }
        img#arrow{
            left: -2.03125vw;
        }
        img#icon{
            width: ${({isSettings}) => isSettings && "6.249999999999999vw"};
            margin-right: ${({isSettings}) => isSettings && "1.875vw"};
        }
    }
    @media(max-width: 768px){
        padding: 0 24px 24px;
        margin-bottom: 24px;
        margin-left: -24px;
        margin-right: -24px;
        img#icon{
            width: 40px;
            margin-right: 15px;
        }
        h1{
            font-size: 18px;
        }
        img#arrow{
            position: initial;
            margin-right: 12px;
        }
    }
`

const Button = styled.button`
    width: 200px;
    height: 40px;
    border-radius: 4px;
    background-color: #11deb3;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`

class ChangePassword extends Component{

    state = {
        old_password: "",
        new_password: "",
        confirm_password: "",
        old_password_err: false,
        new_password_err: false,
        confirm_password_err: false,
        
    }

    componentDidMount() {
        this.checkResetPassword();
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.state.old_password_err !== nextState.old_password_err 
                || this.state.new_password_err !== nextState.new_password_err 
                || this.state.confirm_password_err !== nextState.confirm_password_err
                || nextProps.isDesktop !== this.props.isDesktop
                || nextProps.tooltipMessage.show !== this.props.tooltipMessage.show
    }

    componentDidUpdate(prevProps){
        if(!prevProps.tooltipMessage.show && this.props.tooltipMessage.show){
            setTimeout(() => this.props.setTooltipMessage(false, true, ""), 10000);
        }
    }

    componentWillUnmount(){
        if(this.props.tooltipMessage.show){
            this.props.setTooltipMessage(false, true, "")
        }
    }

    onChangeHandler(value, id){
        if(id === "password" || id === "confirm_password") {
            this.setState({
                ...this.state, [id]: value, 
                "password_err": false, 
                "confirm_password_err": false
            });
        } else {
            this.setState({...this.state, [id]: value, [`${id}_err`]: false});
        }
    }

    getRequestCode() {
        const urlParams = new URLSearchParams(window.location.search);
        const requestCode = urlParams.get('requestCode');
        return requestCode || null;
    }

    async checkResetPassword() {
        try {
            const requestCode = this.getRequestCode();
            if(!requestCode) return;

            await xhrApi.CHECK_RESET_PASSWORD({requestCode});
        } catch(error) {
            console.log(error);
            this.props.setTooltipMessage(true, false, "generalfailMsg");
            return false;
        }
    }

    validate(){

        const state = {};
        let allowed = true;

        let failedPasswordRules = passwordSchema.validate(this.state.new_password, {list: true})
        if(failedPasswordRules.length > 0) {
            state.new_password_err = "Select an 8-15 character password with one number, lowercase letter and uppercase letter."
            allowed = false;
        }

        let failedConfirmPasswordRules = passwordSchema.validate(this.state.confirm_password, {list: true})
        if(failedConfirmPasswordRules.length > 0) {
            state.confirm_password_err = "Select an 8-15 character password with one number, lowercase letter and uppercase letter."
            allowed = false;
        }

        if(this.state.new_password !== this.state.confirm_password) {
            state.new_password_err = "The new password and confirmation password do not match";
            state.confirm_password_err = "The new password and confirmation password do not match";
            allowed = false;
        }
        
        failedPasswordRules = passwordSchema.validate(this.state.old_password, {list: true})
        if(!this.props.isForgot && failedPasswordRules.length > 0){
            state.old_password_err = "Invalid password";
            allowed = false;
        }

        const mandatoryFields = this.props.isForgot ? ["new_password", "confirm_password"] :  ["old_password", "new_password", "confirm_password"];
        mandatoryFields.forEach(field => {
            if(this.state[field] === ""){
                allowed = allowed && false;
                state[`${field}_err`] = "Required field";
            }
        });
     
        return {state, allowed}
    }

    submit = (e) => {
        e.preventDefault();
        const { state, allowed } = this.validate();
        return allowed ? this.updatePassword() : this.setState({...this.state, ...state});
    }

    mappingPayload() {
        const requestCode = this.getRequestCode();
        return [{...this.state}].map(p => this.props.isForgot ? ({requestCode, password: p.new_password}) : ({newPassword: p.new_password, oldPassword : p.old_password}))[0];
    }

    async updatePassword() {
        const payload = this.mappingPayload();

        try {
            if(this.props.isForgot) {
                await xhrApi.SET_NEW_PASSWORD(payload);
            } else {
                await xhrApi.CHANGE_PASSWORD(payload);
            }

            this.props.isForgot ? this.props.history.push(config.root_path) : this.props.onClickBack()
            this.props.setTooltipMessage(true, true, this.props.isForgot ? "resetPasswordSuccess" : "updatePasswordSuccess")
        } catch (error) {
            if(error.response && error.response.data && error.response.status === 400) {
                this.setState({...this.state, old_password_err: config.tooltipMessages.wrongOldPassword})
            }
            this.props.setTooltipMessage(true, false, this.props.isForgot ? "resetPasswordFailed" : "updatePasswordFailed")
        }
    }
    
    render(){
        const {show, key, isSuccess} = this.props.tooltipMessage;
        return (
            <form>
                <Header isSettings={!this.props.isForgot}>
                    {!this.props.isForgot && <img src={require(`../../images/payments/backdesktop.svg`)} alt="reset" id="arrow" onClick={() => this.props.onClickBack()}/>}
                    <img src={require(`../../images/signup/${this.props.isForgot ? "resetpass.svg" : "pass.svg"}`)} alt="reset" id="icon"/>
                    <h1>{this.props.isForgot ? "Reset Password" : "Change Password"}</h1>
                </Header>
                {show && <Tooltip message={config.tooltipMessages[key]} isSuccess={isSuccess} fullWidth/>}
                {!this.props.isForgot && 
                    <Input 
                        id="old_password" 
                        type="password" 
                        label={config.resetPassword.oldPassword} 
                        onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} 
                        error={this.state.old_password_err} 
                        isInnerForm 
                        isPassword
                        className="isInnerForm"/>
                }
                <Input 
                    id="new_password" 
                    type="password" 
                    label={config.resetPassword.newPass} 
                    onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} 
                    error={this.state.new_password_err} 
                    isInnerForm={!this.props.isForgot} 
                    isForm={this.props.isForgot} 
                    isPassword
                    className={this.props.isForgot ? "isForm" : "isInnerForm"}
                />
                <Input 
                    id="confirm_password" 
                    type="password" 
                    label={config.resetPassword.confirmPass} 
                    onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} 
                    error={this.state.confirm_password_err} 
                    isInnerForm={!this.props.isForgot} 
                    isForm={this.props.isForgot} 
                    isPassword
                    className={this.props.isForgot ? "isForm" : "isInnerForm"}
                />
                <Button onClick={(e) => this.submit(e)}>{this.props.isForgot ? "Reset Password" : "Change Password"}</Button>
            </form>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        isDesktop: app.isDesktop,
        tooltipMessage: app.tooltipMessage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTooltipMessage: (show, isSuccess, key) => dispatch(actions.setTooltipMessage(show, isSuccess, key))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));;