import React from 'react'
import { useSelector } from 'react-redux';
import './ButtonSettings.scss';

export const sizes = {
    "small": "(120X24)",
    "medium": "(160X32)",
    "large": "(240X48)",
}

const ButtonSettings = ({isChecked, setIsChecked}) => {
    const { windowWidth } = useSelector(({app}) => app);

    return (
        <div className="button-settings-component">
            {["small", "divider", "medium", "divider", "large"].map((item, index) => <PaymentButton 
                key={index} 
                type={item} 
                isChecked={isChecked} 
                setIsChecked={setIsChecked} 
            />)}

            {windowWidth < 1024 && <PaymentButton 
                key={isChecked} 
                type={isChecked} 
                isChecked={isChecked} 
                setIsChecked={setIsChecked} 
                showActive
            />}
        </div>
    )
}

const PaymentButton = ({type = "small", isChecked, setIsChecked, showActive}) => {
    if(type === "divider") return <div className="payment-button-component__divider" />

    return <div className={`payment-button-component ${type} ${showActive ? 'is-active' : ''}`}>
        {!showActive && <div className="payment-button-component__header">
            <input type="radio" name="payment-button" value={type} checked={isChecked === type} onChange={() => setIsChecked(type)} />
            <div className="payment-button-component__header--type">
                <span>{type}</span>
                <span>{sizes[type]}</span>
            </div>
        </div>}

        <img src={require('../../../../images/tools/pay-with-ada.svg')} alt="ada-pay" />
    </div>
}

export default ButtonSettings;