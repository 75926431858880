import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import { withRouter } from 'react-router-dom';
import Menu from '../Menu/Menu';


const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: roboto, sans-serif;
    padding: 5% 0;
    box-sizing: border-box;
    > form{
        border-radius: 2px;
        box-shadow: 0px 2px 4.9px 0.1px rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        width: 600px;
        flex-direction: column;
        > button, .custom-button{
            width: 200px;
            height: 40px;
            border-radius: 4px;
            background-color: #11deb3;
            border: none;
            outline: none;
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            text-transform: capitalize;
            margin: 0 auto;
            cursor: pointer;
        }
        > p{
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #131313;
            > a{
                color: #10bfa0;
            }
        }
        > p#description{
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: left;
            color: #131313;
            margin: 0 0 32px;
            padding-left: 17px;
            @media(max-width: 768px){
                padding-left: 0;
            }
        }
    }
    @media(max-width: 768px){
        padding: 88px 0 0;
        > form{
            width: 100%;
            padding: 24px;
            border-radius: unset;
            box-shadow: unset;
            background-color: unset;
            margin-bottom: auto;
        }
    }
`

const ViewContainer = (props) => {
    const view = props.history.location.pathname.split("/")[2];
    props.setView(view || "login");
    
    return (
        <Container>
          <Menu isOutsideApp />
          {props.component()}
        </Container>
    );
}

const mapStateToProps = ({app}) => {
    return {
        isDesktop: app.isDesktop,
        view: app.view,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setView: (view) => dispatch(actions.setView(view)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewContainer));