import React from 'react';
import Input from '../../Common/Input';
import styled from 'styled-components';
import { toDisplayAmount } from '../../../shared/utility';

const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    margin: 1.5625vw 0 1vw 0;
    
    @media(max-width: 768px){
        flex-direction: column;
    }
`

const InputAmount = ({amount, setAmount, availableBalance, fees, error, addressIsValid}) => {
    return (
        <Wrapper>
            <Input 
                value={amount} 
                onChangeOutside={({target: { value }}) => setAmount(value)} 
                isNumber 
                id="amount" 
                label="Amount" 
                type="text" 
                isInnerForm 
                isAmount 
                className="isInnerForm isAmount"
                subLabel={`Available balance: ${toDisplayAmount(availableBalance, 6)} ADA`}
                currency="ADA"
                width="100%"
                availableBalance={toDisplayAmount(availableBalance, 6)}
                fees={fees}
                error={error}
                addressIsValid={addressIsValid}
            />
        </Wrapper>
    )
}

export default InputAmount;