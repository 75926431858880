import { takeEvery, all, takeLatest, throttle } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import { 
	onLogin,
	logout,
	onSignup,
} from "./auth";

import {
	setWindowWidth,
} from "./app";

import {
    sailsWebSocketConnections,
    accountSubscriber,
    getMerchantInitialInfo,
    updateMerchantInfo
} from './integrations';

export function* watchApp() {
	yield all([
		takeEvery(actionTypes.CONNECT, sailsWebSocketConnections),
        takeEvery(actionTypes.ACCOUNT_SUBSCRIBER, accountSubscriber),
		takeLatest(actionTypes.LOGOUT, logout),
		takeEvery(actionTypes.GET_MERCHANT_INFO, getMerchantInitialInfo),
		takeEvery(actionTypes.ON_LOGIN, onLogin),
		takeEvery(actionTypes.ON_SIGNUP, onSignup),
		takeEvery(actionTypes.UPDATE_MERCHANT_INFO, updateMerchantInfo),
		throttle(500, actionTypes.SET_WINDOW_WIDTH, setWindowWidth),
	]);
}