import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from "redux-saga";
import { watchApp } from "./store/sagas";
import appReducer from "./store/reducers/app";
import SocketConnection from './HOC/socket-connection';
import './index.scss';

const composeEnhancers = process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? 
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const history = createBrowserHistory();

history.listen((location, action) => {
  window.scrollTo(0, 0);
});

const rootReducer = combineReducers({
  app: appReducer,
  router: connectRouter(history)
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(connectRouter(history)(rootReducer), composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)))

const app = (
    <Provider store={store} >
      <ConnectedRouter history={history}> 
        <SocketConnection component={() => <App/> }/>
      </ConnectedRouter> 
    </Provider>
  );

sagaMiddleware.run(watchApp);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
