import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Setting, { SETTING_TYPES } from '../../../Common/Setting/Setting';
import { config } from '../../../../config';
import { useApiValue } from '../../../../hooks';
import { updateMerchantInfo } from '../../../../store/actions/integrations';
import './MerchantProfile.scss';

const apiUpdatedKeys = {
    [config.settings.paymentRequestApprovalPercentage]: "paymentRequestApprovalPercentage",
    [config.settings.expireTime]: "paymentRequestExpirationTime",
    [config.settings.receipt]: "receiptsEmail",
}

const MerchantProfile = () => {
    const dispatch = useDispatch();
    const { paymentReceipts } = useSelector(({app}) => app);
    const [_receiptValue, _setReceiptValue] = useApiValue(paymentReceipts, config.tooltipsErrorsIds.settings);

    const { account: { companyName } = {} } = useSelector(({app}) => app);
    const [selectedFile, setSelectedFile] = useState();
    const [_companyName, _setCompanyName] = useState();
    
    useEffect(() => {
        if(!companyName) return;
        _setCompanyName(companyName);
    }, [companyName]);

    const onSubmit = (key, value) => {
        dispatch(updateMerchantInfo({
            [apiUpdatedKeys[key] || key]: value
        }));
    }

    return (
        <div className="merchant-profile-component">
            <Setting
                type={SETTING_TYPES.EDIT}
                title="Display name"
                description="The merchant display name is going to be displayed on the payment page"
                value={_companyName} />

            <Setting
                id={config.settings.receipt}
                type={SETTING_TYPES.EDIT}
                title="Notification emails"
                description="The email address the notification emails will be sent to"
                value={_receiptValue}
                setValue={_setReceiptValue}
                validations={["required"]}
                onClick={onSubmit}
                disabled={_receiptValue === paymentReceipts}
                initialValue={paymentReceipts} />

            <Setting
                title="Logo"
                description={["The merchant logo is going to be displayed on the payment page:", <span>&nbsp;</span>, "Max size 5M. File types: JPG, JPEG, PNG"]}
                value={selectedFile}
                setValue={setSelectedFile}
                type={SETTING_TYPES.FILE} />
        </div>
    )
}

export default MerchantProfile;