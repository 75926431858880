import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import {config} from '../config';
import { unAuthPages } from '../xhr-api';

class SocketConnection extends Component {
    state = {
        unAuthPages: Object.keys(unAuthPages)
    }   

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.isAuth && this.props.isAuth) {
            this.props.connect();
        }

        if(prevProps.location.pathname === '/' && this.props.location.pathname !== '/' && !this.props.isAuth)  {
            this.init();
        }
        else if(prevProps.location.pathname === '/' && this.props.location.pathname !== '/' && this.props.isAuth)  {
            this.props.history.push(`${config.root_path}/overview`);
        }
        else if(prevProps.location.pathname !== '/' && this.props.location.pathname === '/' ) {
            this.props.setLoader(false);
        } 

        const accountStatus = this.props.accountStatus
        const unAuthPages = this.state.unAuthPages
        const isSession = !prevProps.isAuth && this.props.isAuth;

        if(!isSession && unAuthPages.includes(this.props.location.pathname)) return;

        if( (isSession && unAuthPages.includes(this.props.location.pathname) && this.props.location.pathname !== '/') || 
            (prevProps.accountStatus !== accountStatus && unAuthPages.includes(this.props.location.pathname) && this.props.location.pathname !== '/') ) {
            this.props.history.push(`${config.root_path}/overview`);
        }else if(!this.props.isAuth && !this.props.isSocketConnected && !unAuthPages.includes(this.props.location.pathname)) {  
            this.props.history.push(config.root_path);  
        }
    }

    init() {
        if(this.state.unAuthPages.includes(this.props.location.pathname)) {
            this.props.setLoader(false);
        } 
        this.props.getMerchantInfo();
    }

    // TODO:: add async component .. 
    render() {
        return this.props.isAuth || this.state.unAuthPages.includes(this.props.location.pathname) ? this.props.component() : null;
    }
}

const mapStateToProps = ({app}) => ({ 
    isAuth: app.account.isAuth,
    accountStatus: app.account.status,
    isSocketConnected: app.isSocketConnected 
});

const mapDispatchToProps = dispatch => ({
    connect: () => dispatch(actions.connect()),
    setLoader: isLoading => dispatch(actions.setLoader(isLoading)),
    getMerchantInfo: () => dispatch(actions.getMerchantInfo()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocketConnection));