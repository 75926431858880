import * as actionTypes from "./actionTypes";

export const setView = view => {
    return {
        type: actionTypes.SET_VIEW,
        view
    }
}

export const setWindowWidth = (width) => {
    return{
      type: actionTypes.SET_WINDOW_WIDTH,
      width
    }
}

export const setWidth = width => {
    return {
        type: actionTypes.SET_WIDTH,
        width
    }
}

export const setTooltipMessage = (show, isSuccess, key, id) => {
    return {
        type: actionTypes.SET_TOOLTIP_MESSAGE,
        show,
        isSuccess,
        key,
        id
    }
}

export const setLoader = isLoading => {
    return{
        type: actionTypes.SET_LOADER,
        isLoading
    }
}