import React from 'react';
import styled from 'styled-components';
import {config} from '../../config';
import {Item} from './Item';

const ListWrapper = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: roboto, sans-serif;
    font-size: 0.8333333333333334vw;
    @media(max-width: 1200px) {
        font-size: 1.25vw;
    }
    @media(max-width: 768px) {
        font-size: 18px;
    }
    @media(max-width: 375px){
        font-size: 13px;
    }
`

export const List = ({view, onClickItem, mobile, isOutsideApp}) => {
    return (
        <ListWrapper>
            {!isOutsideApp && Object.keys(config.items).map((item, i) => <Item key={i} item={config.items[item]} view={view} onClickItem={onClickItem}/>)}
            {mobile && <Item onClickItem={onClickItem} logout/>}
        </ListWrapper>
    )
}