import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { config } from '../../../config';
import { toBNAmount, toDisplayAmount, _gte } from '../../../shared/utility';
import { setTooltipMessage } from '../../../store/actions';
import xhrApi from '../../../xhr-api';
import InnerTitle from '../../Common/InnerTitle';
import Input from '../../Common/Input';
import { Tooltip } from '../../Common/Tooltip';
import InputAmount from './InputAmount';
import  { motion } from 'framer-motion';
import { pageVariants } from '../../../Animations';
import Spinner from '../../Common/Spinner';
import { commaFormatted } from './../../../shared/utility';

const Wrapper = styled.div`
    margin-top: 1.0416666666666665vw;
    padding-top: 3.75vw;
    padding-left: ${({ right }) => right ? '3.6458333333333335vw' : "1.7708333333333333vw"};
    padding-right: ${({ right }) => right ? "6.25vw" : "3.6979166666666665vw"};
    padding-bottom: 2.65625vw;
    box-sizing: border-box;
    background-color: #ffffff;
    font-family: roboto, sans-serif;
    > form{
        padding-left: 2.8vw;
        padding-top: 3.4375000000000004vw;
        > ul{
            list-style: none;
            padding-left: 0;
            margin: 0;
            li{
                display: flex;
                justify-content: space-between;
                padding: 1.7187500000000002vw 0.8854166666666666vw;
                box-sizing: border-box;
                border-top: 1px solid #dde0e6;
                font-size: 0.7291666666666666vw;
                color: #131313;
                &:last-child{
                    font-weight: bold;
                }
            }
        }
    }
    @media(max-width: 1200px){
        width: 100%;
        border-right: unset;
        margin-top: 1.5624999999999998vw;
        padding-top: 5.625vw;
        adding-left: ${({ right }) => right ? '5.46875vw' : "2.65625vw"};
        padding-right: ${({ right }) => right ? "0" : "5.546875vw"};
        padding-bottom: 3.984375vw;
        > form{
            padding-top: 5.156250000000001vw;
            > ul > li{
                padding: 2.5781250000000004vw 1.328125vw;
                font-size: 1.09375vw;
            }
        }
    }
    @media(max-width: 768px){
        margin: ${({ right }) => right ? "47px 0 0 0" : "0"};
        padding: 0;
        background-color: unset;
        > form{
            padding-top: 24px;
            padding-left: 0;
            > ul{
                margin-left: -24px;
                margin-right: -24px;
                li{
                    padding: 25px 24px;
                    font-size: 14px;
                }
            }
        }
    }
`

const Top = styled.div`
    display: flex;
    align-items: center;
    > img#back{
        width: 22px;
        cursor: pointer;
        margin-right: 1.3541666666666667vw;
    }
`

const Divider = styled.div`
    border-top: 1px solid #dde0e6;
    margin-top: ${({ marginTop }) => marginTop ? "1.7870439314966493vw" : "-1.6666666666666667vw"};
    max-width: ${({ maxWidth }) => maxWidth ? maxWidth : "100%"};
    margin-bottom: 1.5625vw;
    @media(max-width: 768px){
        margin-top: 0;
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: 24px;
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 24px;
    max-width: 100%;    
    justify-content: center;

    > button:first-child{
        width: 200px;
        height: 40px;
        border-radius: 4px;
        background-color: #e7e8eb;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
        color: #081339;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;

        &:disabled {
            opacity: 0.5;
            cursor: initial;
        }
    }
    > button:last-child{
        width: 200px;
        height: 40px;
        border-radius: 4px;
        background-color: #11deb3;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
            opacity: 0.5;
            cursor: initial;
        }
    }
    @media(max-width: 768px){
        flex-direction: column-reverse;
        > button:last-child{
            width: 100%;
            margin-bottom: 8px;
        }
        > button:first-child{
            margin-right: 0;
            width: 100%;
        }
    }
`

const NewWithdrawal = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { tooltipMessage = {}, balance } = useSelector(({ app }) => app);
    const { show, key, isSuccess } = tooltipMessage;
    const [address, setAddress] = useState("");
    const [addressIsValid, setAddressIsValid] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [amount, setAmount] = useState("");
    const [platformFees, setPlatformFees] = useState(null);
    const [networkFees, setNetworkFees] = useState(null);
    const totalAmount = Number(toBNAmount(amount, 6)) - Number(networkFees || 0) - Number(platformFees || 0);
    const isInsufficientBalance = 
        (totalAmount < 0
        || _gte(String(toBNAmount(amount, 6)), balance.balance)) && "Insufficient balance";
        const isMinimumWithdrawal = (!!amount && amount < 3) && "Minimum withdrawal amount is 3 ADA";

    const isDisabled = isLoading || address === "" || amount === "" || totalAmount === "" || isInsufficientBalance || isMinimumWithdrawal || !addressIsValid;
    
    const onSubmit = async () => {
        try {
            setIsLoading("submit");
            await xhrApi.WITHDRAWAL_REQUEST({
                includeFees: true,
                outputs: [{
                    address, 
                    amount: toBNAmount(amount, 6)
                }]
            });
            
            dispatch(setTooltipMessage(true, true, "withdrawalRequestSuccess"));
            history.push(`/dashboard/withdrawals`);
        } catch(error) {
            console.log(error);
            dispatch(setTooltipMessage(true, false, "withdrawalRequestFailed"));
        } finally {
            setIsLoading(false)
        }
    }

    const getFees = async (_amount, _address) => {
        try {
            if(!_address || _address === "") {
                setAddressIsValid(false);
            }

            if(!_amount || _amount === "" || Number(_amount) < 3) {
                setNetworkFees(null);
                setPlatformFees(null);
            };

            if(!_amount || _amount === "" || Number(_amount) < 3 || !_address || _address === "") return;

            const { data: { estimatedNetworkFee, estimatedPlatformFees}} = await xhrApi.ESTIMATE_WITHDRAWAL_FEES({
                outputs: [{
                    address: _address,
                    amount: Number(toBNAmount(_amount, 6))
                }]
            });
            setAddressIsValid(true);
            setNetworkFees(String(estimatedNetworkFee));
            setPlatformFees(String(parseInt(estimatedPlatformFees)));
        } catch(error) {
            console.log(error.response);
            if(JSON.stringify(error?.response?.data?.message ?? "").includes("a valid testnet address")) {
                dispatch(setTooltipMessage(true, false, "invalidAddress"));
            } else {
                dispatch(setTooltipMessage(true, false, "failedToEstimateFees"));
            }

            setNetworkFees(null);
            setPlatformFees(null);
        } finally {
            setIsLoading(false)
        }
    }
    const debounceLoadFeesData = useCallback(debounce(getFees, 1000), []);

    const onClickBack = () => {
        history.push(`${config.root_path}${config.items.withdrawals.path}`)
    }

    useEffect(() => {
        return () => {
            if (tooltipMessage.show && tooltipMessage.key !== "withdrawalRequestSuccess") {
                dispatch(setTooltipMessage(false, true, ""))
            } else setTimeout(() => dispatch(setTooltipMessage(false, true, "")), 7000);
        }
    }, [dispatch, tooltipMessage.key, tooltipMessage.show]);

    useEffect(() => {
        dispatch(setTooltipMessage(false, false, ""));
        if(address && amount) {
            setIsLoading("fees");
        }
        debounceLoadFeesData(amount, address);
    }, [address, amount, debounceLoadFeesData, dispatch]);
    
    return (
        <motion.div initial="hidden" animate="visible"  exit="hidden" variants={pageVariants}>
            <Wrapper>
                <Top>
                    <img src={require('../../../images/payments/backdesktop.svg')} onClick={onClickBack} alt="back" id="back" />
                    <InnerTitle title="New Withdrawal" path="new-withdrawal.svg" isSubtitle />
                </Top>

                <form>
                    {show && <Tooltip isSuccess={isSuccess} fullWidth message={config.tooltipMessages[key]}/>}
                    <Divider/>

                    <Input 
                        id="address" 
                        value={address} 
                        label="Recipient's ADA Address" 
                        subLabel="Please ensure this address is a valid ADA address"
                        type="text" 
                        onChangeOutside={({target: { value }}) => setAddress(value)} 
                        convertor="wallet"
                        isInnerForm
                        className="isInnerForm convertor"
                    />
                    <InputAmount 
                        amount={amount} 
                        setAmount={setAmount}
                        availableBalance={balance.balance}
                        fees={platformFees && networkFees && toDisplayAmount(Number(networkFees) + Number(platformFees), 6)}
                        addressIsValid={addressIsValid}
                        error={amount !== "" && isInsufficientBalance ? isInsufficientBalance : isMinimumWithdrawal}
                    />

                    <ul>
                        <li>
                            <span>Network fee (estimated)</span>
                            {isLoading === "fees" ? <Spinner type="default" /> : <> 
                                <span>{!isMinimumWithdrawal && !isInsufficientBalance && addressIsValid && networkFees !== null && `${toDisplayAmount(networkFees, 6)} ADA`}</span>
                            </>}
                        </li>

                        <li>
                            <span>Platform fee</span>
                            {isLoading === "fees" ? <Spinner type="default" /> : <> 
                                <span>{!isMinimumWithdrawal && !isInsufficientBalance && addressIsValid && platformFees !== null && `${toDisplayAmount(platformFees, 6)} ADA`}</span>
                            </>}
                        </li>

                        <li>
                            <span>You will receive (estimated)</span>
                            {isLoading === "fees" ? <Spinner type="default" /> : <> 
                                <span>
                                    {(!isMinimumWithdrawal && !isInsufficientBalance && addressIsValid && amount !== "" && networkFees !== null && platformFees !== null) 
                                    && `${commaFormatted(toDisplayAmount(totalAmount, 6))} ADA`}
                                </span>
                            </>}
                        </li>
                    </ul>

                    <ButtonsWrapper>
                        <button type="button" onClick={onClickBack}>Cancel</button>
                        <button type="button" onClick={onSubmit} disabled={isDisabled}>
                            {isLoading === "submit" && <Spinner type="default" className="submit" />}
                            <span>Submit</span>
                        </button>
                    </ButtonsWrapper>
                </form>
            </Wrapper>
        </motion.div>
    )
}

export default NewWithdrawal;