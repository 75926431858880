import React from 'react'
import { config } from '../../../config.js';
import TransactionRow from '../TransactionRow.js';
import moment from 'moment';
import './PaymentHistory.scss';
import { useSelector } from 'react-redux';

const PaymentHistory = () => {
    const { transactions } = useSelector(({app}) => app);
    return (
        <div className={`payment-history-component`}>
            {transactions.length > 0 ? <> 
                <h2 className="payment-history-component--title">Transactions history</h2>
                <div className="payment-history-component--transactions">
                    {transactions
                    .sort(({createTime: currentDate}, {createTime: nextDate}) => moment.utc(nextDate).valueOf() - moment.utc(currentDate).valueOf())
                    .map(({status, amount, lastMonitoredDepth, createTime, hash}) => 
                        <TransactionRow 
                            key={hash} 
                            address={hash}
                            status={status} 
                            amount={amount}
                            date={createTime}
                            confirmation={[
                                lastMonitoredDepth > config.transaction.maxMonitoredDepth ? config.transaction.maxMonitoredDepth : lastMonitoredDepth, 
                                config.transaction.maxMonitoredDepth
                            ]}
                        />)}
                </div>
            </> : <h3>No transactions detected.</h3>}
        </div>
    )
}

export default PaymentHistory;