/* CG-API */
import axios from 'axios';
import { config } from './config';
axios.defaults.withCredentials = true;

export const unAuthPages = {
  '/': '/',
  [config.register]: config.register,
  [config.root_path]: config.root_path,
  [config.appViews.resetPassword.path]: config.appViews.resetPassword.path,
  [config.appViews.status.path]: config.appViews.status.path,
  [config.appViews.verify_email.path]: config.appViews.verify_email.path
}

const host = process.env.REACT_APP_API_HOST,
      headers = {
        'Content-Type': 'application/json',
        withCredentials: true
      };

const instance = axios.create({
  baseURL: host,
  headers,
});

// Add a response interceptor
instance.interceptors.response.use(response => response, error => {
  if(!unAuthPages[window.location.pathname]) {
    if(error.response.status === 403) {
      window.location.href = '/dashboard';
    }
  }
  return error;
});

export default {
  LOGIN: payload => instance.post('/login', payload), // {username: "", password: ""}
  SIGN_UP: payload => instance.put('/signup', payload), 
  LOGOUT: () => instance.post('/logout'),
  ACCOUNT_INFO: () => instance.post('/account'),
  UPDATE_ACCOUNT_INFO: (payload) => instance.patch('/account/update-account-settings', payload),
  ACCOUNT_BALANCE: () => instance.post('/account/get-account-balance'),
  GET_ACCOUNT_API_KEYS: () => instance.post('/account/get-api-keys-for-account'),
  GET_ACCOUNT_SETTINGS: () => instance.post('/account/get-account-settings'),
  UPDATE_ACCOUNT_SETTINGS: () => instance.patch('/account/get-account-settings'),
  CHANGE_PASSWORD: (payload) => instance.post('/account/change-password', payload),
  REQUEST_RESET_PASSWORD: payload => instance.post('/request-reset-password', payload), // step 1 - {email: ""} - reset password request 
  CHECK_RESET_PASSWORD: payload => instance.post('/check-reset-password', payload), // step 2 - {"requestCode": "BEBt44lAA9q4Z53z95bB71ty51xd70SI"} 
  SET_NEW_PASSWORD: payload => instance.post('/reset-password', payload), // step 3 - {	"requestCode": "BEBt44lAA9q4Z53z95bB71ty51xd70SI", "password": "123456"}
  GET_PAYMENT_REQUESTS: (payload) => instance.post('/payment-request', payload), // { "from": "2021-05-02T13:35:43.000Z", "to": "2021-05-03T07:52:04.000Z", "uuid": "", "pageNumber": 1, "pageSize": 10 }
  GET_PAYMENT_REQUEST: (payload) => instance.post(`/payment-request/get-by-uuid`, payload), // { uuid: "" }
  RESEND_EMAIL_VERIFICATION: payload => instance.post('/resend-email-verification', payload),
  EMAIL_VERIFICATION: payload => instance.post('/email-verification', payload),
  ESTIMATE_WITHDRAWAL_FEES: payload => instance.post('/withdrawal/estimate-fee', payload),
  GET_WITHDRAWAL_LIST: (payload) => instance.post('/withdrawal', payload),
  WITHDRAWAL_REQUEST: payload => instance.put('/withdrawal', payload),
  UPLOAD_LOGO: (payload, config) => instance.post('/account/upload-logo', payload, config), 
};