import React from 'react'
import Welcome from '../../Welcome';
import './Header.scss';

const Header = () => {
    return (
        <div className="home-component">
            <div className="ada-pay-container">
                <div className="ada-pay-background"></div>
            </div>
            <div className="home-component__container">
                <Welcome />
                <div className="image">
                    <div className="circle-container">
                        <img src={require('../../../images/adapay-gif.gif')} alt="ada pay" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;