import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import Input from '../Common/Input';
import Recaptcha from 'react-recaptcha';
import {config} from '../../config';
import {Tooltip} from '../Common/Tooltip';
import xhrApi from '../../xhr-api';
import { debounce } from 'lodash';
 
const Header = styled.header`
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    margin-bottom: 34px;
    img{
        width: 80px;
        margin-right: 24px;
    }
    h1{
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #081339;
    }
    @media(max-width: 768px){
        padding: 0 24px 24px;
        margin-bottom: 24px;
        margin-left: -24px;
        margin-right: -24px;
        img{
            width: 40px;
            margin-right: 15px;
        }
        h1{
            font-size: 18px;
        }
    }
`

const CaptchaHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 304px;
    border: ${({error}) => error && "1px solid salmon"};
    margin: 0 auto 24px;
`

const Forgot = styled.p`
    margin: 22px 0;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    &#backTo{
        color: #10bfa0;
    }
    text-decoration: underline;
    cursor: pointer;
    @media(max-width: 768px){
        padding-left: 0;
    }
`

const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

class ForgotPassword extends Component{

    state = {
        email: "",
        email_err: false,
        captchaVerified: process.env.NODE_ENV === 'development',
        captcha_err: false,
    }

    verifyCallback(){
        this.setState({...this.state, captchaVerified: true, captcha_err: false})
    }

    callback(){
    }

    shouldComponentUpdate(nextProps, nextState){
        return nextState.email_err !== this.state.email_err
                || nextState.email !== this.state.email
                || nextState.captcha_err !== this.state.captcha_err
                || nextProps.isDesktop !== this.props.isDesktop
                || this.props.tooltipMessage.show !== nextProps.tooltipMessage.show
    }

    componentDidUpdate(prevProps){
        if(this.props.tooltipMessage.show && !prevProps.tooltipMessage.show){
            setTimeout(() => this.props.setTooltipMessage(false, true, ""), 10000);
        }
    }

    componentWillUnmount(){
        if(this.props.tooltipMessage.show){
            this.props.setTooltipMessage(false, true, "")
        }
    }

    onChangeHandler(value, id){
        this.setState({...this.state, [id]: value, [`${id}_err`]: false})
    }

    validate(){
        let isValid = true;
        const newState = {...this.state};
        if(this.state.email === ""){
            isValid = isValid && false;
            newState[`email_err`] = "Required field";
        }
        if(this.state.email !== "" && !pattern.test(this.state.email)){
            isValid = false;
            newState['email_err'] = "Invalid email";
        }
        if(!this.state.captchaVerified){
            isValid = false;
            newState['captcha_err'] = true
        }
        this.setState(newState)
        return isValid;
    }

    async requestResetPassword() {
        try {
            await xhrApi.REQUEST_RESET_PASSWORD({email: this.state.email});
            this.props.setTooltipMessage(true, true, "requestResetPasswordSuccess");
        } catch (error) {
            if(error && error.status === 400){
                return this.setState({...this.state, email_err: "Email not found"})
            }
            this.props.setTooltipMessage(true, false, "requestResetPasswordFailed")
        }
    }
    
    debouncedRequestResetPassword = debounce(function() {
        this.requestResetPassword();
    }, 1000);

    onSubmit(e){
        e.preventDefault();
        if(this.validate()){
            this.debouncedRequestResetPassword();
        }
    }



    render(){
        const {show, key, isSuccess} = this.props.tooltipMessage;
        return(
            <form onSubmit={(e) => this.onSubmit(e)}>
                <Header>
                    <img src={require('../../images/signup/pass.svg')} alt="forgot password"/>
                    <h1>Forgot Password</h1>
                </Header>
                {show && <Tooltip isSuccess={isSuccess} message={config.tooltipMessages[key]}/>}
                <Input 
                    type="text" 
                    label="Email address" 
                    id="email" 
                    error={this.state.email_err} 
                    value={this.state.email}
                    onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} 
                    isForm 
                    className="isForm"
                />

                <CaptchaHolder error={this.state.captcha_err}>
                    <Recaptcha
                        sitekey="6LdeZbYUAAAAAAfiA6Nz6VRv1kAm9FvTwqAks5Wf"
                        render="explicit"
                        verifyCallback={() => this.verifyCallback()}
                        onloadCallback={() => this.callback()}
                        badge
                    />
                </CaptchaHolder>
                <button type="submit" onClick={(e) => this.onSubmit(e)}>Reset Password</button>
                <Forgot id="backTo" onClick={() => this.props.toggleView()}>Back to login</Forgot>
            </form>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        isDesktop: app.isDesktop,
        tooltipMessage: app.tooltipMessage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: payload => dispatch(actions.onLogin(payload)),
        setTooltipMessage: (show, isSuccess, key) => dispatch(actions.setTooltipMessage(show, isSuccess, key))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));