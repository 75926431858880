import React from 'react';
import { CopyBlock, github } from 'react-code-blocks';
import Setting, { SETTING_TYPES } from '../../../Common/Setting/Setting';
import { sizes } from '../ButtonSettings/ButtonSettings';
import './CodeSettings.scss';

const CodeSettings = ({type}) => {
    const sizeStringToObject = () => {
        if(!sizes[type]) return  {width: 120, height: 24};
        const removeBrackets = sizes[type].replaceAll("(", "").replaceAll(")", "");
        const sizesSplitted = removeBrackets.split("X");

        return {
            width: sizesSplitted[0],
            height: sizesSplitted[1]
        }
    }
    const buttonSize = sizeStringToObject();
    
    return (
        <div className="code-settings-component">
            <Setting
                title="Generated button code"
                description="Please copy the code below and paste it into your website"
                value={<CopyBlock
                    language="html"
                    text={`
                        <a href="#" target="_blank" rel="noreferrer noopener">
                            <img 
                                width="${buttonSize.width}" 
                                height="${buttonSize.height}" 
                                src="${window.location.origin}/images/pay-with-ada.svg" alt="adapay" 
                            />
                        </a>`}
                    codeBlock
                    theme={github}
                    showLineNumbers={false}
                />}
                type={SETTING_TYPES.CODE}
            />
        </div>
    )
}

export default CodeSettings;