import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import {config} from '../../config';
import _ from 'lodash';

const initialAccount = {
    id: null,
    status: null,
    uuid: null,
    firstName: null,
    lastName: null,
    email: null,
    isEmailVerified: false,
    isApproved: false,
    logoUrl: null,
    companyName: null,
    websiteUrl: null,
    country: null,
    city: null,
    addressLine1: null,
    addressLine2: null,
    zip: null,
    addressManagerUuid: null,
    createTime: null,
    updateTime: null,
    isAuth: false,
}



const initialState = {
    loader: true,
    view: config.items.overview.label,
    windowWidth: window.innerWidth,
    isDesktop: window.innerWidth > 768,
    account: { ...initialAccount },
    payments: [],
    transactions: [],
    authErrorMessage: false,
    tooltipMessage: {
        show: false,
        isSuccess: false,
        key: "",
        id: ""
    },
    withdrawals: [],
    paymentMethods: [],
    balance: 0,
    apiKeys: [],
    successUrl: "",
    webhookUrl: "",
    paymentRequestApprovalPercentage: "",
    paymentExpireTime: "",
    paymentReceipts: "",
};

const onAuth = (state, {account}) => {
    if(account) {
        return updateObject(state, { 
                loader: false,
                account: {
                    isAuth: true,
                    ...account, 
                }
            });
    }
    return updateObject(state, {loader: false, account: { ...initialAccount }});
}

const setView = (state, {view}) => {
    return updateObject(state, {view})
}

const setWindowWidth = (state, {width}) => {
    return updateObject(state, {windowWidth: width, isDesktop: width > 768})
}

const onSubscribe = (state, {data, key}) => {    
    if(key === "transactions") {
        const urlParams = new URLSearchParams(window.location.search);
        const uuid = urlParams.get("id");

        if(data instanceof Array) { // check if is array to set the state. 
            return updateObject(state, {transactions: data.filter(transaction => transaction.paymentRequestUuid === uuid)});
        }

        if(data.paymentRequestUuid !== uuid) return state; 

        const findIndex = state.transactions.findIndex(transaction => transaction.hash === data.hash); // find exiting transaction to update or set
        if(findIndex >= 0) { // if found, do update
            let updatedTransactions = [...state.transactions];
            updatedTransactions[findIndex] = data;
            return updateObject(state, {transactions: updatedTransactions.filter(transaction => transaction.paymentRequestUuid === uuid)})
        }

        return updateObject(state, {transactions: [...state.transactions, data].filter(transaction => transaction.paymentRequestUuid === uuid)}); // set new transaction object
    }

    if(key === "payments") {
        if(!(data instanceof Array) && state.payments.length >= 0) {
            const findIndex = state.payments.findIndex(payment => payment.uuid === data.uuid);
            if(findIndex >= 0) {
                const currentPaymentRequest = {...data};
                data = [...state.payments];
                data[findIndex] = currentPaymentRequest;
            } else {
                data = [...state.payments, data];
            }
        }
    }

    if(key === "withdrawalsTransactions") {
        if(data.status === "canceled") return state;
        const withdrawalUuid = data.withdrawalRequestUuid;
        const currentWithdrawalIndex = state.withdrawals.findIndex(({uuid}) => uuid === withdrawalUuid);
        if(currentWithdrawalIndex === -1) return state;

        const transactionHash = data.hash;
        const transactionFee = data.fee;

        data = [...state.withdrawals];
        data[currentWithdrawalIndex] = {
            ...data[currentWithdrawalIndex],
            transactionFee,
            transactionHash,
        }
    }

    if(key === "withdrawals") {
        if(!(data instanceof Array) && state.withdrawals.length >= 0) {
            const findIndex = state.withdrawals.findIndex(withdrawal => withdrawal.uuid === data.uuid);
            if(findIndex >= 0) {
                const currentPaymentRequest = {...data};
                data = [...state.withdrawals];
                data[findIndex] = {
                    ...data[findIndex],
                    ...currentPaymentRequest
                };
            } else {
                data = [...state.withdrawals, data];
            }
        }
    }


    if(key === "balance"){
        return updateObject(state, {[key]: {...state.balance, ...data}})
    }
    else if(key === "payments" || key === "withdrawals" || key === "withdrawalsTransactions"){
        data = _.orderBy(data, "createTime", "desc");
    }
  
    if(key === "account") {
        return updateObject(state, {[key]: {...state.account, ...data}});
    }

    const _key = key === "withdrawalsTransactions" ? "withdrawals" : key;
    return updateObject(state, {[_key]: data});
}

const setTooltipMessage = (state, {show, isSuccess, key, id}) => {
    if(show) {
        window.scrollTo(0, 0);
    }
    return updateObject(state, {tooltipMessage: {show, isSuccess, key, id}})
}

const setLoader = (state, {isLoading}) => {
    return updateObject(state, {loader: isLoading})
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ON_AUTH: 
            return onAuth(state, action);
        case actionTypes.SET_VIEW: 
            return setView(state, action);
        case actionTypes.SET_WIDTH: 
            return setWindowWidth(state, action);
        case actionTypes.ON_SUBSCRIBE:
            return onSubscribe(state, action);
        case actionTypes.SET_TOOLTIP_MESSAGE:
            return setTooltipMessage(state, action)
        case actionTypes.SET_LOADER:
            return setLoader(state, action)
        default:
            return state;
    }
};

export default reducer;