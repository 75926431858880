import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import {config} from '../../config';

const Li = styled.li`
    height: 4.166666666666666vw;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #012465;
    cursor: pointer;
    &:last-child{
        border-bottom: 1px solid #012465;
    }
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    > a{
        color: inherit;
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: ${({selected}) => selected ? '#11deb3' : 'unset'}; 
        
        &.disabled{
            color: rgba(256, 256, 256, 0.5);
            cursor: default;
            cursor: selected;

            & img {
                opacity: 0.5;
            }
        }

        & .soon {
            color: #11deb3;
            font-size: 10px;
            margin-left: 24px;
            text-align: center;
        }
    }
    img{
        width: 1.25vw;
        margin-right: 0.8854166666666666vw;
        margin-left: 2.5vw;
    }
    background-color: ${({selected}) => selected && '#071133'};
    @media(max-width: 1200px) {
        height: 6.25vw;
        img {
            width: 1.875vw;
            margin-right: 1.328125vw;
            margin-right: 3.75vw;
        }
    }
    @media(max-width: 768px) {
        height: 80px;
        img { 
            width: 32px;
            margin-right: 20px;
            margin-left: 48px;
        }
    }
    @media(max-width: 375px){
        height: 50px;
        img { 
            width: 20px;
            margin-left: 38px;
        }
    }
`

export const Item = ({item, view, onClickItem, logout}) => (
    logout
    ? <Li onClick={()=>onClickItem(true)}><img src={require('../../images/menu/logoutmobile.svg')} alt="logout"/>Logout</Li>
    :
    <Li selected={item.label === view}>
        <Link className={`${item.coming_soon && 'disabled'}`} to={!item.coming_soon ? {pathname: `${config.root_path}${item.path}`} : {}} onClick={() => !item.coming_soon && onClickItem && onClickItem()}>
            <img src={item.label === view ? require(`../../images/menu${item.path}-color.svg`) : require(`../../images/menu${item.path}.svg`)} alt={item.label}/>
            <span>{item.label}</span>
            {item.coming_soon && <span className="soon">Coming soon</span>}
        </Link>
    </Li>
)