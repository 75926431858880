import { all, put, fork, call, take, cancelled } from "redux-saga/effects";
import * as actions from '../actions';
import WSApi from '../../ws-api';
import xhrApi from "../../xhr-api";
import { END, eventChannel } from "@redux-saga/core";
import WS_API from "../../ws-api";
import { config } from '../../config';

window.events = {};

export function* createChannel(event) {
    const channel = yield call(event);
    try {
        while (true) {
            const action = yield take(channel);
            yield put(action);
        }
    }finally {
        if (yield cancelled()) {
            channel.close();
        }
    }    
}

export function* sailsWebSocketConnections(){
    const event = () => eventChannel(emit => {
        const handler = socket => {
            console.log("connect!");
            emit(actions.accountSubscriber());
        }

        WSApi.CONNECT(handler);
        return () => console.log('socket ended');
    });

    yield fork(createChannel, event);
}

export function* accountSubscriber() {
    const event = () => eventChannel(emit => {
        const handler = async (data) => {
            if(data.paymentRequestUpdate) {
                emit(actions.onSubscribe(data.paymentRequestUpdate, "payments"));
            }

            if(data.paymentRequestTransactionsUpdate) {
                emit(actions.onSubscribe(data.paymentRequestTransactionsUpdate, "transactions"));
            }

            if(data.accountBalanceUpdate) {
                emit(actions.onSubscribe(data.accountBalanceUpdate, "balance"));
            }

            if(data.withdrawalRequestUpdate) {
                emit(actions.onSubscribe(data.withdrawalRequestUpdate, "withdrawals"));
            }

            if(data.withdrawalRequestTransactionsUpdate) {
                emit(actions.onSubscribe(data.withdrawalRequestTransactionsUpdate, "withdrawalsTransactions"));
            }
        }
        const errorHandler = error => emit(END);
        WS_API.subscribe(handler, errorHandler);
        WS_API.ON('paymentRequestUpdate', handler);
        WS_API.ON('paymentRequestTransactionsUpdate', handler);
        WS_API.ON('accountBalanceUpdate', handler);
        WS_API.ON('withdrawalRequestUpdate', handler);
        WS_API.ON('withdrawalRequestTransactionsUpdate', handler);

        return () => {
          return console.log(`Subscribe to payment request ended`);
        }
    });
    yield fork(createChannel, event);
}

export function* getAccountBalance() {
    try {
        const accountBalance = yield call(xhrApi.ACCOUNT_BALANCE);
        const { data } = accountBalance;
        if(!data) return;
        yield put(actions.onSubscribe(data, "balance"))
    } catch(error) {
        console.log(error);
    }
}

export function* getPaymentRequests() {
    try {
        const paymentRequests = yield call(xhrApi.GET_PAYMENT_REQUESTS, {
            pageSize: 10000
        });
        const { data: { data } } = paymentRequests;
        if(!data) return;
        yield put(actions.onSubscribe(data, "payments"))
    } catch(error) {
        console.log(error);
    }
}


export function* getWithdrawalsList() {
    try {
        const withdrawalRequests = yield call(xhrApi.GET_WITHDRAWAL_LIST, {
            pageSize: 10000
        });
        const { data: { data } } = withdrawalRequests;
        if(!data) return;
        yield put(actions.onSubscribe(data, "withdrawals"))
    } catch(error) {
        console.log(error);
    }
}

export function* getApiKeys() {
    try {
        const apiKeys = yield call(xhrApi.GET_ACCOUNT_API_KEYS);
        const { data } = apiKeys;
        if(!data) return;
        yield put(actions.onSubscribe(data, "apiKeys"))
    } catch(error) {
        console.log(error);
    }
}

export function* getMerchantAccountSettings() {
    try {
        const accountSettings = yield call(xhrApi.GET_ACCOUNT_SETTINGS);
        const { data } = accountSettings;
        if(!data) return;
        const { callbackUrl, returnUrl, paymentRequestExpirationTime, paymentRequestApprovalPercentage, receiptsEmail  } = data;
        if(callbackUrl) yield put(actions.onSubscribe(callbackUrl, "webhookUrl"));
        if(returnUrl) yield put(actions.onSubscribe(returnUrl, "successUrl"));
        if(paymentRequestExpirationTime) yield put(actions.onSubscribe(paymentRequestExpirationTime, "paymentExpireTime"));
        if(paymentRequestApprovalPercentage) yield put(actions.onSubscribe(paymentRequestApprovalPercentage, "paymentRequestApprovalPercentage"));
        if(receiptsEmail) yield put(actions.onSubscribe(receiptsEmail, "paymentReceipts"));
    } catch(error) {
        console.log(error);
    }
}

export function* getMerchantInitialInfo() {
    try {
        const accountInfo = yield call(getMerchantInfo);
        if(!accountInfo) return;
        yield all([
            fork(getAccountBalance), 
            fork(getPaymentRequests),
            fork(getWithdrawalsList),
            fork(getApiKeys),
            fork(getMerchantAccountSettings)
        ]);
    } catch(error) {
        console.log(error);
    }
}

export function* getMerchantInfo() {
    try {
        const accountInfo = yield call(xhrApi.ACCOUNT_INFO);
        const { data } = accountInfo;
        yield put(actions.onAuth(data));
        return data;
    } catch(error) {
        console.log(error);
        yield put(actions.onAuth({
            status: "Unauthorized",
            isAuth: false
        }));
    }
}

export function* updateMerchantInfo({payload}) {
    try {

        const { data } = yield call(xhrApi.UPDATE_ACCOUNT_INFO, payload);
        if(!data) return; 
        const { callbackUrl, returnUrl, paymentRequestExpirationTime, paymentRequestApprovalPercentage, receiptsEmail  } = data;
        if(callbackUrl) yield put(actions.onSubscribe(callbackUrl, "webhookUrl"));
        if(returnUrl) yield put(actions.onSubscribe(returnUrl, "successUrl"));
        if(paymentRequestExpirationTime) yield put(actions.onSubscribe(paymentRequestExpirationTime, "paymentExpireTime"));
        if(paymentRequestApprovalPercentage) yield put(actions.onSubscribe(paymentRequestApprovalPercentage, "paymentRequestApprovalPercentage"));
        if(receiptsEmail) yield put(actions.onSubscribe(receiptsEmail, "paymentReceipts"));

        yield put(actions.setTooltipMessage(true, true, "updatedSettings"));
    } catch(error) {
        console.log(error.response.data);
        if(error.response && error.response.data && error.response.data.message) {
            return yield put(actions.setTooltipMessage(true, false, error.response.data.message, config.tooltipsErrorsIds.settings));
        }
        yield put(actions.setTooltipMessage(true, false, "generalfailMsg", config.tooltipsErrorsIds.settings));
    }
}
