import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Overview from '../Views/Overview/Overview';
import Payments from '../Views/Payments/Payments';
import Withdrawals from './Withdrawals/Withdrawals';
import Settings from './Settings/Settings';
import {config} from '../../config';
import Invoice from './Payments/Invoice';
import Tools from './Tools';
import NewWithdrawal from './Withdrawals/NewWithdrawal';
import { AnimatePresence } from 'framer-motion';

const ViewsRouter = () => {
    const location = useLocation();
    return (
        <AnimatePresence exitBeforeEnter initial={false}>
            <Switch location={location} key={location.pathname}>
                <Route path={`${config.root_path}${config.items.overview.path}`} component={Overview} />  
                <Route path={`${config.root_path}${config.items.payments.path}`} exact component={Payments} />
                <Route path={`${config.root_path}${config.items.payments.path}/invoice`} exact component={Invoice}/>            
                <Route path={`${config.root_path}${config.items.settings.path}`} component={Settings} />             
                <Route path={`${config.root_path}${config.items.tools.path}`} component={Tools} />             
                <Route path={`${config.root_path}${config.items.withdrawals.path}/new-withdrawal`} component={NewWithdrawal} />             
                <Route path={`${config.root_path}${config.items.withdrawals.path}`} component={Withdrawals} />             
            </Switch>
        </AnimatePresence>
    );
}

export default ViewsRouter;