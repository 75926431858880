import React, { useRef } from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";
import { useOnClickOutside } from '../../../hooks';

const modalRoot = document.getElementById("modal-root");

const Modal = ({ type = "basic", className="", id, children, handleCloseModal, closeIcon }) => {
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, ()=> handleCloseModal(false));

  return ReactDOM.createPortal(
    <div {...id ? {id: id} : {}} className={`modal ${type} ${className}`}>
      <div ref={containerRef} className="modal__container">
        {closeIcon && <button className="close-button" onClick={handleCloseModal}>
          <img src={require("../../../images/close-modal-icon.svg")} alt="close icon" />
        </button> }
        {children}
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;