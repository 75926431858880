import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import {config} from '../../config';
import Input from '../Common/Input';
import Recaptcha from 'react-recaptcha';
import ForgotPassword from './ForgotPassword';
import {Tooltip} from '../Common/Tooltip';
import { passwordSchema } from '../Signup/Signup';
import { motion } from 'framer-motion';
import { pageVariants } from '../../Animations';

const Header = styled.header`
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    margin-bottom: 34px;
    img{
        width: 80px;
        margin-right: 24px;
    }
    h1{
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: #081339;
    }
    @media(max-width: 768px){
        padding: 0 24px 24px;
        margin-bottom: 24px;
        margin-left: -24px;
        margin-right: -24px;
        img{
            width: 40px;
            margin-right: 15px;
        }
        h1{
            font-size: 18px;
        }
    }
`

const CaptchaHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 304px;
    border: ${({error}) => error && "1px solid salmon"};
    margin: 0 auto 24px;
`

const Forgot = styled.p`
    margin: ${({error}) => error ? "32px" : "15px"} 0 22px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left !important;
    color: #10bfa0;
    padding-left: 16px;
    text-decoration: underline;
    cursor: pointer;
    @media(max-width: 768px){
        padding-left: 0;
    }
`

const CreateAccount = styled.p`
    margin: 20px 0 0 0;
    font-size: 14px;
    text-align: center;
    font-family: Roboto;
    
    & a {
        color: #10bfa0;
    }
`

const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

class Login extends Component{

    state = {
        email: "",
        email_err: false,
        password: "",
        password_err: false,
        captchaVerified: process.env.NODE_ENV === 'development',
        captcha_err: false,
        isForgot: false,
    }

    verifyCallback(){
        this.setState({...this.state, captchaVerified: true, captcha_err: false})
    }

    callback(){
    }

    componentWillUnmount() {
        this.props.setTooltipMessage(false, true, "");
    }

    shouldComponentUpdate(nextProps, nextState){
        return nextState.password_err !== this.state.password_err
                || nextState.email_err !== this.state.email_err
                || nextState.captcha_err !== this.state.captcha_err
                || nextProps.isDesktop !== this.props.isDesktop
                || nextState.isForgot !== this.state.isForgot
                || nextProps.tooltipMessage !== this.props.tooltipMessage
                || nextState.email !== this.state.email
                || nextState.password !== this.state.password
                || nextState.captchaVerified !== this.state.captchaVerified
    }
    
    onChangeHandler(value, id){
        this.setState({...this.state, [id]: value, [`${id}_err`]: false})
    }

    validate(){
        let isValid = true;
        const newState = {...this.state};
        const mandatoryFields = ["email", "password"];
        mandatoryFields.forEach(field => {
            if(this.state[field] === ""){
                isValid = isValid && false;
                newState[`${field}_err`] = "Required field";
            }
        });

        let failedPasswordRules = passwordSchema.validate(this.state.password, {list: true});
        const passwordErr = "Select an 8-15 character password with one number, lowercase and uppercase letter and at least one special letter.";
        if(this.state.password !== "" && failedPasswordRules.length > 0) {
            newState.password_err = passwordErr;
            isValid = false;
        }

        if(this.state.email !== "" && !pattern.test(this.state.email)){
            isValid = false;
            newState['email_err'] = "Invalid email";
        }
        if(!this.state.captchaVerified){
            isValid = false;
            newState['captcha_err'] = true
        }
        window.scrollTo(0, 0);
        this.setState(newState)
        return isValid;
    }

    onSubmit(e){
        e.preventDefault();
        if(this.validate()){
            this.props.onLogin(this.state)
        }
    }

    toggleView(isForgot){
        this.props.setTooltipMessage(false, true, "");
        this.setState({
            email: "",
            email_err: false,
            password: "",
            password_err: false,
            captchaVerified: process.env.NODE_ENV === 'development',
            captcha_err: false,
            isForgot: isForgot ? isForgot : false,
        })
    }

    render(){
        const {show, key, isSuccess} = this.props.tooltipMessage;
        return(
            this.state.isForgot
            ? <ForgotPassword toggleView={() => this.toggleView(false)}/>
            :   
                <motion.form onSubmit={(e) => this.onSubmit(e)} initial="hidden" animate="visible"  exit="hidden" variants={pageVariants}>
                    {show && <Tooltip message={config.tooltipMessages[key]} isOutsideApp fullWidth isSuccess={isSuccess}/>}
                    <Header>
                        <img src={require('../../images/signup/login.svg')} alt="login"/>
                        <h1>Login</h1>
                    </Header>
                    <Input type="text" label="Email address" id="email" error={this.state.email_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm className="isForm"/>
                    <Input type="password" label="Password" id="password" error={this.state.password_err} onChangeOutside={({target}) => this.onChangeHandler(target.value, target.id)} isForm isPassword className="isForm isPassword"/>
                    <Forgot error={this.state.password_err} onClick={() => this.toggleView(true)} style={{color: "#10bfa0"}}>Forgot password?</Forgot>
                    <CaptchaHolder error={this.state.captcha_err}>
                        <Recaptcha
                            sitekey="6LdeZbYUAAAAAAfiA6Nz6VRv1kAm9FvTwqAks5Wf"
                            render="explicit"
                            verifyCallback={() => this.verifyCallback()}
                            onloadCallback={() => this.callback()}
                            badge
                        />
                    </CaptchaHolder>
                    <button type="submit" onClick={(e) => this.onSubmit(e)}>login</button>
                    <CreateAccount>New to ADA Pay Business? <Link to={config.register}>Create account</Link></CreateAccount>
                </motion.form>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        isDesktop: app.isDesktop,
        tooltipMessage: app.tooltipMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: payload => dispatch(actions.onLogin(payload)),
        setView: (view) => dispatch(actions.setView(view)),
        setTooltipMessage: (show, isSuccess, key) => dispatch(actions.setTooltipMessage(show, isSuccess, key))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));