import ReactGA from 'react-ga';

const getPage = () => {
    return window.location.pathname;
}

export const track = (category, event) => {
    event = event || 'click';
    ReactGA.event({
        category,
        action: event,
        label: getPage()
    });
}