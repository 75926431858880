import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import {config} from '../../../config';
import InnerTitle from '../../Common/InnerTitle';
import Table from './Table';
import {Tooltip} from '../../Common/Tooltip';
import Filter from '../../Common/Filter';
import  { motion } from 'framer-motion';
import { pageVariants } from '../../../Animations';

const Wrapper = styled.div`
    margin-top: 1.0416666666666665vw;
    padding-top: 2.5vw;
    background-color: #ffffff;
    padding-bottom: 4.166666666666666vw;
    padding-left: 4.166666666666666vw;
    padding-right: 6.25vw;
    box-sizing: border-box;
    #title-holder{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    button{
        width: 10.416666666666668vw;
        height: 2.083333333333333vw;
        border-radius: 4px;
        background-color: #11deb3;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 0.9375vw;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        @media(max-width: 1200px){
            width: 15.625000000000002vw;
            height: 3.1249999999999996vw;
            font-size: 1.40625vw;
        }
        @media(max-width: 768px){
            width: 71px;
            height: 40px;
            font-size: 14px;
            transform: unset;
        }
        
    }
    @media(max-width: 768px){
        margin: 0;
        padding: 0;
        background-color: unset;
    }
`

class Withdrawals extends Component{
    state = {
        value: "", 
        calendarType: "", 
        from: "", 
        to: ""
    }

    onSearch(value, calendarType, from, to){
        this.setState({...this.state, value, calendarType, from, to})
    }

    onClickNewWithdrawal(){
        this.props.history.push(`${config.root_path}${config.items.withdrawals.path}/new-withdrawal`)
    }

    componentDidUpdate(){
        if(this.props.tooltipMessage.show){
            setTimeout(() => this.props.setTooltipMessage(false, true, ""), 7000);
        }
    }

    componentWillUnmount(){
        if(this.props.tooltipMessage.show){
            this.props.setTooltipMessage(false, true, "")
        }
    }
    
    render(){
        const {show, key, isSuccess} = this.props.tooltipMessage;
        return (
            <motion.div initial="hidden" animate="visible"  exit="hidden" variants={pageVariants}>
                <Wrapper>
                    <div id="title-holder">
                        <InnerTitle title="Withdrawals" path="withdrawals.svg"/>
                        <button onClick={() => this.onClickNewWithdrawal()}>{this.props.isDesktop ? "New Withdrawal" : "New"}</button>
                    </div>
                    {show && <Tooltip message={config.tooltipMessages[key]} fullWidth isSuccess={isSuccess}/>}
                    <Filter searchInput={config.withdrawals.searchInput} onSearch={(value, calendarType, from, to) => this.onSearch(value, calendarType, from, to)}/>
                    <Table filterParams={this.state} /> 
                </Wrapper>
            </motion.div>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        isDesktop: app.isDesktop,
        tooltipMessage: app.tooltipMessage,
    };
};

const mapDispatchToProps = dispatch => ({ 
    setTooltipMessage: (show, isSuccess, key) => dispatch(actions.setTooltipMessage(show, isSuccess, key)) ,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Withdrawals));