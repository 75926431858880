import moment from 'moment';
import React from 'react'
import {config} from '../../../config';
import { commaFormatted, toDisplayAmount } from '../../../shared/utility';
import './TransactionRow.scss';

const TransactionRow = ({status, amount, date, address, confirmation = [0, config.transaction.maxMonitoredDepth] }) => {
    const [currentLevel, maxLevel] = confirmation;

    return (
        <div className={`transaction-row-component ${status}`}>
           <div className="transaction-row-component__header">
                {status && <img src={require(`../../../images/transaction/${status}.svg`)} alt={status} />}
                <span>{currentLevel} of {maxLevel} confirmations</span>
                <b>{commaFormatted(toDisplayAmount(amount, config.currencies.ADA.decimals, 2))} {config.currencies.ADA.symbol}</b>
           </div>

           <div className="transaction-row-component__date">
                <span>{status}</span>
                <b>{moment.utc(date).format('HH:mm')}, {moment.utc(date).format('DD.MM.YYYY')}</b>
           </div>

           <a 
               href={`${process.env.REACT_APP_CARDANO_EXPLORER}/en/transaction?id=${address}`} 
               className="transaction-row-component__address"
               rel="noopener noreferrer"
               target="_blank"
          >
                {address}
           </a>
        </div>
    )
}

export default TransactionRow;