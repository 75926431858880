import React from 'react'
import './Spinner.scss';

const Spinner = ({className, type = "default"}) => {
    return (
        <span className={`spinner-component ${type ?? ''} ${className ?? ''}`}></span>
    )
}

export default Spinner;
