import React, {Component} from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Navbar = styled.nav`
    width: 100%;
    height: 88px;
    padding: 2.083333333333333vw 4.2vw; 
    display: flex;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    justify-content: space-between;
    font-family: roboto, sans-serif;
    > img{
        width: 91.8px;
        height: 48px;
    }
    > div{
        display: flex;
        align-items: center;
    }
    a{
        text-decoration: none;
        font-size: 16px;
        background-color: #0033ad;
        border-radius: 20px;
        color: white;
        padding: 8px 38px;
        height: 40px;
        line-height: 1;
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
            font-size: 9px;
            padding: 4px 18px;
            height: 24px;
        }
    }
    
    @media(max-width: 767px){
        height: 64px;
        padding: 0 3.1249999999999996vw 0 4.375vw;

        @media (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        > img{
            width: 56px;
            height: 29.3px;
        }
    }
`

class Nav extends Component {

    state = {
        toggleMobileMenu: false
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.windowWidth !== this.props.windowWidth){
            this.handleResize(this.props.windowWidth)
        }
    }

    handleResize(windowWidth) {
        this.setState({
            ...this.state,
            toggleMobileMenu: windowWidth > 768 ? false : this.state.toggleMobileMenu,
        });
    }


    toggleMobileMenu(isLogout){
        this.setState({
            ...this.state,
            toggleMobileMenu: !this.state.toggleMobileMenu
        })
    }

    render(){
        return (
            <React.Fragment>
                <Navbar isDesktop={this.props.isDesktop}>
                    <img src={require('../../../images/adapay-logo.svg')} alt="adapay logo"/>
                    <Link to="/dashboard">{this.props.isAuth ? 'Dashboard' : 'Login'}</Link>
                </Navbar>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        isDesktop: app.isDesktop,
        isAuth: app.account.isAuth
    }
}

export default withRouter(connect(mapStateToProps)(Nav));