import React, { useMemo, useState } from 'react';
import Modal from './Modal';
import { track } from '../../shared/analytics';
import { useHistory } from 'react-router';

const ApplyAsMerchantButton = ({position, isModal}) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const _onClick = () => {
    track('Homepage', `Register - ${position || ''}`);
    if(isModal) return setShowModal(true);
    history.push('/registration');
  }
  
  return useMemo(() => <>
    {showModal && <Modal className="thankyou-modal" handleCloseModal={() => setShowModal(false)} closeIcon>
      <h2>Thank you for your interest in ADA Pay</h2>
      <p>Please send your details to</p>
      <a href="mailto:support@adapay.finance" onClick={() => track('Homepage', `Email (application) - ${position}`)}>support@adapay.finance</a>
    </Modal>}
    <button className="register-now-link" onClick={_onClick}>Register</button>
  </>, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [position, showModal])
};

export default ApplyAsMerchantButton;
