import React from 'react'
import { SETTING_TYPES } from '../Setting';
import Code from './Code';
import FileInput from './FileInput';
import SettingInput from './SettingInput';

export default ({type, setValue, disabled, value, onClick, isValid, onSubmit, isEdit, onEdit, onCancel, isClickable}) => {
    switch(type) {
        case SETTING_TYPES.FILE: {
            return <div className={`setting-component__value choose-file-component logo ${!isClickable ? 'not-clickable' : ''}`}>
                {value ? <img src={value?.url} alt={type} /> : <div className="image-placeholder"></div>}
                <FileInput value={value} setValue={setValue} />
            </div>
        }

        case SETTING_TYPES.INPUT: {
            return <SettingInput disabled={disabled} value={value} onClick={onClick} isValid={isValid} onSubmit={onSubmit} setValue={setValue} />
        }

        case SETTING_TYPES.EDIT: {
            if(!onClick) return null;
            if(isEdit) {
                return <SettingInput 
                    disabled={disabled}  
                    value={value} 
                    onClick={onClick} 
                    isValid={isValid} 
                    onSubmit={onEdit} 
                    setValue={setValue}
                    isEdit={isEdit}
                    onCancel={onCancel}
                />
            }
            return <button onClick={onEdit}>Edit</button> 
   
        }

        case SETTING_TYPES.CODE: 
            return <Code value={value} />
            
        default: 
            return null;
    }
}
