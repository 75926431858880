import io from 'socket.io-client';
import moment from 'moment';


let socket; 

const createConnection = (handler, errorHandler) => new Promise((resolve, reject) => {
  let reconnected = 0;
  console.log("create connection");
  try {
    socket = io(process.env.REACT_APP_API_HOST, {
      autoConnect: false,
      withCredentials: true,
      reconnectionAttempts: 12,
      reconnectionDelay: 5000,
    });

    socket.connect();

    socket.on('connect', () => {
      console.log('Websocket connection is open!');
      if(reconnected > 9) {
        return window.location.reload();
      }
      window.socket = socket;
      handler(socket);
    });

    socket.on('subscribe', (event) => {
      console.log(`Subscribe to socket`, event);
    })
      
    socket.on('connect_error', (error) => {
      reconnected++;
      console.log('Error connecting to server at: ',moment().format('HH:mm:ss'));
    });
    
    socket.on('reconnect_failed', a => {
      // server error page ? .. 
      window.location = '/';
    });
    
  }catch (error) {
    console.log('no io: ', error)
  }
});

const dispatcher = (JWR, res, rej) => JWR.statusCode > 199 && JWR.statusCode < 399 ? res(JWR.body) : rej(JWR);

const WS_API = {
    CONNECT: createConnection,
    ON: (event, handler) => socket.on(event, handler),
    subscribe: (cb, cbErrr) => socket.emit('subscribe', (body, JWR) => dispatcher(JWR, cb, cbErrr)),
    unsubscribe: (cb, cbErrr) => socket.emit('unsubscribe', (body, JWR) => dispatcher(JWR, cb, cbErrr))
}

export default WS_API;