import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import {config} from '../../../config';
import ChangePassword from '../../Common/ChangePassword';
import InnerTitle from '../../Common/InnerTitle';
import {Tooltip} from '../../Common/Tooltip';
import Expand from '../../Common/Expand/Expand';
import PaymentSettings from './PaymentSettings';
import MerchantProfile from './MerchantProfile/MerchantProfile';
import  { motion } from 'framer-motion';
import { pageVariants } from '../../../Animations';

const Wrapper = styled.div`
    margin-top: 1.0416666666666665vw;
    padding-top: 2.5vw;
    background-color: #FFFFFF;
    padding-bottom: 4.166666666666666vw;
    padding-left: 4.166666666666666vw;
    padding-right: 3.6458333333333335vw;
    width: 100%;
    font-family: roboto, sans-serif;
    box-sizing: border-box;
    @media(max-width: 768px){
        margin: 0;
        padding: 0;
        background-color: unset;
    }
`
const Divider = styled.div`
    border-top: 1px solid #DDE0E6;
    margin-top: ${({marginTop}) => marginTop ? marginTop : "-1.6666666666666667vw"};
    @media(max-width: 1024px){
        margin-top: 16px;
        margin-left: -24px;
        margin-right: -24px;
    }
`

const Subtitle = styled.h4`
    color: #131313;
    font-weight: bold;
    font-size: 16px;
    margin: 1.25vw 0 1.8229166666666667vw 0.8333333333333334vw;

    @media(max-width: 1024px){
        font-size: 16px;
        margin: 1.875vw 0 1.875vw 0;
    }
    @media(max-width: 768px){
        font-size: 16px;
        margin: 25px 0 24px 0;
    }
`
const Label = styled.p`
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: inherit;
    letter-spacing: normal;
    text-align: left;
    color: #131313;
    padding-left: 0.8333333333333334vw;
    padding-bottom: 0.5212211466865228vw;
    display: block;
    margin: 0;
    @media(max-width: 1024px){
        font-size: 1.25vw;
        padding: 0;
    }
    @media(max-width: 768px){
        padding-left: 0;
        font-size: 14px;
    }
`
const Holder = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    > button{
        width: 10.416666666666668vw;
        height: 2.083333333333333vw;
        border-radius: 4px;
        background-color: #11DEB3;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 0.9375vw;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 1200px){
            width: 15.625000000000002vw;
            height: 3.1249999999999996vw;
            font-size: 1.40625vw;
        }
        @media(max-width: 768px){
            width: 100%;
            height: 40px;
            font-size: 18px;
            margin-top: 12px;
        }
    }
    @media(max-width: 768px){
        flex-direction: column;
        align-items: flex-start;
    }
`

class Settings extends Component{
    state = {
        showForm: false
    }
    toggleView(showForm){
        this.setState({...this.state, showForm})
    }
    componentDidUpdate(prevProps){
        if(!prevProps.tooltipMessage.show && this.props.tooltipMessage.show){
            setTimeout(() => this.props.setTooltipMessage(false, true, ""), 7000);
        }
    }
    componentWillUnmount(){
        if(this.props.tooltipMessage.show){
            this.props.setTooltipMessage(false, true, "")
        }
    }
    render(){
        const {show, key, isSuccess} = this.props.tooltipMessage;
        return (
            <motion.div initial="hidden" animate="visible"  exit="hidden" variants={pageVariants}>
                <Wrapper>
                    {
                        this.state.showForm
                        ? <ChangePassword onClickBack={() => this.toggleView(false)}/>
                        :
                            <React.Fragment>
                                <InnerTitle title="Settings" path="settings-large.svg"/>
                                {show && <Tooltip isSuccess={isSuccess} message={config.tooltipMessages[key] || key} fullWidth/>}
                                <Divider marginTop="24px" />
                                <Subtitle>Password</Subtitle>
                                <Holder>
                                    <Label>To change your password you will need your old password</Label>
                                    <button onClick={() => this.toggleView(true)}>Change Password</button>
                                </Holder>

                                <Divider marginTop="24px" />
                                <Expand hideIcon isExpanded header={<b>Payment settings</b>} expandedView={<PaymentSettings />} />
                                
                                <Divider marginTop="0" />
                                <Expand hideIcon isExpanded header={<b>Merchant profile</b>} expandedView={<MerchantProfile />} />
                            </React.Fragment>
                    }
                </Wrapper>
            </motion.div>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        view: app.view,
        tooltipMessage: app.tooltipMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTooltipMessage: (show, isSuccess, key) => dispatch(actions.setTooltipMessage(show, isSuccess, key))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));