export const listItemVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      ease: "linear",
      duration: 0.4,
      delay: i * 0.03,
    },
  }),
};

export const fadeInRightVariants = {
  hidden: {
    x: -100,
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.6,
      delay: 0,
    }
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 0.6,
      delay: 0,
    }
  },
};

export const expandVariants = {
  hidden: {
    height: 0,
    opacity: 0,
    transition: {
      ease: "easeOut",
      delay: 0,
      duration: 0.3,
    }
  },
  visible: {
    height: "100%",
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 0.3,
    }
  },
};

export const expandItemsVariants = {
  hidden: {
    height: 0,
    opacity: 0,
  },
  visible: {
    height: "100%",
    opacity: 1,
  },
};

export const pageVariants = {
  hidden: {
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.3,
    }
  },
  visible: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 0.3,
    }
  },
}



export const scaleVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      ease: "easeOut",
      duration: 0.3,
    }
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 0.3,
    }
  },
}