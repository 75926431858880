import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { config } from '../../config';
import { setTooltipMessage } from '../../store/actions';
import xhrApi from '../../xhr-api';

const EmailVerification = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const requestCode = new URLSearchParams(window.location.search);
    const requestCodeValue = requestCode.get("requestCode");
   
    const verifyEmail = useCallback(async () => {
        try {
            if(!requestCodeValue) throw new Error("verifiedFailed");

            await xhrApi.EMAIL_VERIFICATION({
                emailVerificationHash: requestCodeValue
            });

            dispatch(setTooltipMessage(true, true, "verifiedSuccess"))
            history.push(config.root_path);
        } catch(error) {    
            console.log(error);
            dispatch(setTooltipMessage(true, false, "verifiedFailed"));
            history.push(config.root_path);
        }
    }, [dispatch, history, requestCodeValue])

    useEffect(() => {
        verifyEmail();
    }, [requestCodeValue, verifyEmail]);

    return <div></div>;
}

export default EmailVerification;