import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Setting, { SETTING_TYPES } from '../../../Common/Setting/Setting';
import { config } from '../../../../config';
import { updateMerchantInfo } from '../../../../store/actions/integrations';
import { useApiValue } from '../../../../hooks';
import  { motion } from 'framer-motion';
import { expandItemsVariants } from '../../../../Animations';
import './PaymentSettings.scss';

const apiUpdatedKeys = {
    [config.settings.paymentRequestApprovalPercentage]: "paymentRequestApprovalPercentage",
    [config.settings.expireTime]: "paymentRequestExpirationTime",
    [config.settings.receipt]: "receiptsEmail",
}

const PaymentSettings = () => {
    const dispatch = useDispatch();
    const { paymentRequestApprovalPercentage, paymentExpireTime } = useSelector(({app}) => app);
    const [_approvalValue, _setApprovalValue] = useApiValue(paymentRequestApprovalPercentage, config.tooltipsErrorsIds.settings);
    const [_expireTimeValue, _setExpireTimeValue] = useApiValue(paymentExpireTime, config.tooltipsErrorsIds.settings);

    const onSubmit = (key, value) => {
        dispatch(updateMerchantInfo({
            [apiUpdatedKeys[key] || key]: value
        }));
    }

    return (
        <motion.div className="payment-settings-component" variants={expandItemsVariants}>
            <Setting
                id={config.settings.paymentRequestApprovalPercentage}
                type={SETTING_TYPES.EDIT}
                title="Payment approval"
                description="The percentage of the payment amount the customer has to send in order for the payment to be approved"
                value={_approvalValue}
                setValue={_setApprovalValue}
                validations={["required", "number", "min:1", "max:100"]}
                onClick={onSubmit}
                disabled={_approvalValue === paymentRequestApprovalPercentage}
                initialValue={paymentRequestApprovalPercentage}
                suffix="%"
            />

            <Setting
                id={config.settings.expireTime}
                type={SETTING_TYPES.EDIT}
                title="Payment expire time"
                description="How much time the customer will have to complete their payment until the payment expires"
                value={_expireTimeValue}
                setValue={_setExpireTimeValue}
                validations={["required", "number", "min:15", "max:9999"]}
                onClick={onSubmit}
                disabled={_expireTimeValue === paymentExpireTime}
                initialValue={paymentExpireTime}
                suffix=" minutes"
            />

        </motion.div>
    )
}

export default PaymentSettings;
