import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {Tooltip} from '../Common/Tooltip';
import { config } from '../../config';
import { UrlParams } from '../../shared/UrlParams';
import xhrApi from '../../xhr-api';
import { debounce } from 'lodash';
import Spinner from '../Common/Spinner';

const Wrapper = styled.div`
    width: 704px;
    border-radius: 2px;
    box-shadow: 0px 2px 4.9px 0.1px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    padding: 70px 50px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: roboto, sans-serif;
    h1{
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        color: #081339;
        margin: 0 0 40px 0;
    }
    > img{
        width: 176px;
        margin-bottom: 32px;
    }
    a{
        font-size: 14px;
        color: #10bfa0;
        cursor: pointer;
        text-direction: underline;
        margin-top: 10px;
    }
    p{
        font-size: 14px;
        color: #131313;
        margin: 0 0 10px;
        text-align: center;
        &.emailSent { 
            margin-top: 10px;
            color: #fff;
            padding: 7px 15px;
            &.successful { background: #11deb3; }
            &.unsuccessful { background: salmon; }
        }
    }
    > button#resend{
        font-size: 14px;
        color: #10bfa0;
        cursor: pointer;
        margin-top: 20px;
        border: 0;
        background-color: unset;

        &:disabled {
            opacity: 0.5;
        }

        &:focus: {
            outline: 0;
        }
    }
    @media(max-width: 768px){
        border-radius: unset;
        box-shadow: unset;
        background-color: unset;
        padding: 36px 24px 40px;
        margin-bottom: auto;
        h1{
            font-size: 18px;
        }
        > img{
            margin-bottom: 47px;
        }
    }
`

class Status extends Component {

    state = {
        email: "test@coti.io",
        isSent: false,
        message: false,
        status: "thankYou",
        isSending: false
    }

    componentDidMount(){
        const params = new UrlParams(window.location.search);
        const status = params.get('status'); 

        if(status === 'not-verified') {
            const email = params.get('email'); 
            if(email) return this.setState({
                ...this.state,
                email: email,
                status: 'confirmEmail'
            });
        } 
    }


    emailSent(bol,msg) {
        let updatedProperties = {isSent: bol, message: msg}
        this.setState({...this.state, ...updatedProperties });
    }

    debouncedResendEmail = debounce(function() {
        this.resendEmail();
    }, 5000);

    handleResendEmail = () => {
        this.setState({
            isSending: true
        });

        this.debouncedResendEmail();
    }

    async resendEmail(){     
        try {
            const JWR = await xhrApi.RESEND_EMAIL_VERIFICATION({email: this.state.email}); 
            if(JWR.data) {
                setTimeout(()=> this.emailSent(false, false), 7000);
                this.emailSent(true, 'Verification email successfully sent');
            }
        } catch (error) {
            if(error && error.body && error.body.errorMessage){
                setTimeout(()=> this.emailSent(false, false), 4000);
                this.emailSent(false, "There was an error sending verification email. Please try again later.");
            }     
        } finally {
            this.setState({
                isSending: false
            });
        }
    }

    render(){
        const messages = config.statusMessages;
        return(
            <Wrapper>
                {this.state.message && this.state.status === "confirmEmail" && <Tooltip message={this.state.message} isSuccess={this.state.isSent} fullWidth/>}
                <h1>{messages[this.state.status]}</h1>
                <img src={require(`../../images/statuses/${this.state.status.toLowerCase()}.svg`)} alt="status"/>
                {
                    this.state.status === "confirmEmail" 
                    ?
                    <React.Fragment>
                        <p>We've sent you a verification email to:</p>
                        <p><b>{this.state.email}</b></p>
                        <p>Click the link in your email to confirm your account</p>
                        <button id="resend" onClick={()=>this.handleResendEmail()} disabled={this.state.isSending}>
                            Resend verification email {this.state.isSending && <Spinner /> }
                        </button>
                        <Link to={config.root_path}>Back to login</Link>
                    </React.Fragment>
                    :<p>
                        Your application has been received, and it's currently being reviewed by our team. <br/>
                        We'll be in touch as soon as it's approved!
                    </p>
          
                }
            </Wrapper>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        isDesktop: app.isDesktop,
    };
};

export default withRouter(connect(mapStateToProps, null)(Status));