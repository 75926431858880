import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';
import {config} from '../../config';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { slideInDown } from 'react-animations';

const slideDown = keyframes`${slideInDown}`;

const CalendarsHolder = styled.div`
    width: 15.625vw;
    display: flex;
    position: absolute;
    top: 2.9166666666666665vw;
    right: 0;
    animation: 0.6s ${slideDown};
    .rw-widget-container.rw-state-focus,
    .rw-state-focus>.rw-widget-container,
    .rw-widget-container.rw-state-focus:hover,
    .rw-state-focus>.rw-widget-container:hover {
        background-color: transparent;
        border-color: transparent;
        box-shadow: unset
    }
   
    > div{
        position: relative;
        border: #dde0e6 1px solid;
        > span{
            position: absolute;
            right: 0;
            width: 100%;
            top: 0;
            height: 100%;
            display: flex;
            align-items: center;
            font-family: roboto, sans-serif;
            background-color: #fff;
            font-size: 0.8333333333333334vw;
            padding-left: 0.8333333333333334vw;
            box-sizing: border-box;
            > span{
                color: #888888;
            }
        }
    }
    > div:first-child{
        width: 53%;
        .rw-popup-container{
            left: -6px;
            right: unset;
            top: 2.6vw;
        }
    }
    > div:last-child{
        width: 47%;
        .rw-popup-container{
            right: -6px;
            left: unset;
            top: 2.6vw;
        }
    }
    .rw-widget-container{
        border-radius: unset;
        outline: none;
        background-color: transparent;
        font-family: roboto, sans-serif;
        border: none;
        :hover{
            border: none;
            box-shadow: unset;
            background-color: transparent;
        }
    }
    .rw-btn-primary, .rw-btn-primary:hover{
        background-color: #fff;
    }
    .rw-popup-container{
        z-index: 10000;
    }
    .rw-datetime-picker:first-child{
        border-right: unset;
    }
    .rw-datetime-picker:last-child{
    }
    .rw-widget-picker{
        font-size: 0.8333333333333334vw;
        height: 2.5vw;
        font-family: roboto, sans-serif;
        background-color: transparent;
    }
    .rw-i-calendar, .rw-i-clock-o{
        display: none;
    }
    .rw-select{
        width: 52% !important;
        border: unset;
    }
    .rw-select button{
        width: 100%;
    }
    .rw-select button:last-child{
        display: none;
    }
    .rw-widget-input{
        display: none;
    }
    .rw-select-bordered:active, .rw-select-bordered:hover{
        background-color: unset;
        box-shadow: unset;
        background-color: transparent;
    }
    
    .rw-state-focus > .rw-widget-container:hover{
        border: none;
        box-shadow: unset;
        background-color: none;
    }
    .rw-btn-primary{
        opacity: 1 !important;
    }
    @media(max-width: 1200px){
        top: 4.375vw;
        right: 0;
        width: 20vw;
        .rw-widget-picker{
            height: 3.8vw;
            font-size: 1.25vw;
        }
        .rw-popup-container{
            top: 3.9vw !important;
        }
    }
    @media(max-width: 768px){
        position: initial;
        margin-top: 7px;
        width: 100%;
        .rw-widget-picker{
            height: 48px;
            font-size: 16px;
        }
        .rw-popup-container{
            top: 50px !important;
        }
        > div{
            width: 50%;
            > span{
                font-size: 16px;
                padding-left: 16px;
            }
        }
    }
`

class Calendars extends Component{

    state = {
        from: new Date(config.payments.defaultFromDate),
        to: new Date(),
        openFrom: false,
        openTo: false,
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.from !== prevState.from){
            this.props.onSelectDate(moment(this.state.from).format("YYYY-MM-DD"), "from")
        }
        else if(this.state.to !== prevState.to){
            this.props.onSelectDate(moment(this.state.to).format("YYYY-MM-DD"), "to")
        }
        if(this.props.to !== prevProps.to && this.props.to === moment().format("YYYY-MM-DD")){
            this.setState({...this.state, to: new Date()})
        }
        else if(this.props.from !== prevProps.from && this.props.from === config.payments.defaultFromDate){
            this.setState({...this.state, from: new Date(config.payments.defaultFromDate)})
        }
    }

    onSelectDate(date, direction){
        this.setState({...this.state, [direction]: date, [`open${direction.charAt(0).toUpperCase() + direction.slice(1)}`]: false})
    }

    toggleClendar(flag, type){
        return this.setState({...this.state, [type]: flag})
    }

    render(){
        moment.locale('en');
        momentLocalizer();
        return (
            <CalendarsHolder>
                <div>
                    <span><span>From:</span>&nbsp; {this.state.from && moment(this.state.from).format("L")}</span>
                    <DateTimePicker onToggle={(value) => this.toggleClendar(value, "openFrom")} open={this.state.openFrom} value={this.state.from} max={this.state.to ? new Date(this.state.to) : new Date()} placeholder="From:" onChange={value => this.onSelectDate(value, "from")}/>
                </div>
                <div>
                    <span><span>To:</span>&nbsp; {this.state.to && moment(this.state.to).format("L")}</span>
                    <DateTimePicker onToggle={(value) => this.toggleClendar(value, "openTo")} open={this.state.openTo} value={this.state.to} {...(this.state.from ? {min: new Date(this.state.from)} : {})} max={new Date()} placeholder="To:" onChange={value => this.onSelectDate(value, "to")}/>
                </div>
            </CalendarsHolder>
        )
    }
}

export default Calendars;