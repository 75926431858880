import React, {Component} from 'react';
import styled, { keyframes } from 'styled-components';
import {Logo} from './Logo';
import {config} from '../../config';
import {List} from './List';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fadeInRight } from 'react-animations';
import * as actions from '../../store/actions'; 
import LogoutButton from '../Common/LogoutButton';

const menuanimateOpen = keyframes`${fadeInRight}`;

const MenuContainer = styled.aside`
    width: 16.666666666666664vw;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to bottom, #081339, #081339), linear-gradient(to bottom, #000053, #00001d);
    font-family: roboto, sans-serif;
    position: fixed;
    height: 100%;
    z-index:100;
    @media(max-width: 1200px){
        width: 25vw;
    }
    @media(max-width: 768px){
        width: 100vw;
        height: 88px;
        position: fixed;
        top: 0;
        padding-top: 0;
        justify-content: center;
        z-index: 10000;
        align-items: center;
    }
`

const Hamburger = styled.img`
    cursor: pointer;
    position: absolute;
    left: 24px;
`

const MobileMenuOverlay = styled.div`
    animation: 0.4s ${menuanimateOpen};
    position: fixed;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    top:0;
    left: 0;
    height: 100vh;
    z-index: 2;
    padding: 88px 0 0;
    background-image: linear-gradient(to bottom, #081339, #081339), linear-gradient(to bottom, #000053, #00001d);
    overflow-y: auto;
`;

class Menu extends Component{

    state = {
        toggleMobileMenu: false
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.windowWidth !== this.props.windowWidth){
            this.handleResize(this.props.windowWidth)
        }
    }

    handleResize(windowWidth) {
        this.setState({
            ...this.state,
            toggleMobileMenu: windowWidth > 768 ? false : this.state.toggleMobileMenu,
        });
    }


    toggleMobileMenu(isLogout){
        isLogout 
        ? this.props.logout()
        : this.setState({
            ...this.state,
            toggleMobileMenu: !this.state.toggleMobileMenu
        })
    }

    renderDesktop(){
        const logoDark = config.logo_dark;
        const {isOutsideApp} = this.props;
        const isOutsideAppSession = this.isOutsideAppSession();
        return (
            isOutsideApp ?
                <React.Fragment>
                    <Logo src={logoDark} isOutsideApp/>
                    {isOutsideAppSession && <LogoutButton title={this.props.view} isDesktop={this.props.isDesktop} username={this.props.username} isOutsideApp/>}
                </React.Fragment>
            :
                <MenuContainer>
                    <Logo src={config.logo} desktop/>
                    <List view={this.props.view}/>
                </MenuContainer>
        )
    }

    isOutsideAppSession(){
        const pathname = this.props.history.location.pathname;
        const isOutsideAppSession = pathname.includes("company-details") || (pathname.includes("status") && !pathname.includes("not-verified"))
        return isOutsideAppSession;
    }

    renderMobile(){
        const {isOutsideApp} = this.props;
        const isOutsideAppSession = this.isOutsideAppSession();
        return(
            <MenuContainer>
                {
                    (isOutsideAppSession || !isOutsideApp) && 
                    <Hamburger src={this.state.toggleMobileMenu ? require('../../images/menu/menu-b.png') : require('../../images/menu/menu.png')} onClick={() => this.toggleMobileMenu()}/>
                }
                <Logo src={config.logo}/>
            </MenuContainer>
        )
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.props.isDesktop
                        ? this.renderDesktop()
                        : this.renderMobile()
                }
                {
                    this.state.toggleMobileMenu &&
                    <MobileMenuOverlay animate={this.state.toggleMobileMenu}>          
                        <List view={this.props.view} onClickItem={(isLogout) => this.toggleMobileMenu(isLogout)} mobile isOutsideApp={this.props.isOutsideApp}/>
                    </MobileMenuOverlay>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        view: app.view,
        isDesktop: app.isDesktop,
        windowWidth: app.windowWidth,
        username: app.account.isAuth ? `${app.account.firstName} ${app.account.lastName}` : ""
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout()),
        onAuth: () => dispatch(actions.onAuth())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));