import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import View from './Components/Views/View';
import Login from './Components/Login/Login';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import ResetPassword from './Components/Common/ChangePassword';
import {config} from './config';
import ViewContainer from './Components/Common/ViewContainer';
import {Logo} from './Components/Menu/Logo';
import Lp from './Components/Views/Lp/Lp';
import Signup from './Components/Signup/Signup';
import Status from './Components/Views/Status';
import EmailVerification from './Components/EmailVerification/EmailVerification';

const LoaderOverlay = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

`;

class AppRouter extends Component {

  componentDidMount(){
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.props.setWindowWidth(window.innerWidth);
  }

  doRedirect() {
    setTimeout(()=> window.location = process.env.REACT_APP_HOMEPAGE, 1500);
  }

  render(){
    if(this.props.loader) {
      return (<LoaderOverlay>
        <Logo src={config.logo_dark} />
      </LoaderOverlay>);
    }

    return ( 
        <Switch>
            <Route path={"/"} exact component={(props) => <Lp {...props}/>}/> 
            <Route path={config.root_path} exact component={() => <ViewContainer component={() => <Login/>}/>}/> {/* login rendering first after login view will loaded to dom */}
            <Route path={config.register} exact component={() => <ViewContainer component={() => <Signup/>}/>}/> 
            <Route path={config.appViews.verify_email.path} exact component={() => <ViewContainer component={() => <EmailVerification />}/>} />
            <Route path={config.appViews.status.path} render={() => <ViewContainer component={(props) => <Status {...props}/>} />}/>
            <Route path={config.appViews.resetPassword.path} render={() => <ViewContainer component={(props) => <ResetPassword {...props} isForgot/>}/>}/>
            <Route path={`${config.root_path}*`} render={(props) => <View {...props}/> }/> 
        </Switch>
      );
  }
}

const mapStateToProps = ({app}) => ({ 
  isAuth: app.account.isAuth, 
  loader: app.loader
});

const mapDispatchToProps = dispatch => ({ setWindowWidth: width => dispatch(actions.setWindowWidth(width)) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRouter));
