import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: #dde0e6;
    margin-right: 8px;
    font-family: roboto, sans-serif;
    padding: 2px 8px;
    border-radius: 4px;
    border: solid 1px #b9bcc7;
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    box-sizing: border-box;
    font-size: 12px;
    height: 32px;
    display: flex;
    align-items: center;
    > div{
        max-width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    > div:first-child{
        width: 22px;
        padding-right: 8px;
        border-right: 1px solid #b9bcc7;
        height: 32px;
        display: flex;
        align-items: center;
        margin-right: 14px;
        > img{
            width: 16px;
            cursor: pointer;
        }
    }
    @media(max-width: 1024px){
        margin-bottom: 4px;
    }
    @media(max-width: 768px){
        margin-bottom: 8px;
    }
`

export const Label = ({label, resetFilter}) => {
    return (
        <Wrapper>
            <div>
                <img src={require('../../images/close.svg')} onClick={() => resetFilter(label.key)} alt="close"/>
            </div>
            <div>{label.value}</div>
        </Wrapper>
    )
} 