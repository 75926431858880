import React from 'react';
import styled from 'styled-components';
import {config} from '../../config';

const Container = styled.div`
    width: ${({fullWidth}) => fullWidth ? "100%" : "520px"};
    padding: 15px 16px;
    background-color: ${({isSuccess, isAlert}) => isSuccess ? "#e7fcf7" : isAlert ? "#fdf8ea" : "#fdeaea"};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: roboto, sans-serif;
    letter-spacing: normal;
    text-align: left;
    color: ${({isSuccess, isAlert}) => isSuccess ? "#10bfa0" : isAlert ? "#c89b2a" : "#d42525"};
    display: flex;
    align-items: center;
    margin-top: ${({isOutsideApp}) => isOutsideApp ? "15px" : "-18px"};
    margin-bottom: ${({fullWidth, isAlert}) => fullWidth && !isAlert ? "50px" : "26px"};
    max-width: 100%;
    box-sizing: border-box;
    img{
        width: 17px;
        margin-right: 15px;
    }
    > span{
        margin-left: auto;
        margin-top: -15px;
        transform: rotate(45deg);
        font-size: 18px;
        cursor: pointer;
    }
    @media(max-width: 768px){
        margin: ${({fullWidth}) => fullWidth && "24px 0"};
        align-items: flex-start;
    }
`

export const Tooltip = ({message, isSuccess, fullWidth, isAlert, isOutsideApp, onClose}) => {
    const responseIcons = config.responseIcons
    return (
        <Container isSuccess={isSuccess} fullWidth={fullWidth} isAlert={isAlert} isOutsideApp={isOutsideApp}>
            <img src={require(`../../images/${isSuccess ? responseIcons.successIcon : isAlert ? responseIcons.alert : responseIcons.failIcon}`)} alt="icon"/>
            {message}
            {onClose && <span onClick={() => onClose()}>+</span>}
        </Container>
    )
}