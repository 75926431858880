import React, {Component} from 'react';
import styled from 'styled-components';
import Input from './Input';
import {config} from '../../config';
import Dropdown from './Dropdown';
import 'react-widgets/dist/css/react-widgets.css';
import moment from 'moment';
import {Label} from './Labels';
import _ from 'lodash';

const Holder = styled.div`
    display: flex;
    margin-bottom: ${({space}) => space ? '5vw' : '1.7708333333333333vw'};
    position: relative;
    @media(max-width: 1200px){
        margin-bottom: 7.5vw;
    }
    @media(max-width: 768px){
        flex-direction: column;
        margin-top: 24px;
    }
`
const LabelsHolder = styled.div`
    position: absolute;
    top: 2.9166666666666665vw;
    display: flex;
    @media(max-width: 1200px){
        top: 4.375vw;
    }
    @media(max-width: 1024px){
        max-width: 400px;
        flex-wrap: wrap;
    }
    @media(max-width: 768px){
        margin-top: 16px;
        flex-wrap: wrap;
        position: initial;
        max-width: 100%;
    }
`
const filterProperties = {
    search: "",
    filter: "all",
    from: config.payments.defaultFromDate,
    to: moment().format("YYYY-MM-DD"),
}

class Filter extends Component{

    state = {
        ...filterProperties,
        labels: []
    }

    shouldComponentUpdate(prevProps, prevState){
        return this.state.search !== prevState.search
                || this.state.filter !== prevState.filter
                || this.state.from !== prevState.from
                || this.state.to !== prevState.to
                || this.state.openFrom !== prevState.openFrom
                || this.state.openTo !== prevState.openTo
                || !_.isEqual(this.state.labels, prevState.labels);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.filter !== prevState.filter && this.state.filter === "all"){
            this.props.onSearch && this.props.onSearch(this.state.search, this.state.filter, this.state.from, this.state.to);
            this.setState({...this.state, labels: this.state.search ? [{value: this.state.search, key: "search"}] : []});
        }
        else if(this.state.search !== prevState.search
                || (this.state.filter !== prevState.filter && this.state.filter !== "custom")
                || this.state.from !== prevState.from
                || this.state.to !== prevState.to){
            const {from, to} = this.getFromAndToDates(this.state.filter)
            this.props.onSearch && this.props.onSearch(this.state.search, this.state.filter, from, to)
            this.handleLabelsArray(from, to)
        }
        if(this.state.filter !== prevState.filter && this.state.filter === "custom"){
            this.setState({...this.state, labels: this.state.search ? [{value: this.state.search, key: "search"}] : []})
        }
        if(this.state.from === filterProperties.from && this.state.to === filterProperties.to && 
            (this.state.from !== prevState.from || this.state.to !== prevState.to)){
            this.setState({...this.state, filter: filterProperties.filter})
        }
    }

    handleLabelsArray(from, to){
        let labels = [];
        if(this.state.search !== ""){
            labels.push({value: this.state.search, key: "search"});
        }
        if(from && moment(config.payments.defaultFromDate).format("YYYY-MM-DD") !== from && this.state.filter === "custom"){
            labels.push({value: `From: ${from}`, key: "from"})
        }
        if(to && moment().format("YYYY-MM-DD") !== to && this.state.filter === "custom"){
            labels.push({value: `To: ${to}`, key: "to"})
        }
        if(this.state.filter !== "all" && this.state.filter !== "custom"){
            const filterLabeled = config.payments.filterOptions.find(option => option.value === this.state.filter).label;
            labels.push({value: filterLabeled, key: "filter"})
        }
        this.setState({...this.state, labels})
    }

    getFromAndToDates(filter){
        let from, to;
        if(filter === "last3"){
            from = moment().add(-3, 'months').format("YYYY-MM-DD");
            to = moment().format("YYYY-MM-DD");
        }
        else if(filter === "last30"){
            to = new Date()
            from = new Date().setDate(to.getDate()-30);
            to = moment(to).format("YYYY-MM-DD");
            from = moment(from).format("YYYY-MM-DD")
        }
        else if(filter === "yearToDate"){
            from = moment().startOf('year').format("YYYY-MM-DD");
            to = moment().format("YYYY-MM-DD");
        }
        else if(filter === "custom"){
            from = moment(this.state.from).format("YYYY-MM-DD");
            to = moment(this.state.to).format("YYYY-MM-DD");
        }
        return {from, to}
    }

    onChangeHandler(name, value){
        this.setState({...this.state, [name]: value})
    }

    onSelectOption(option){
        this.setState({...this.state, filter: option, from: filterProperties.from, to: filterProperties.to})
    }

    onSelectDate(date, direction){
        this.setState({...this.state, [direction]: date})
    }

    toggleClendar(flag, type){
        return this.setState({...this.state, [type]: flag})
    }

    resetFilter(key){
        this.setState({...this.state, [key]: filterProperties[key]})
    }

    render(){
        return (
            <Holder space={this.state.filter === "custom" || !!this.state.labels.length}>
                <Input 
                    placeholder={this.props.searchInput} 
                    id="search" 
                    width="84%" 
                    type="text" 
                    value={this.state.search} 
                    isSearch 
                    className="isSearch" 
                    onChangeOutside={(e) => this.onChangeHandler(e.target.id, e.target.value)}
                />
                <Dropdown 
                    options={config.payments.filterOptions} 
                    id="filter" 
                    tabIndex={2} 
                    width="15" 
                    isSearch 
                    className="isSearch" 
                    onSelectOption={(option) => this.onSelectOption(option)}
                    onSelectDate={(date, direction) => this.onSelectDate(date, direction)}
                    from={this.state.from}
                    to={this.state.to}
                    type={this.state.filter}
                />
                {
                    !!this.state.labels.length &&
                        <LabelsHolder>
                            {this.state.labels.map((label, i) => <Label key={i} label={label} resetFilter={(key) => this.resetFilter(key)}/>)}
                        </LabelsHolder>
                }
            </Holder>
        )
    }
}

export default Filter;