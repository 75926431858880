import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Image = styled.img`
    width: ${({isOutsideApp}) => isOutsideApp ? "102px" : "5.489583333333334vw"};
    margin-top: 3.3333333333333335vw;
    margin-left: ${({desktop})=> desktop && 'auto'};
    margin-right: ${({desktop})=> desktop && 'auto'};
    margin-bottom: 3.28125vw;
    margin: ${({isOutsideApp}) => isOutsideApp && "0"};
    position: ${({isOutsideApp}) => isOutsideApp && "fixed"};
    top: ${({isOutsideApp}) => isOutsideApp && "2%"};
    left: ${({isOutsideApp}) => isOutsideApp && "2%"};
    display: flex;
    @media(max-width: 1200px){
        width: ${({isOutsideApp}) => !isOutsideApp && "12.734375vw"};
        margin-top: 5vw;
        margin-left: ${({desktop})=> desktop && '3.75vw'};
        margin-bottom: 4.921875vw;
        margin: ${({isOutsideApp}) => isOutsideApp && "0"};
    }
    @media(max-width: 768px){
        width: ${({isOutsideApp}) => !isOutsideApp && "64px"};
        margin: 0;
    }
`

export const Logo = ({src, desktop, isOutsideApp}) => (
    <Link to={isOutsideApp ? '/dashboard' : '#'}>
        <Image src={require(`../../images/${src}`)} desktop={desktop} isOutsideApp={isOutsideApp} alt="coti-pay-business"/>
    </Link>
)