import React, {Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {config} from '../../../config';
import Table from './Table';
import InnerTitle from '../../Common/InnerTitle';
import Filter from '../../Common/Filter';
import  { motion } from 'framer-motion';
import { pageVariants } from '../../../Animations';

const Wrapper = styled.div`
    margin-top: 1.0416666666666665vw;
    padding-top: 2.5vw;
    background-color: #ffffff;
    padding-bottom: 4.166666666666666vw;
    padding-left: 4.166666666666666vw;
    padding-right: 6.25vw;
    box-sizing: border-box;
    @media(max-width: 768px){
        margin: 0;
        padding: 0;
        background-color: unset;
    }
`

class Payments extends Component {

    state = {
        value: "", 
        calendarType: "", 
        from: "", 
        to: ""
    }

    onSearch(value, calendarType, from, to){
        this.setState({...this.state, value, calendarType, from, to})
    }

    render(){
        return(
            <motion.div initial="hidden" animate="visible"  exit="hidden" variants={pageVariants}>
                <Wrapper>
                    <InnerTitle title="Payments" path="recent.svg" isTable/>
                    <Filter searchInput={config.payments.searchInput} onSearch={(value, calendarType, from, to) => this.onSearch(value, calendarType, from, to)}/>
                    <Table filterParams={{...this.state}}/> 
                </Wrapper>
            </motion.div>
        )
    }
}

const mapStateToProps = ({app}) => {
    return {
        view: app.view,
        isDesktop: app.isDesktop,
        payments: app.payments,
        account: app.account,
        currencies: app.currencies
    };
};

export default withRouter(connect(mapStateToProps)(Payments));