import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { config } from '../../../config';
import { setTooltipMessage } from '../../../store/actions';
import Expand from '../../Common/Expand/Expand';
import InnerTitle from '../../Common/InnerTitle';
import { Tooltip } from '../../Common/Tooltip';
import ButtonSettings from './ButtonSettings';
import CodeSettings from './CodeSettings';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import './Tools';
import  { motion } from 'framer-motion';
import { pageVariants } from '../../../Animations';

const Wrapper = styled.div`
    margin-top: 1.0416666666666665vw;
    padding-top: 2.5vw;
    background-color: #FFFFFF;
    padding-bottom: 4.166666666666666vw;
    padding-left: 4.166666666666666vw;
    padding-right: 3.6458333333333335vw;
    width: 100%;
    font-family: roboto, sans-serif;
    box-sizing: border-box;
    @media(max-width: 768px){
        margin: 0;
        padding: 0;
        background-color: unset;
    }
`

const Divider = styled.div`
    border-top: 1px solid #DDE0E6;
    margin-top: ${({marginTop}) => marginTop ? marginTop : "-1.6666666666666667vw"};
    
    @media(max-width: 1024px){
        margin-top: 16px;
        margin-left: -24px;
        margin-right: -24px;
    }
`

const Tools = () => {
    const dispatch = useDispatch();
    const { tooltipMessage = {} } = useSelector(({app}) => app);
    const {show, key, isSuccess} = tooltipMessage;
    const [isChecked, setIsChecked] = useState("small");

    useEffect(() => {
        return () => {
            dispatch(setTooltipMessage(false, true, ""));
        }
    }, [dispatch]);

    return (
        <motion.div initial="hidden" animate="visible"  exit="hidden" variants={pageVariants}>
            <Wrapper className="integration-tools-page">
                {show && <Tooltip message={config.tooltipMessages[key]} isSuccess={isSuccess} fullWidth/>}
                <InnerTitle class="inner-title" title="Integration tools" path="integration-tools.svg"/>
                <Divider marginTop="24px" />

                <Expand header={<b>Payment settings</b>} expandedView={<PaymentSettings />} />
                <Divider marginTop="24px" />
                <Expand 
                    hideIcon 
                    isExpanded
                    header={<b>Payment button</b>} 
                    expandedView={<ButtonSettings isChecked={isChecked} setIsChecked={setIsChecked} />} 
                />

                <Divider marginTop="24px" />
                <Expand isExpanded hideIcon expandedView={<CodeSettings type={isChecked} />} />
            </Wrapper>
        </motion.div>
    )
}

export default Tools;