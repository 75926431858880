import React, {Component} from 'react';
import styled from 'styled-components';
import {config} from '../../config';
import showEye from '../../images/signup/pass-show.svg';
import hideEye from "../../images/signup/pass-hide.svg";
import showEyeHover from '../../images/signup/pass-show-hover.svg';
import hideEyeHover from "../../images/signup/pass-hide-hover.svg";
import Spinner from './Spinner';
import { debounce } from 'lodash';

const CustomInput = styled.input`
    border: ${({error}) => error ? "solid 1px salmon" : "solid 1px #dde0e6"};
    background-color: #ffffff;
    font-size: 0.8333333333333334vw;
    height: 2.5vw;
    padding-left: 2.5vw;
    width: 100%;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #131313;
    font-family: roboto, sans-serif;
    border: ${({error}) => error && "1px solid salmon"};
    outline: none;
    box-sizing: border-box;
    :disabled{
        background-color: #f7f7f7;
    }
    :-internal-autofill-selected{
        box-shadow: #ffffff 0px 0px 0px 30px inset;
        -webkit-text-fill-color: #131313 !important;
    }
    :placeholder{
        color: #888888;
    }
    &.isForm{
        font-size: 16px;
        height: 48px;
        padding-left: 17px;
    }
    &.isInnerForm{
        padding-left: 0.8854166666666666vw;
    }
    &.convertor{
        padding-left: 3vw;
    }
    &.isForm, &.isInnerForm{
        border-radius: 4px;
    }
    &.isUrl, &.isApiKey{
        width: 72%;
    }
    @media(max-width: 1200px){
        padding-left: 3.75vw;
        height: 3.75vw;
        font-size: 1.25vw;
        &.isInnerForm{
            padding-left: 1.328125vw;
        }
        &.convertor{
            padding-left: 4.5vw;
        }
        &.isForm{
            padding-left: 17px;
            height: 48px;
            font-size: 16px;
        }
    }
    @media(max-width: 768px){
        height: 48px;
        font-size: 16px;
        padding-left: 48px;
        &.isForm, &.isInnerForm{
            padding-left: 15px;
        }
        &.isInnerForm{
            padding-right: 16px;
            width: 100%;
        }
        &.convertor{
            padding-left: 63px;
        }
    }
`

const Holder = styled.div`
    position: relative;
    &.isSearch{
        width: 100%;
    }
    &.isInnerForm, &.isForm{
        margin-bottom: 26px;
    }
    &.isUrl{
        margin-bottom: 30px;
    }
    &.isPassword{
        margin-bottom: unset;
    }
    &.isTyping, &.isApiKey{
        display: flex;
    }
    &.isAmount {
        display: flex;
        flex-wrap: wrap;   
        justify-content: flex-start;
        margin-bottom: 14px;
    }
    align-items: center;
    justify-content: space-between;
    > button{
        width: 14%;
        height: 40px;
        border-radius: 4px;
        background-color: #11deb3;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        outline: none;
        border: none;
        cursor: pointer;
    }
    > img{
        width: 0.8333333333333334vw;
        position: absolute;
        left: 0.8333333333333334vw;
        top: 50%;
        transform: translateY(-50%);
    }
    @media(max-width: 1200px){
        > img{
            width: 1.25vw;
            left: 1.25vw;
        }
    }
    @media(max-width: 768px){
        &.isTyping, &.isApiKey{
            flex-direction: column;
        }
        &.isSearch{
            width: 100%;
            margin-bottom: 8px;
        }
        > img{
            width: 16px;
            left: 16px;
        }
        > button{
            width: 100%;
            margin-top: 20px;
        }
    }
`

const Eye = styled.div`
    width: 16px;
    height: 16px;
    background-image: url(${({path}) => path});
    background-size: cover;
    position: absolute;
    right: 16px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    :hover{
        background-image: url(${({type}) => type === "password" ?  showEyeHover : hideEyeHover});
    }
`

const Label = styled.label`
    font-size: 14px;
    padding-left: 17px;
    padding-bottom: 7px;
    &.isInnerForm{
        font-size: 0.7291666666666666vw;
        padding-left: 0.8854166666666666vw;
        padding-bottom: 0.5212211466865228vw;
    }
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-family: inherit;
    letter-spacing: normal;
    text-align: left;
    color: #131313;
    display: block;
    @media(max-width: 1200px){
        &.isInnerForm{
            font-size: 1.09375vw;
            padding-left: 1.328125vw;
            padding-bottom: 0.7818317200297842vw;
        }
    }
    @media(max-width: 768px){
        padding-left: 0;
        font-size: 14px;
        &.isInnerForm{
            padding-left: 0;
            font-size: 14px;
        }
        &.isUrl{
            padding-bottom: 25px;
        }
    }
`

const Error = styled.p`
    position: absolute;
    color: salmon;
    font-size: 12px;
    margin: 0;
    padding-left: ${({isInnerForm}) => isInnerForm ? "0.8854166666666666vw" : "17px"};
    bottom: ${({isInnerForm}) => isInnerForm && '-15px'};
    @media(max-width: 1200px){
        padding-left: ${({isInnerForm}) => isInnerForm && "1.328125vw"};
        font-size: ${({isAmount}) => isAmount && "9px"};
        bottom: ${({isAmount}) => isAmount && '-13px'};
    }
    @media(max-width: 768px){
        padding-left: 0;
        line-height: 1.2;
        bottom: ${({isInnerForm}) => isInnerForm && 'unset'};
        top: ${({isInnerForm}) => isInnerForm && '50px'};
        font-size: 12px;
        left: 0;
    }
`

const Wrapper = styled.div`
    width: ${({width}) => width};
    transition: all .3s ease-in;
    @media(max-width: 1200px){
        width: ${({isSearch, isAmount}) => isSearch ? '70%' : isAmount ? "100%" : ""};
    }
    @media(max-width: 768px){
        width: ${({isSearch, isAmount}) => (isSearch || isAmount) && '100%'};
    }
`

const SubLabel = styled.p`
    margin: 8px 0 0 0;
    font-size: 0.7291666666666666vw;
    padding-left: 0.8854166666666666vw;
    color: #131313;
    font-family: sofia-pro, sans-serif;
    white-space: pre-wrap;
    pointer-events: none;
    @media(max-width: 1200px){
        font-size: 1.09375vw;
        padding-left: 1.328125vw;
    }
    @media(max-width: 768px){
        white-space: unset;
        padding-left: 0;
        font-size: 14px;
        position: initial;
        padding-top: ${({error}) => error ? "17px" : "3px"};
    }
`

const CurrencySymbol = styled.div`
    position: absolute;
    width: 4.166666666666666vw;
    height: 2.5vw;
    top: 0;
    right: 0;
    font-size: 0.8333333333333334vw;
    border-left: 1px solid #dde0e6;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 1200px){
        width: 6.249999999999999vw;
        height: 3.75vw;
        font-size: 1.25vw;
    }
    @media(max-width: 768px){
        width: 48px;
        height: 48px;
        font-size: 14px;
    }
`

const Convertor = styled.div`
    position: absolute;
    width: 2.5vw;
    height: 2.5vw;
    top: 0;
    left: 0;
    font-size: 0.8333333333333334vw;
    border-right: 1px solid #dde0e6;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    > img{
        width: 0.8333333333333334vw;
    }
    @media(max-width: 1200px){
        width: 3.75vw;
        height: 3.75vw;
        > img{
            width: 1.25vw;
        }
    }
    @media(max-width: 768px){
        width: 48px;
        height: 48px;
        > img{
            width: 16px;
        }
    }
`

const Max = styled.span`
    color: #10bfa0;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.7291666666666666vw;
    padding-left: 8px;
    margin: 8px 0 0 0;

    @media(max-width: 1200px){
        font-size: 1.09375vw;
    }

    @media(max-width: 768px){
        font-size: 14px;
        position: absolute;
        right: 9px;
        top: -30px;
    }
`

class Input extends Component{

    state = {
        value: this.props.value || "",
        type: "password",
        isTyping: false,
        maxIsLoading: false
    }

    shouldComponentUpdate(nextProps, nextState){
        return nextState.value !== this.state.value
                || nextProps.addressIsValid !== this.props.addressIsValid
                || nextProps.error !== this.props.error
                || nextProps.value !== this.props.value
                || nextState.type !== this.state.type
                || nextProps.width !== this.props.width
                || nextState.isTyping !== this.state.isTyping
                || nextProps.submitted !== this.props.submitted
                || nextProps.subLabel !== this.props.subLabel
                || nextProps.isAmount !== this.props.isAmount
                || nextProps.isNumber !== this.props.isNumber
                || nextProps.availableBalance !== this.props.availableBalance
                || nextProps.fees !== this.props.fees 
                || nextState.maxIsLoading !== this.state.maxIsLoading 
    }

    componentDidUpdate(prevProps){
        if(prevProps.value !== this.props.value){
            this.setState({...this.state, value: this.props.value})
        }
        if(!prevProps.submitted && this.props.submitted){
            this.setState({...this.state, isTyping: false})
        }
    }

    onChange(e, max){
        const value = max ? max : e.target.value;

        if(this.props.isAmount) {
            if(value?.split && value?.split('.')?.length > 0 && value?.split('.')?.[1]?.length > 6) return;
            if(Number(value) > 999999999999) return;
        }
        
        if(this.props.isNumber && isNaN(value)) return;
        if(this.props.isNumber && this.props.decimals){
            const pattern = new RegExp(this.props.decimals === 8 ? /^\d*(\.\d{0,8})?$/ :  /^\d*(\.\d{0,2})?$/);
            if(!pattern.test(value)) return
        }

        const valueStartsWithHttp = value.substring(0, 8) === config.tools.https || value.substring(0, 7) === config.tools.http;
        if(this.props.isUrl && !valueStartsWithHttp) return;
        const isTypingUrl = this.props.isUrl && value !== config.tools.http && value !== config.tools.https;
        this.setState({...this.state, value: value, isTyping: isTypingUrl});
        this.props.onChangeOutside(max ? { target: { value }} : e)
    }

    toggleType(){
        const type = this.state.type === "password" ? "text" : "password";
        this.setState({...this.state, type})
    }

    getValue(){
        const {value} = this.state;
        return this.props.isUrl && (value === "" || value === null || value === undefined) ? `${config.tools.https}` : value
    }

    calculateMaxAmount = debounce(() => {
        this.setState({
            maxIsLoading: false,
        });

        if(!this.props.addressIsValid) {
            this.props.onChangeOutside({ target: { value: this.props.availableBalance }})
            return;
        }

        this.onChange(undefined, this.props.availableBalance);
    }, 500);

    render(){
        const {
            placeholder, 
            id, 
            type, 
            error, 
            isSearch, 
            label, 
            tabIndex, 
            isPassword, 
            isInnerForm, 
            onClickSave, 
            maxLength, 
            className, 
            isApiKey, 
            disabled, 
            isAmount, 
            subLabel, 
            currency,
            convertor,
            availableBalance
        } = this.props;
        const searchIcon = config.payments.searchIcon;
        return (
            <Wrapper className={`input-component ${id}`} width={this.props.width || ""} isSearch={isSearch} isAmount={isAmount}>
                {label && <Label className={className}>{label}</Label>}
                <Holder className={`${className}${this.state.isTyping ? " isTyping" : ""}`}>
                    {isSearch && <img src={require(`../../images/payments/${searchIcon}`)} alt="search"/>}
                    {
                        convertor && <Convertor>
                            <img 
                                src={require(`../../images/${convertor === true ? 'sync' : convertor}.svg`)} 
                                alt={convertor === true ? 'sync' : convertor} 
                            />
                        </Convertor>
                    }
                    
                    <CustomInput 
                        tabIndex={tabIndex} 
                        error={!this.state.maxIsLoading && error} 
                        type={isPassword ? this.state.type : type} 
                        placeholder={placeholder} 
                        id={id} 
                        name={id}
                        className={className}
                        onChange={(e) => this.onChange(e)} 
                        value={this.getValue()}
                        disabled={disabled}
                        {...(maxLength ? {maxLength: maxLength} : {})} 
                    />
                    {currency && <CurrencySymbol>{currency}</CurrencySymbol>}
                    {(this.state.isTyping || isApiKey) && <button onClick={() => onClickSave(id)}>{this.state.isTyping ? "Save" : "Generate"}</button>}
                    {isPassword && <Eye type={this.state.type} path={this.state.type === "password" ? showEye : hideEye} onClick={() => this.toggleType()}/>}
                    {(error && !this.state.maxIsLoading) && <Error isInnerForm={isInnerForm} isAmount={isAmount}>{error}</Error>}
                    {subLabel && <SubLabel error={error}>{subLabel}</SubLabel>}
                    {availableBalance && <Max onClick={() => {
                        this.setState({
                            maxIsLoading: true
                        });
                        this.calculateMaxAmount();
                    }}>
                        Max
                        {this.state.maxIsLoading && <Spinner type="default" className="max-amount" />}
                    </Max>}
                </Holder>
            </Wrapper>
        )
    }
}

export default Input;

