import React, { useState } from 'react';
import './Expand.scss';


const Expand = ({header, expandedView, hideIcon, isExpanded}) => {
    const [_isExpanded, setIsExpanded] = useState(!!isExpanded);

    return (
        <div className={`expand-component ${_isExpanded ? 'expanded' : ''} ${hideIcon ? 'not-clickable' : ''}`}>
            <div className="expand-component__header" onClick={() => !hideIcon && setIsExpanded(!_isExpanded)}>
                {header}
                {!hideIcon && <button className="expand-component__header--button">
                    <img src={require('../../../images/dropdown-chevron.svg')} alt="chevron" />
                </button> }
            </div>

            {_isExpanded && <div className="expand-component__expanded">
                {expandedView}
            </div> }
        </div>
    )
}

export default Expand;