/* AUTH */
export const ON_SIGNUP = 'ON_SIGNUP';
export const SUBMIT_COMPANY_DETAILS = 'SUBMIT_COMPANY_DETAILS';
export const ON_LOGIN = 'ON_LOGIN';
export const LOGOUT = 'LOGOUT';
export const ON_AUTH = 'ON_AUTH';
export const ON_AUTH_FAIL = 'ON_AUTH_FAIL';

/* CG-API */
export const CONNECT = 'CONNECT';
export const ACCOUNT_SUBSCRIBER = 'ACCOUNT_SUBSCRIBER';
export const IS_CONNECTED = 'IS_CONNECTED';
export const ON_SUBSCRIBE = 'ON_SUBSCRIBE';
export const ON_MESSAGE = 'ON_MESSAGE';
export const GET_MERCHANT_INFO = 'GET_MERCHANT_INFO';
export const GET_MERCHANT_CONFIG = 'GET_MERCHANT_CONFIG';
export const UPDATE_MERCHANT_INFO = 'UPDATE_MERCHANT_INFO';

/* APP */
export const SET_VIEW = 'SET_VIEW';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const SET_WIDTH = 'SET_WIDTH';
export const SET_BLOCKED_COUNTRIES = 'SET_BLOCKED_COUNTRIES';
export const SET_TOOLTIP_MESSAGE = 'SET_TOOLTIP_MESSAGE';
export const SET_LOADER = 'SET_LOADER';

export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

