import React from 'react'
import { SETTING_TYPES } from '../Setting';

export default ({type, value, isEdit, isClickable, suffix}) => {
    switch(type) {
        case SETTING_TYPES.EDIT:
            if(isEdit) return null;
            return <div className={`setting-component__value ${!isClickable ? 'not-clickable' : ''}`}>
                {value}{value ? suffix ?? '' : ''}
            </div>

        default: 
            return null;
    }
}