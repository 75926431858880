import * as actionTypes from "./actionTypes";

export const onSubscribe = (data, key) => {
    return {
        type: actionTypes.ON_SUBSCRIBE,
        data,
        key
    }
}

export const connect = () => {
    return {
        type: actionTypes.CONNECT,
    }
}

export const accountSubscriber = () => {
    return {
        type: actionTypes.ACCOUNT_SUBSCRIBER,
    }
}

export const getMerchantInfo = () => {
    return {
        type: actionTypes.GET_MERCHANT_INFO,
    }
}

export const updateMerchantInfo = (payload) => {
    return {
        type: actionTypes.UPDATE_MERCHANT_INFO,
        payload
    }
}
