import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/coti-logo.png';
import { track } from '../../shared/analytics';
import ApplyAsMerchantButton from '../Common/ApplyAsMerchantButton';
import './Footer.scss';

export const Footer = () => {
    return (
        <footer className="FooterContainer">
            <div className="ada-footer-background"></div>
            <div className="accept">
                <div className="ada">
                    <div className="register-section">
                        <h2>We accept ADA</h2>
                        <h3>Direct. Secure. From Anywhere. For Everyone</h3>
                    </div>
                     <ApplyAsMerchantButton position="Bottom" />
                </div>
            </div>

            <PoweredByCoti />
        </footer>
    )
}

export const Merchant = ({merchantData}) => {
    return (
        <div className="merchant-component">
            <img src={require(`../../images/${merchantData.image}`)} alt={merchantData.image} />
            <Link className="register-now-link" onClick={() => track('Homepage', 'Donate now homepage')} to={{pathname: `${process.env.REACT_APP_MERCHANTS}/save-the-children`}} target="_blank" >Donate now</Link>
        </div>
    )
}

export const PoweredByCoti = ({className = ""}) => {
    return (
        <a href="https://coti.io" target="_blank" rel="noopener noreferrer" className={`InnerContainer ${className}`}>
            Powered by
            <img src={Logo} alt="logo"/>
        </a>
    )
}