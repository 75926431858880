import { fork, put } from "redux-saga/effects";
import { push } from 'connected-react-router';
import * as actions from '../actions/index';
import {config} from '../../config';
import xhrApi from "../../xhr-api";
import { getMerchantInitialInfo } from "./integrations";


export function* onSignup({payload}){
    try {
        const JWR = yield xhrApi.SIGN_UP(payload); 
        
        if(JWR.data) {
            yield put(actions.onAuth({...JWR.data}));
            yield put(push(`${config.appViews.status.path}?email=${payload.email}&status=not-verified`));
        }
        if (JWR instanceof  Error) {
          throw JWR;
        }
    } catch (error) { 
        const errorMessage = error.response && error.response.data && error.response.data.message;
        if(errorMessage) {
            console.log(errorMessage);
            if(errorMessage.includes("email_UNIQUE")) {  
                return yield put(actions.setTooltipMessage(true, false, "emailUnique"))  
            } else if(errorMessage.includes("companyName_UNIQUE")) {  
                return yield put(actions.setTooltipMessage(true, false, "companyNameUnique"))  
            }
        }
        return yield put(actions.setTooltipMessage(true, false, "generalfailMsg"))       
    }
}

export function* onLogin({payload}){
    const {email, password} = payload;
    try {
        const JWR = yield xhrApi.LOGIN({email, password}); 
        if (JWR instanceof Error) {
            throw JWR;  
        }
        if(JWR.data) {
            yield put(push(`${config.root_path}/overview`));
            yield fork(getMerchantInitialInfo);
        }
    } catch (error) {
        console.log(error);
        if(error && error.response && error.response.data) {
            if(error.response.data.message === "Unauthorized") {
                return yield put(actions.setTooltipMessage(true, false, "invalidEmailOrPassword"))    
            } else if(error.response.data.data === "EMAIL_NOT_VERIFIED") {
                yield put(actions.setTooltipMessage(true, false, "emailNotVerified"))  
                return yield put(push(`${config.appViews.status.path}?email=${payload.email}&status=not-verified`));
            } else if(error.response.data.data === "ACCOUNT_NOT_APPROVED") {
                yield put(actions.setTooltipMessage(true, false, "accountNotApproved"))  
                return yield put(push(`${config.appViews.status.path}?status=thankYou`));
            } else {
                return yield put(actions.setTooltipMessage(true, false, "generalfailMsg"))  
            }
        }

        return yield put(actions.setTooltipMessage(true, false, "generalfailMsg"))    
    }
}

export function* logout(){
    try {
        const JWR = yield xhrApi.LOGOUT(); 

        if(JWR.data) {
            window.location = config.root_path;
        }
        if (JWR instanceof  Error) {
            throw JWR;
          }
    } catch (error) {
        console.log(error);
        window.location = config.root_path;
        // error handler actions.onAuthError server error           
    }
}

// const isViewable = () => [
//     '/dashboard/',
//     config.root_path,
//     config.root_path+'/email',
//     config.root_path+config.appViews.resetPassword.path,
//     config.root_path+config.appViews.notVerified.path
// ].includes(window.location.pathname);

