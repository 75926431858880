import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onSubscribe, setTooltipMessage } from '../../../../store/actions';
import xhrApi from '../../../../xhr-api';
import { config } from '../../../../config';
import Spinner from '../../Spinner';

export default ({value, setValue}) => {
    const { logoUrl } = useSelector(({app}) => app.account);
    const dispatch = useDispatch(); 

    const onChangeFile = ({target: { files }}) => {
        const fileObject = files[0];
        if (fileObject.type && !fileObject.type.startsWith('image/')) {
            console.log('File is not an image.', fileObject.type, fileObject);
            return dispatch(setTooltipMessage(true, false, "File is not an image"));
        }

        if((fileObject.size / 1024 / 1024) > 5) {
            console.log('File size cannot be more than 5MB.', fileObject.type, fileObject);
            return dispatch(setTooltipMessage(true, false, "File size cannot be more than 5MB."));
        }

        if(['image/png', 'image/jpg', 'image/jpeg'].every(fileType => fileObject.type !== fileType)) {
            console.log('File type is not supported.', fileObject.type, fileObject);
            return dispatch(setTooltipMessage(true, false, "File type is not supported."));
        }

        const fileUrl = window.URL.createObjectURL(fileObject)
        const formData = new FormData();
        formData.append('file', fileObject);

        setValue({
            url: fileUrl,
            data: formData
        });
    }

    const onSubmitFile = async (e) => {
        try {
            setValue(prev => ({...prev, data: null}));
            await xhrApi.UPLOAD_LOGO(value.data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            setValue(prev => ({...prev, data: value.data}));
            dispatch(setTooltipMessage(true, true, config.tooltipMessages.updatedSettings));
            dispatch(onSubscribe({
                logoUrl: value.url
            }, "account"));
        } catch(error) {
            console.log(error);
            dispatch(setTooltipMessage(true, false, config.tooltipMessages.updatSettingsFailed));
        } finally {
            setValue(prev => ({...prev, data: undefined}));
        }
    }

    const onCancel = () => {
        setValue({url: logoUrl, data: undefined});
    }

    useEffect(() => {
        if(!logoUrl) return;
        setValue({
            url: logoUrl,
            data: undefined
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoUrl]);
    
    return <div className="select-file-component">
        {value?.data ? <> 
                <button onClick={onSubmitFile}>Save</button>
                <button onClick={onCancel}>
                    Cancel
                </button> 
            </> : value?.data === null ? <Spinner /> : <label htmlFor="logo">
                Select
            </label>
        }
        
        <input 
            type="file" 
            name="logo" 
            id="logo" 
            style={{display: 'none'}} 
            onChange={onChangeFile} 
            accept=".jpg, .jpeg, .png" 
        />
    </div>
        
  
}
